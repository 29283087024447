import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const PdfViewer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const handlePreviousPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
  };

  return (
    <div>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page 
        pageNumber={pageNumber} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        
        />
      </Document>
      <p>
        Sayfa {pageNumber} / {numPages}
      </p>
      <button onClick={handlePreviousPage} disabled={pageNumber <= 1}>
        Previous
      </button>
      <button onClick={handleNextPage} disabled={pageNumber >= numPages}>
        Next
      </button>
    </div>
  );
};

export default PdfViewer;
