const HeatingSystemUpgrade = [
    { name: "New gas boiler", code: "1" },
    { name: "Controls", code: "2" },
    { name: "Underfloor heating", code: "3" },
    { name: "Heat pump", code: "4" },
    { name: "More than one ", code: "5" },

  ];

  export default HeatingSystemUpgrade;

  




