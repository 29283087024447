import { lazy } from "react";
import { BrowserRouter, Router, Switch } from "react-router-dom";
import PublicRoute from "./common/PublicRoute";
import { history } from "./common/history";
import Passport from "./components/Passport";

const Publicbar = lazy(() => import("./common/Publicbar"));

const AppPassport = () => {
  return (
    <Router history={history}>
      <BrowserRouter>
        <Switch>
          <div>
            <Publicbar />
            <br />
            <center>
            <div className="w-full">
              <PublicRoute
                restricted={false}
                component={Passport}
                path="/passport/:id"
                exact
              />
            </div>
            </center>
            <br />
          </div>
        </Switch>
      </BrowserRouter>
    </Router>
  );
};

export default AppPassport;