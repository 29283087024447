import React, { Component } from "react";
import { ScrollTop } from "primereact/scrolltop";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { withTranslation } from "react-i18next";
import WaitDialog from "../common/WaitDialog";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../resources/main.css";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {

            privacyDisplay: false,
            position: 'bottom-left',
        };

        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {

        if (this.checkCookie('lrs')) {
            this.setState({
                privacyDisplay: false
            });            
        } else {
            this.setState({
                privacyDisplay: true
            });            
        }

        window.scrollTo(0, 0);
    }


    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; SameSite=Lax; path=/";

        this.setState({
            privacyDisplay: false
        });
    }


    checkCookie(name) {

        var ca = document.cookie.split(';');

        for (var i=0;i < ca.length;i++) {
            var c = ca[i];
            if (c.trim() === 'lrs=hhhl1') {
                return true;
            }
        }
        return false;
    }    

    renderFooter() {
        return (
            <div>
                <Button className="p-button-mb p-button-sm" label="Accept" icon="pi pi-check"
                    onClick={() => this.setCookie('lrs','hhhl1',180)}
                />
            </div>
        );
    }

    render() {

        const { t } = this.props;

        return (
            <div>

                <ScrollTop />
                <div className="container">
                    <div className="row p-5">


                        <div className="col-12 md:col-12 lg:col-6 mb-6">
                            <b><p class="text-6xl" style={{color:"#0094A8",font:"Manrope"}}>Welcome to London</p></b>
                            <b><p class="text-6xl" style={{color:"#0094A8",font:"Manrope"}}>Rebuilding Society Project</p></b>
                            <b><p class="text-6xl" style={{color:"#0094A8",font:"Manrope"}}> Management Platform</p></b>

                            <p class="text-2xl" style={{color:"#0094A8",font:"Manrope"}}>Making every house a happy home</p>

                            <p class="text-lg">The London Rebuilding Society Healthy Homes, Healthy Lives service could enable homeowners who can’t afford the repairs they need, to have their home improved to a high standard, without having to pay out any cash up front.</p>

                            <br />
                            <br />
                            <img className="img-fluid" src="/assets/img/mainpagePic3.png" alt="" />
                            <br />
                            <br />

                            <Button label={t("goToLRSWebsite")} className="p-button-outlined p-button-sm" style={{color:"#0094A8",font:"Manrope"}} 
                              onClick={() => {
                                window.open('https://londonrebuilding.com/society/', '_blank');
                              }}
                            />

                        </div>

                        <div className="col-12 md:col-12 lg:col-6 mb-6">

                            <img className="img-fluid" src="/assets/img/mainpagePic1.png" alt="" />

                            <img className="img-fluid" src="/assets/img/mainpagePic2.png" alt="" />

                        </div>


                    </div>

                </div>

                <section className="container py-5">

                    <div className="grid flex-column">

                        <Dialog visible={this.state.privacyDisplay} position={this.state.position} modal style={{ width: '36vw', height: '14vw' }} footer={this.renderFooter()}
                            draggable={false} resizable={false} closable={false} >
                            <p>
                                This site uses cookies to offer you a better browsing experience. Cookies that are strictly necessary for the website to function are always on and cannot be switched off.
                            </p>
                        </Dialog>

                    </div>
                </section>

                <WaitDialog loading={this.state.loading} lang={t} />

            </div>

        );
    }
}

export default withTranslation()(Index);
