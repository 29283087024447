export function randomColor() {
  return '#' + Math.floor(Math.random() * 0xFFFFFF).toString(16);
}


export function rowClass(data) {

  if (data.areWeProceeding === "2") {
    return "awpNo";
  } else if (data.areWeProceeding === "3") {
    return "awpPending";
  } else {
    return "";
  }

}

export function isDevelopment() {
  return true;
} 

export function roundToTwo(num) {
  return Number(num).toFixed(1);
}

export function diffMinutes(dt1Str) {

  var dt1 = dt1Str.split(' ');

  let ymd = dt1[0].split('-');
  let hms = dt1[1].split(':');

  var dt1 = new Date(ymd[0], ymd[1]-1, ymd[2], hms[0], hms[1], hms[2], 0);

  return parseInt((new Date(Date.now())-dt1)/60000);
}

export function handleDownloadDocument(rowData) {

  var a = document.createElement("a");
  a.target = "_blank";
  a.href = rowData.documentURL;
  a.setAttribute("download", rowData.documentName);
  a.click();
}

export function handleDownloadEQDatasheetDocument(rowData) {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = rowData.eqDatasheetURL;
  a.setAttribute("download", rowData.documentName);
  a.click();
}