import React, { Component } from "react";

import { connect } from "react-redux";
import { login } from "../actions/auth";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Captcha } from "primereact/captcha";

import { withTranslation, Trans } from "react-i18next";
import WaitDialog from "../common/WaitDialog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import { deleteRoute } from "../actions/route";
import { isAdmin } from "../common/token-check";

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      username: "",
      password: "",
      response: "",
      loading: false,
    };
    this.recaptcha = null;
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleLogin() {
    this.setState({ loading: true });

    if (
      this.state.username == null ||
      this.state.username === "" ||
      this.state.password == null ||
      this.state.password === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnLogin1" />);
      setTimeout(() => {
        this.recaptcha.reset();
      }, 500);
      return;
    }

    if (this.state.response === "" || this.state.response === null) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnCaptcha" />);
      setTimeout(() => {
        this.recaptcha.reset();
      }, 500);
      return;
    }

    const { dispatch, history } = this.props;

    dispatch(
      login(this.state.username, this.state.password, this.state.response)
    )
      .then(() => {
        this.setState({ loading: false });
        if (isAdmin()) {
          history.push("/home");
        } else {
          history.push("/home");
        }
        dispatch(deleteRoute());
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        toast.error(<Trans i18nKey={this.props.message} />);
        setTimeout(() => {
          this.recaptcha.reset();
        }, 500);
      });
  }

  showResponse = (recaptcha) => {
    const response = recaptcha.response;
    this.setState({ response });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
            <div className="text-center text-900 text-xl font-medium">
              {t("welcome")}!
            </div>
            <div className="text-center text-600 font-medium line-height-3 mb-4">
              {t("txtLogin1")}
            </div>
            <div>
              <form onSubmit={this.handleSubmit}>
                <label
                  htmlFor="email"
                  className="block text-900 font-medium mb-2"
                >
                  {t("email")}
                </label>
                <InputText
                  id="email"
                  type="text"
                  className="w-full p-inputtext block mb-2"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />

                <label
                  htmlFor="password"
                  className="block text-900 font-medium mb-2"
                >
                  {t("password")}
                </label>
                <InputText
                  id="password"
                  type="password"
                  className="w-full p-inputtext block mb-4"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />

                <div className="flex align-items-center justify-content-between mb-2">
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                    href="/forgotPassword"
                  >
                    - {t("txtLogin2")}?
                  </a>
                </div>

                <div className="flex align-items-center justify-content-between mb-4">
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                    href="/resendMail"
                  >
                    - {t("txtLogin3")}?
                  </a>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Captcha
                    ref={(recaptcha) => (this.recaptcha = recaptcha)}
                    siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onResponse={this.showResponse}
                  />
                </div>
                <br />

                <Button
                  label={t("login")}
                  icon="pi pi-sign-in"
                  className="w-full p-button-mb mb-4"
                  onClick={() => this.handleLogin()}
                />
              </form>
            </div>
          </div>

          <WaitDialog loading={this.state.loading} lang={t} />

          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="colored"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  const { route } = state.route;
  return {
    isLoggedIn,
    message,
    route,
  };
};

export default withTranslation()(connect(mapStateToProps)(Login));
