const ExternalDoorReplacement = [
    { name: "Front", code: "1" },
    { name: "Back", code: "2" },
    { name: "Front and back", code: "3" },

  ];

  export default ExternalDoorReplacement;



