import React, { Component } from "react";

import { connect } from "react-redux";
import { changePassword } from "../actions/sys";

import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Panel } from "primereact/panel";
import { BlockUI } from "primereact/blockui";

import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import WaitDialog from "../common/WaitDialog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

class ChangePassword extends Component {

  constructor(props) {

    super(props);

    this.state = {
      content: "",
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
      saveButtonStatus: false,
      loading: false,
      blockedPanel: false,
    };

    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
  }

  accept() {

    this.setState({ loading: true });

    if (
      this.state.oldPassword.trim() === "" ||
      this.state.newPassword.trim() === "" ||
      this.state.newPassword2.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword1"/>);
      return;
    }

    if (this.state.newPassword.length < 8) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword2"/>);
      return;
    }

    if (this.state.newPassword !== this.state.newPassword2) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword3"/>);
      return;
    }

    if (this.state.oldPassword === this.state.newPassword) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword4"/>);
      return;
    }

    this.props
      .dispatch(
        changePassword(
          this.state.content.username,
          this.state.oldPassword,
          this.state.newPassword
        )
      )
      .then(() => {
        this.setState({
          loading: false,
          oldPassword: "",
          newPassword: "",
          newPassword2: "",
          saveButtonStatus: false
        });

        toast.success(<Trans i18nKey={this.props.message}/>);
      })
      .catch(() => {
        this.setState({
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message}/>);
      });
  }

  reject() {}

  componentDidMount() {

    this.setState({ loading: true });
    
    SysService.getUserInfo().then(
      (response) => {
        this.setState({
          content: response.data.users,
          blockedPanel: false,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
          loading: false,
        });
        toast.error(<Trans i18nKey={this.state.content}/>);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  render() {

    const { t } = this.props;

    return (
      <div>
        <>
          <div className="col-12 md:col-4 lg:col-4 mb-2 centered">
            <BlockUI blocked={this.state.blockedPanel}>
              <Panel header={t("changePassword")}>
                <br />
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="grid mb-4">
                    <div className="col-12">
                      <div className="p-float-label">
                        <Password
                          className="w-full"
                          id="oldPassword"
                          feedback={false}
                          value={this.state.oldPassword}
                          onChange={(e) =>
                            this.setState({ oldPassword: e.target.value, saveButtonStatus: true })
                          }
                          toggleMask
                        />
                        <label htmlFor="oldPassword" className="platformColor">{t("oldPassword")}</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="p-float-label">
                        <Password
                          className="w-full"
                          id="newPassword"
                          value={this.state.newPassword}
                          onChange={(e) =>
                            this.setState({ newPassword: e.target.value, saveButtonStatus: true })
                          }
                          toggleMask
                        />
                        <label htmlFor="newPassword" className="platformColor">{t("newPassword")}</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="p-float-label">
                        <Password
                          className="w-full"
                          id="newPassword2"
                          feedback={false}
                          value={this.state.newPassword2}
                          onChange={(e) =>
                            this.setState({ newPassword2: e.target.value, saveButtonStatus: true})
                          }
                          toggleMask
                        />
                        <label htmlFor="newPassword2" className="platformColor">
                        {t("newPassword")} ({t("again")})
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-8 centered">
                    <Button
                      label={t("changePassword")}
                      disabled={!this.state.saveButtonStatus}
                      onClick={() => this.setState({ visible: true })}
                      icon="pi pi-key"
                      className="w-full p-button-mb mb-4"
                    />

                    <ConfirmDialog
                      visible={this.state.visible}
                      onHide={() => {
                        this.setState({ visible: false });
                      }}
                      message={t("confirmMessage")}
                      header={t("confirm")}
                      icon="pi pi-exclamation-triangle"
                      accept={this.accept}
                      reject={this.reject}
                    />
                  </div>
                </div>
              </Panel>
            </BlockUI>
          </div>

          <WaitDialog loading={this.state.loading} lang={t} />

          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="colored"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
          />
        </>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(ChangePassword));
