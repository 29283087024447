import apios from "../common/apios";
import authHeader from "./auth-header";
import authTracidHeader from "./auth-tracid-header";
import axios from "axios";

import { isTracidTokenValid } from "../common/token-check";

class TracidService {
  getNewToken(apiKey, apiSecret) {
    return apios
      .post("https://dev.tracid.net/api/auth/signinext", {
        apiKey,
        apiSecret,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("tracid_token", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  getPassportLogbooksByProjectId(projectId) {
    if (!isTracidTokenValid()) {
      this.getNewToken(
        process.env.REACT_APP_TRACID_APIKEY,
        process.env.REACT_APP_TRACID_APISECRET
      );
    }

    return apios.get(
      "https://dev.tracid.net/api/ext/passportLogbooksByProjectId",
      {
        params: {
          projectId: projectId,
        },
        headers: authTracidHeader(),
      }
    );
  }

  getPassportLogbookById(passportId) {
    return apios.get("https://dev.tracid.net/api/ext/passportLogbookById", {
      params: {
        passportId: passportId,
      },
      headers: authTracidHeader(),
    });
  }

  addNewPassportLogbook(passportData) {
    return apios.post(
      "https://dev.tracid.net/api/ext/addNewPassportLogbook",
      {
        passportData,
      },
      { headers: authTracidHeader() }
    );
  }

  updatePassportLogbook(passportData) {
    return apios.post(
      "https://dev.tracid.net/api/ext/updatePassportLogbook",
      {
        passportData,
      },
      { headers: authTracidHeader() }
    );
  }

  deletePassportLogbook(passportData) {
    return apios.post(
      "https://dev.tracid.net/api/ext/deletePassportLogbook",
      {
        passportData,
      },
      { headers: authTracidHeader() }
    );
  }

  publishPassportLogbook(passportData) {
    return apios.post(
      "https://dev.tracid.net/api/ext/publishPassportLogbook",
      {
        passportData,
      },
      { headers: authTracidHeader() }
    );
  }

  passportLogbookHistoryById(passportId) {
    return apios.get(
      "https://dev.tracid.net/api/ext/passportLogbookHistoryById",
      {
        params: {
          passportId: passportId,
        },
        headers: authTracidHeader(),
      }
    );
  }

  getPassportLogbook(passportCode) {
    return apios.get("https://dev.tracid.net/api/pub/passport", {
      params: {
        passportCode: passportCode,
      },
    });
  }

  passportControl(passportCode, passportControlCode) {
    return apios.get("https://dev.tracid.net/api/pub/passportCC", {
      params: {
        passportCode: passportCode,
        passportControlCode: passportControlCode,
      },
    });
  }
}
export default new TracidService();
