import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { isDevelopment } from "../common/functions";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { withTranslation, Trans } from "react-i18next";
import { Galleria } from "primereact/galleria";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import WaitDialog from "../common/WaitDialog";
import SysService from "../services/sys.service";
import {
  isUser,
  isPlatformManager,
  isSupervisor,
  isViewerExpert,
} from "../common/token-check";

import { ToastContainer, toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import projectStatus3 from "../common/ccs_projectStatus3";
import projectStatus4 from "../common/ccs_projectStatus4";
import projectStatus5 from "../common/ccs_projectStatus5";
import projectStatus6 from "../common/ccs_projectStatus6";
import projectStatus7 from "../common/ccs_projectStatus7";

import statusReason from "../common/ccs_statusReason";

import contactType from "../common/ccs_contactType";
import Tasks from "./tasks";

class Project extends Component {
  emptyArchiveProject = {
    pruid: "",
    provideReason: "",
    archiveProjectNotes: "",
  };

  emptyUpdateStatus = {
    projectId: "",
    projectStatus: "",
    pausedReason: "",
    closedReason: "",
    notes: "",
  };

  emptyImages = {
    itemImageSrc: "",
    thumbnailImageSrc: "",
    alt: "",
    title: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",
      clientLogAndMonitoringVisit: [],
      monitoringVisit: false,
      pid: "",
      loading: false,
      blockedPanel: false,
      documents: [],
      filters1: null,
      globalFilterValue1: "",
      updateStatusDialogVisible: false,
      archiveProjectDialogVisible: false,
      viewStatusLogDialogVisible: false,
      clientLogDialogVisible: false,
      updateClientLogDialogVisible: false,
      nonEditAccess: true,

      pmAcceptConfirmDialogVisible: false,
      pmRejectConfirmDialogVisible: false,
      pmReject4ConfirmDialogVisible: false,
      pmRejectDialogVisible: false,
      pmReject4DialogVisible: false,

      p4RequestAcceptConfirmDialogVisible: false,

      contactType: "",
      description: "",
      notes: "",
      uploadDate: "",
      id: "",

      deleteClientLogConfirmDialogVisible: false,

      archiveProject: this.emptyArchiveProject,
      firstName: "",
      lastName: "",
      lastModificationTime: "",
      updateProjectStatus: this.emptyUpdateStatus,

      projectStatusLogs: "",
      content: "",

      pmApprove: "",
      pmNote: "",
      pStatus: "",

      pausedReason: "",
      clousedReason: "",
      projectStatus: "",

      selectedRow: null,

      activeIndex: 0,
      galleria1: React.createRef(null),
      galleria2: React.createRef(null),
      galleria3: React.createRef(null),
      galleria4: React.createRef(null),
      galleria5: React.createRef(null),
      galleria6: React.createRef(null),
      galleria7: React.createRef(null),
      galleria8: React.createRef(null),
      galleria9: React.createRef(null),

      visible: false,
      imageList: [],
      imageList1: [],
      imageList2: [],
      imageList3: [],
      imageList4: [],
      imageList5: [],
      imageList6: [],
      imageList7: [],
      imageList8: [],
      imageList9: [],
    };
  }

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  handleUpdateClientLog = (rowData) => {
    if (rowData.contactType) {
      this.setState({
        monitoringVisit: false,
        contactType: rowData.contactType,
      });
    } else if (rowData.monitoringVisitsAndContacts) {
      this.setState({
        monitoringVisit: true,
        contactType: rowData.monitoringVisitsAndContacts,
      });
    }

    this.setState({
      id: rowData.id,
      description: rowData.description,
      notes: rowData.notes,
      uploadDate: new Date(rowData.uploadDate),
      pid: rowData.pid,
      updateClientLogDialogVisible: true,
    });
  };

  handleDeleteClientLog = (rowData) => {
    if (rowData.contactType) {
      this.setState({
        monitoringVisit: false,
      });
    } else if (rowData.monitoringVisitsAndContacts) {
      this.setState({
        monitoringVisit: true,
      });
    }

    this.setState({
      selectedRow: rowData,
      deleteClientLogConfirmDialogVisible: true,
    });
  };

  actionTemplate = (data, props) => {
    return (
      <div>
        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            this.handleDownloadDoc(data, props);
          }}
        />
      </div>
    );
  };

  actionTemplate2 = (data, props) => {
    return (
      <>
        {!isViewerExpert() && (
          <Button
            icon="pi pi-pencil"
            href
            tooltip="Update"
            className="p-button-rounded p-button-text"
            onClick={() => this.handleUpdateClientLog(data)}
          />
        )}

        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={() => this.handleDeleteClientLog(data)}
          />
        )}
      </>
    );
  };

  acceptDeleteClientLog = () => {
    this.deleteClientLog(this.state.selectedRow);
  };

  rejectDeleteClientLog = () => {};

  deleteClientLog(data) {
    this.setState({
      loading: true,
      updateClientLogDialogVisible: false,
    });

    let id = data.id.toString();

    if (this.state.monitoringVisit === false) {
      SysService.deleteClientLog(id).then(
        (response) => {
          this.setState({
            loading: false,
          });

          toast.success(<Trans i18nKey={response.data.message} />);

          this.getProject();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    } else {
      SysService.deleteMonitoringVisit(id).then(
        (response) => {
          this.setState({
            loading: false,
          });

          toast.success(<Trans i18nKey={response.data.message} />);

          this.getProject();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  handleDownloadDoc = (rowData) => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = rowData.documentURL;
    a.setAttribute("download", rowData.documentName);
    a.click();
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeArchiveProject = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeUpdateProjectStatus = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let updateProjectStatus = { ...this.state.updateProjectStatus };
    updateProjectStatus[`${name}`] = val;

    this.setState({
      updateProjectStatus,
      saveButtonStatus: true,
    });
  };

  tabHeaderITemplate(options) {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <i className="pi pi-prime mr-2" />
        {options.titleElement}
      </button>
    );
  }

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  updateProjectStatusValue = (val) => {
    let project = { ...this.state.project };
    project[`${"projectStatus"}`] = val;

    this.setState({
      project,
    });
  };

  calendarFormatTemplateTable = (rowData) => {
    if (rowData === null) {
      return "null";
    } else if (rowData !== undefined) {
      return format(new Date(rowData), "dd/MM/yyyy");
    } else {
      return "";
    }
  };

  addClientLog() {
    if (
      this.state.contactType == null ||
      this.state.contactType.trim() === "" ||
      this.state.description == null ||
      this.state.description.trim() === "" ||
      this.state.uploadDate == null ||
      this.state.uploadDate === "undefined"
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    } else {
      this.setState({ loading: true, clientLogDialogVisible: false });
      SysService.addClientLog(
        this.state.contactType,
        this.state.description,
        this.state.notes,
        this.state.uploadDate,
        this.props.location.state.id
      ).then(
        (response) => {
          this.setState({
            loading: false,
            blockedPanel: false,
          });
          toast.success(<Trans i18nKey={response.data.message} />);

          SysService.getProject(this.props.location.state.id).then(
            (response) => {
              let clientLogAndMonitoringVisitArray = [
                ...response.data.clientLogList,
                ...response.data.monitoringVisitsList,
              ];

              this.setState({
                project: response.data,
                firstName: response.data.firstName,
                clientLogAndMonitoringVisit: clientLogAndMonitoringVisitArray,
                lastName: response.data.lastName,
                lastModificationTime: response.data.lastModificationTime,
                documents: response.data.documentList,

                loading: false,
                blockedPanel: false,
                nonEditAccess: response.data.status === 2 ? false : true,
              });
            },

            (error) => {
              this.setState({
                loading: false,
                content:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.timeoutErrorMessage ||
                  error.toString(),
                blockedPanel: true,
              });

              toast.error(<Trans i18nKey={this.state.content} />);

              if (
                error.response &&
                (error.response.status === 401 ||
                  error.response.status === 403 ||
                  error.response.status === 404)
              ) {
                switch (error.response.status) {
                  case 401:
                    window.location.href = "/401";
                    break;

                  case 403:
                    window.location.href = "/403";
                    break;

                  case 404:
                    window.location.href = "/404";
                    break;

                  default:
                    break;
                }
              }
            }
          );
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
    this.setState({
      contactType: "",
      description: "",
      notes: "",
      uploadDate: "",
    });
  }

  updateClientLog() {
    if (this.state.monitoringVisit === false) {
      if (
        this.state.contactType == null ||
        this.state.contactType.trim() === "" ||
        this.state.description == null ||
        this.state.description.trim() === "" ||
        this.state.uploadDate == null ||
        this.state.uploadDate === "undefined"
      ) {
        this.setState({ loading: false });
        toast.warning(<Trans i18nKey="warnMandatory" />);
        return;
      } else {
        this.setState({ loading: true, clientLogDialogVisible: false });
        SysService.updateClientLog(
          this.state.id,
          this.state.contactType,
          this.state.description,
          this.state.notes,
          this.state.uploadDate,
          this.props.location.state.id
        ).then(
          (response) => {
            this.setState({
              loading: false,
              blockedPanel: false,
              updateClientLogDialogVisible: false,
            });
            toast.success(<Trans i18nKey={response.data.message} />);

            this.getProject();

          },

          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;

                case 403:
                  window.location.href = "/403";
                  break;

                case 404:
                  window.location.href = "/404";
                  break;

                default:
                  break;
              }
            }
          }
        );
      }
      this.setState({
        contactType: "",
        description: "",
        notes: "",
        uploadDate: "",
      });
    } else {
      SysService.updateMonitoringVisit(
        this.state.id.toString(),
        this.state.contactType,
        this.state.description,
        this.state.notes,
        this.state.uploadDate
      ).then(
        (response) => {
          this.setState({
            loading: false,
            blockedPanel: false,
            updateClientLogDialogVisible: false,
          });
          toast.success(<Trans i18nKey="164" />);
          //this.addClientLog();
          this.getProject();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
      this.setState({
        contactType: "",
        description: "",
        notes: "",
        uploadDate: "",
      });
    }
  }

  archiveProject() {
    if (
      this.state.project.archiveProjectNotes == null ||
      this.state.project.archiveProjectNotes === "" ||
      this.state.project.provideReason == null ||
      this.state.project.provideReason === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({ loading: true, archiveProjectDialogVisible: false });
    }

    this.state.project.projectId = this.state.pid;

    SysService.archiveProject(this.state.project).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          blockedPanel: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        SysService.getProject(this.props.location.state.id).then(
          (response) => {
            this.setState({
              project: response.data,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              lastModificationTime: response.data.lastModificationTime,
              documents: response.data.documentList,
              loading: false,
              blockedPanel: false,
              nonEditAccess: response.data.status === 2 ? false : true,
            });
          },

          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;

                case 403:
                  window.location.href = "/403";
                  break;

                case 404:
                  window.location.href = "/404";
                  break;

                default:
                  break;
              }
            }
          }
        );
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  updateProjectStatus() {
    this.setState({ loading: true });
    this.state.updateProjectStatus.projectId = this.state.pid;

    if (this.state.updateProjectStatus.projectStatus === "10") {
      if (
        this.state.updateProjectStatus.projectStatus == null ||
        this.state.updateProjectStatus.projectStatus === "" ||
        this.state.updateProjectStatus.pausedReason == null ||
        this.state.updateProjectStatus.pausedReason === ""
      ) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="warnMandatory" />);

        return;
      } else {
        this.setState({
          updateStatusDialogVisible: false,
          loading: true,
        });

        SysService.updateProjectStatus(this.state.updateProjectStatus).then(
          (response) => {
            this.setState({
              updateProjectStatus: response.data,
              loading: false,
              blockedPanel: false,
            });
            toast.success(<Trans i18nKey={response.data.message} />);

            SysService.getProject(this.props.location.state.id).then(
              (response) => {
                this.setState({
                  project: response.data,
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  lastModificationTime: response.data.lastModificationTime,
                  documents: response.data.documentList,
                  loading: false,
                  blockedPanel: false,
                  nonEditAccess: response.data.status === 2 ? false : true,
                });
              },

              (error) => {
                this.setState({
                  loading: false,
                  content:
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.timeoutErrorMessage ||
                    error.toString(),
                  blockedPanel: true,
                });

                toast.error(<Trans i18nKey={this.state.content} />);

                if (
                  error.response &&
                  (error.response.status === 401 ||
                    error.response.status === 403 ||
                    error.response.status === 404)
                ) {
                  switch (error.response.status) {
                    case 401:
                      window.location.href = "/401";
                      break;

                    case 403:
                      window.location.href = "/403";
                      break;

                    case 404:
                      window.location.href = "/404";
                      break;

                    default:
                      break;
                  }
                }
              }
            );
          },

          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);
          }
        );
      }

      this.setState({
        updateProjectStatus: this.emptyUpdateStatus,
      });
    } else if (this.state.updateProjectStatus.projectStatus === "11") {
      if (
        this.state.updateProjectStatus.projectStatus == null ||
        this.state.updateProjectStatus.projectStatus === "" ||
        this.state.updateProjectStatus.closedReason == null ||
        this.state.updateProjectStatus.closedReason === ""
      ) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="warnMandatory" />);

        return;
      } else {
        this.setState({
          updateStatusDialogVisible: false,
          loading: true,
        });

        SysService.updateProjectStatus(this.state.updateProjectStatus).then(
          (response) => {
            this.setState({
              updateProjectStatus: response.data,
              loading: false,
              blockedPanel: false,
            });
            toast.success(<Trans i18nKey={response.data.message} />);

            SysService.getProject(this.props.location.state.id).then(
              (response) => {
                this.setState({
                  project: response.data,
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  lastModificationTime: response.data.lastModificationTime,
                  documents: response.data.documentList,
                  loading: false,
                  blockedPanel: false,
                  nonEditAccess: response.data.status === 2 ? false : true,
                });
              },

              (error) => {
                this.setState({
                  loading: false,
                  content:
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.timeoutErrorMessage ||
                    error.toString(),
                  blockedPanel: true,
                });

                toast.error(<Trans i18nKey={this.state.content} />);

                if (
                  error.response &&
                  (error.response.status === 401 ||
                    error.response.status === 403 ||
                    error.response.status === 404)
                ) {
                  switch (error.response.status) {
                    case 401:
                      window.location.href = "/401";
                      break;

                    case 403:
                      window.location.href = "/403";
                      break;

                    case 404:
                      window.location.href = "/404";
                      break;

                    default:
                      break;
                  }
                }
              }
            );
          },

          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);
          }
        );
      }

      this.setState({
        updateProjectStatus: this.emptyUpdateStatus,
      });
    } else {
      if (
        this.state.updateProjectStatus.projectStatus == null ||
        this.state.updateProjectStatus.projectStatus === ""
      ) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="warnMandatory" />);

        return;
      } else {
        this.setState({
          updateStatusDialogVisible: false,
          loading: true,
        });

        SysService.updateProjectStatus(this.state.updateProjectStatus).then(
          (response) => {
            this.setState({
              updateProjectStatus: response.data,
              loading: false,
              blockedPanel: false,
            });
            toast.success(<Trans i18nKey={response.data.message} />);

            SysService.getProject(this.props.location.state.id).then(
              (response) => {
                this.setState({
                  project: response.data,
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  lastModificationTime: response.data.lastModificationTime,
                  documents: response.data.documentList,
                  loading: false,
                  blockedPanel: false,
                  nonEditAccess: response.data.status === 2 ? false : true,
                });
              },

              (error) => {
                this.setState({
                  loading: false,
                  content:
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.timeoutErrorMessage ||
                    error.toString(),
                  blockedPanel: true,
                });

                toast.error(<Trans i18nKey={this.state.content} />);

                if (
                  error.response &&
                  (error.response.status === 401 ||
                    error.response.status === 403 ||
                    error.response.status === 404)
                ) {
                  switch (error.response.status) {
                    case 401:
                      window.location.href = "/401";
                      break;

                    case 403:
                      window.location.href = "/403";
                      break;

                    case 404:
                      window.location.href = "/404";
                      break;

                    default:
                      break;
                  }
                }
              }
            );
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);
          }
        );
      }

      this.setState({
        updateProjectStatus: this.emptyUpdateStatus,
      });
    }
  }

  updateProjectStatus2() {
    this.setState({ loading: true });
    let local = this.emptyUpdateStatus;
    local.projectId = this.state.pid;
    if (this.state.project.p4Request === "accept") {
      local.projectStatus = "4";
    } else if (this.state.project.p4Request === "reject") {
      local.projectStatus = "6";
    } else if (this.state.project.pmApprove === "accept") {
      local.projectStatus = "1";
    } else if (this.state.project.pmApprove === "reject") {
      local.projectStatus = "3";
    }
    SysService.updateProjectStatus(local).then(
      (response) => {
        this.setState({
          updateProjectStatus: response.data,
          loading: false,
          blockedPanel: false,
        });

        SysService.getProject(this.props.location.state.id).then(
          (response) => {
            this.setState({
              project: response.data,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              lastModificationTime: response.data.lastModificationTime,
              documents: response.data.documentList,
              loading: false,
              blockedPanel: false,
              nonEditAccess: response.data.status === 2 ? false : true,
            });
          },

          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;

                case 403:
                  window.location.href = "/403";
                  break;

                case 404:
                  window.location.href = "/404";
                  break;

                default:
                  break;
              }
            }
          }
        );
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);
      }
    );
  }

  approvePhase1 = () => {
    this.state.project.projectStatus = "1";
    this.state.project.projectId = this.state.pid;
    this.state.project.phase = "PmApproval";
    this.state.project.pmApprove = "accept";
    this.state.project.p4Request = "";

    this.setState({
      loading: true,
    });
    SysService.updateProject(this.state.project).then(
      (response) => {
        this.setState({
          project: response.data,
          saveButtonStatus: false,
        });
        this.updateProjectStatus2();
        toast.success(<Trans i18nKey={"saved"} />);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  approvePhase4 = () => {
    this.state.project.projectStatus = "4";
    this.state.project.projectId = this.state.pid;
    this.state.project.phase = "PmApproval";
    this.state.project.pmApproval = "accept";
    this.state.project.p4Request = "accept";

    this.setState({
      loading: true,
    });
    SysService.updateProject(this.state.project).then(
      (response) => {
        this.setState({
          project: response.data,
          saveButtonStatus: false,
        });
        this.updateProjectStatus2();
        toast.success(<Trans i18nKey={"saved"} />);
      },

      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectPhase1 = () => {
    this.state.project.projectStatus = "3";
    this.state.project.projectId = this.state.pid;
    this.state.project.phase = "PmApproval";
    this.state.project.pmApprove = "reject";
    this.state.project.p4Request = "";

    this.setState({
      loading: true,
    });
    SysService.updateProject(this.state.project).then(
      (response) => {
        this.setState({
          project: response.data,
          saveButtonStatus: false,
          pmRejectDialogVisible: false,
        });
        this.updateProjectStatus2();
        toast.success(<Trans i18nKey={"saved"} />);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectPhase4 = () => {
    this.state.project.projectStatus = "6";
    this.state.project.projectId = this.state.pid;
    this.state.project.phase = "PmApproval";
    this.state.project.pmApprove = "accept";
    this.state.project.p4Request = "reject";

    this.setState({
      loading: true,
    });
    SysService.updateProject(this.state.project).then(
      (response) => {
        this.setState({
          project: response.data,
          saveButtonStatus: false,
          pmReject4DialogVisible: false,
        });
        this.updateProjectStatus2();
        toast.success(<Trans i18nKey={"saved"} />);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  viewStatusLog() {
    if (!isUser()) {
      SysService.getProject(this.props.location.state.id).then(
        (response) => {
          this.setState({
            project: response.data,
            loading: false,
            blockedPanel: false,
            nonEditAccess: response.data.status === 2 ? false : true,
            statusLogNotes: response.data.notes,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );

      SysService.getProjectStatusLogs(this.state.project.id).then(
        (response) => {
          this.setState({
            projectStatusLogs: response.data,
            loading: false,
            blockedPanel: false,
            nonEditAccess: response.data.status === 2 ? false : true,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  getProject() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    if (!isUser()) {
      SysService.getProject(this.props.location.state.id).then(
        (response) => {
          if (response.data.documentList) {
            for (let i = 0; i < response.data.documentList.length; i++) {
              if (
                response.data.documentList[i].documentURL
                  .toLowerCase()
                  ?.endsWith(".jpg") ||
                response.data.documentList[i].documentURL
                  .toLowerCase()
                  ?.endsWith(".png") ||
                response.data.documentList[i].documentURL
                  .toLowerCase()
                  ?.endsWith(".jpeg")
              ) {
                let newObj = {};
                newObj.itemImageSrc = response.data.documentList[i].documentURL;
                newObj.thumbnailImageSrc =
                  response.data.documentList[i].documentURL;
                newObj.alt = response.data.documentList[i].fileDescription;
                newObj.title = response.data.documentList[i].fileName;

                if (response.data.documentList[i].phase.charAt(1) === "1") {
                  this.state.imageList1.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "2"
                ) {
                  this.state.imageList2.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "3"
                ) {
                  this.state.imageList3.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "4"
                ) {
                  this.state.imageList4.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "5"
                ) {
                  this.state.imageList5.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "6"
                ) {
                  this.state.imageList6.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "7"
                ) {
                  this.state.imageList7.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "8"
                ) {
                  this.state.imageList8.push(newObj);
                } else if (
                  response.data.documentList[i].phase.charAt(1) === "9"
                ) {
                  this.state.imageList9.push(newObj);
                }
              }
            }
          }

          let clientLogAndMonitoringVisitArray = [
            ...response.data.clientLogList,
            ...response.data.monitoringVisitsList,
          ];

          this.setState({
            project: response.data,
            clientLogAndMonitoringVisit: clientLogAndMonitoringVisitArray,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            lastModificationTime: response.data.lastModificationTime,
            documents: response.data.documentList,
            loading: false,
            blockedPanel: false,
            nonEditAccess: response.data.status === 2 ? false : true,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  updateProject() {
    this.setState({
      loading: true,
    });

    this.state.project.projectId = this.state.pid;
    this.state.project.phase = "PmApproval";

    SysService.updateProject(this.state.project).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          saveButtonStatus: false,
        });

        toast.success(<Trans i18nKey="saved" />);
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  componentDidMount() {
    this.getProject();

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />

          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderClientLogHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("clientLog")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />

          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;
    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  projectIDTemplate(data) {
    let arr = [];
    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");

    return str;
  }

  contactTypeTemplate(data, t) {
    if (data.contactType !== null) {
      if (data.contactType === "1") {
        return <Trans i18nKey={"e-mail"} />;
      } else if (data.contactType === "2") {
        return <Trans i18nKey={"siteVisit"} />;
      } else if (data.contactType === "3") {
        return <Trans i18nKey={"telephoneCall"} />;
      } else if (data.contactType === "4") {
        return <Trans i18nKey={"textMessage"} />;
      } else if (data.contactType === "5") {
        return <Trans i18nKey={"videoCall"} />;
      } else if (data.contactType === "01") {
        return <Trans i18nKey={"Monitoring visit"} />;
      } else if (data.contactType === "02") {
        return <Trans i18nKey={"Monitoring check 1"} />;
      } else if (data.contactType === "03") {
        return <Trans i18nKey={"Monitoring check 2"} />;
      }
    }

    if (data.monitoringVisitsAndContacts !== null) {
      if (data.monitoringVisitsAndContacts === "1") {
        return <Trans i18nKey={"e-mail"} />;
      } else if (data.monitoringVisitsAndContacts === "2") {
        return <Trans i18nKey={"siteVisit"} />;
      } else if (data.monitoringVisitsAndContacts === "3") {
        return <Trans i18nKey={"telephoneCall"} />;
      } else if (data.monitoringVisitsAndContacts === "4") {
        return <Trans i18nKey={"textMessage"} />;
      } else if (data.monitoringVisitsAndContacts === "5") {
        return <Trans i18nKey={"videoCall"} />;
      }
    }
  }

  provideReasonTemplate(data) {
    if (data === "1") {
      return <Trans i18nKey={"notAHomeowner"} />;
    } else if (data === "2") {
      return <Trans i18nKey={"ageOfHomeowner"} />;
    } else if (data === "3") {
      return <Trans i18nKey={"propertyLocation"} />;
    } else if (data === "4") {
      return <Trans i18nKey={"titleHeader"} />;
    } else if (data === "5") {
      return <Trans i18nKey={"listedBuildingConservationArea"} />;
    } else if (data === "6") {
      return <Trans i18nKey={"existingMortgage"} />;
    } else if (data === "5") {
      return <Trans i18nKey={"homeownerDecidedNotToProceed"} />;
    }
  }

  clientLogTimeStampTemplate(data) {
    return format(new Date(data.logTime), "dd/MM/yyyy");
  }

  contactDateTemplate(data) {
    return format(new Date(data.uploadDate), "dd/MM/yyyy");
  }

  viewStatusLogTimeTemplate(data) {
    return format(new Date(data.logTime), "dd/MM/yyyy");
  }

  statusLogNotesTemplate(data) {
    if (data.statusLogNotes === "projectCreated") {
      return <Trans i18nKey="projectCreated" />;
    } else {
      return data.statusLogNotes;
    }
  }

  userTemplate(data) {
    return data.lastName + ", " + data.firstName;
  }

  timelineStatusTemplate(data) {
    let status = "";
    if (data.details === "0" || data.details === "3") {
      status = <Trans i18nKey={"factfindingStage"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "2") {
      status = <Trans i18nKey={"waitingForApprovalToProgressToSurvey"} />;
      return <div className={"mr-2 mb-2 p-float-label-orange"}>{status}</div>;
    } else if (data.details === "1" || data.details === "6") {
      status = <Trans i18nKey={"validationStage"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "4") {
      status = <Trans i18nKey={"worksStage"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "5") {
      status = <Trans i18nKey={"waitingForApprovalToContractWorks"} />;
      return <div className={"mr-2 mb-2 p-float-label-orange"}>{status}</div>;
    } else if (data.details === "8") {
      status = <Trans i18nKey={"monitoring"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "9") {
      status = <Trans i18nKey={"completed"} />;
      return <div className={"mr-2 mb-2 p-float-label-green"}>{status}</div>;
    } else if (data.details === "10") {
      status = <Trans i18nKey={"paused"} />;
      return <div className={"mr-2 mb-2 p-float-label-graybold"}>{status}</div>;
    } else if (data.details === "11") {
      status = <Trans i18nKey={"closed"} />;
      return <div className={"mr-2 mb-2 p-float-label-gray"}>{status}</div>;
    }
  }

  projectStatusCard(data, t) {
    let status = "";

    if (data.projectStatus === "0") {
      status = t("factfindingStage");
    } else if (data.projectStatus === "1") {
      status = t("validationStage");
    } else if (data.projectStatus === "2") {
      status = t("waitingForApprovalToProgressToSurvey");
    } else if (data.projectStatus === "3") {
      status = t("factfindingStage");
    } else if (data.projectStatus === "4") {
      status = t("worksStage");
    } else if (data.projectStatus === "5") {
      status = t("waitingForApprovalToContractWorks");
    } else if (data.projectStatus === "6") {
      status = t("validationStage");
    } else if (data.projectStatus === "8") {
      status = t("monitoring");
    } else if (data.projectStatus === "9") {
      status = t("completed");
    } else if (data.projectStatus === "10") {
      status = t("paused");
    } else if (data.projectStatus === "11") {
      status = t("closed");
    }

    return this.capitalizeFirstLetter(status);
  }

  calendarFormatTemplate = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  notesSort(rowData) {
    const sortNote = rowData.notes;
    if (sortNote.length > 40) {
      return sortNote.substring(0, 40) + "...";
    }
    return sortNote;
  }

  render() {
    const { t } = this.props;

    const rejectProjectDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => this.setState({ pmRejectDialogVisible: false })}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("reject")}
          icon="pi pi-save"
          className="p-button-danger"
          onClick={() => this.setState({ pmRejectConfirmDialogVisible: true })}
        />

        {/*pm Reject Confirm Dialog */}
        <ConfirmDialog
          visible={this.state.pmRejectConfirmDialogVisible}
          onHide={() => {
            this.setState({ pmRejectConfirmDialogVisible: false });
          }}
          message={
            <p>
              <b>{t("confirmMessage")}</b>
            </p>
          }
          header={t("confirm")}
          icon="pi pi-exclamation-triangle icon-red"
          accept={this.rejectPhase1}
        />
      </React.Fragment>
    );

    const rejectp4ProjectDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => this.setState({ pmReject4DialogVisible: false })}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("reject")}
          icon="pi pi-save"
          className="p-button-danger"
          onClick={() => this.setState({ pmReject4ConfirmDialogVisible: true })}
        />

        {/*pm Reject Confirm Dialog */}
        <ConfirmDialog
          visible={this.state.pmReject4ConfirmDialogVisible}
          onHide={() => {
            this.setState({ pmReject4ConfirmDialogVisible: false });
          }}
          message={
            <p>
              <b>{t("confirmMessage")}</b>
            </p>
          }
          header={t("confirm")}
          icon="pi pi-exclamation-triangle icon-red"
          accept={this.rejectPhase4}
        />
      </React.Fragment>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <div className={"col-12 "}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <Card
                    style={{
                      height: "3.8em",
                      background: "rgb(230, 240, 247)",
                    }}
                  >
                    <div className="flex align-items-center justify-content-center">
                      <Link
                        to={{ pathname: "/project", state: this.state.project }}
                        style={{ textDecoration: "none" }}
                      >
                        <Chip
                          style={{ fontSize: "18px", fontStyle: "Manrope" }}
                          label={
                            this.state.project.projectName +
                            " (ID: " +
                            this.projectIDTemplate(this.state.project.id) +
                            ")"
                          }
                          className="mb-2 custom-chip-home"
                        />
                      </Link>
                    </div>
                  </Card>

                  {/*Project status change card */}

                  {/*Project status update manuel*/}
                  {this.state.project.status === 2 &&
                    (this.state.project.projectStatus !== "2" ||
                      this.state.project.projectStatus !== "5") && (
                      <>
                        <br />
                        <br />
                        <Card style={{ background: "#EDEDED" }}>
                          <>
                            <div className="grid">
                              {/*project Status*/}
                              <div className="col-12 md:col-6 lg:col-6">
                                &nbsp;&nbsp;
                                <h5 style={{ color: "#0094A8" }}>
                                  <b>
                                    {t("projectStatus")}
                                    {": "}{" "}
                                  </b>
                                  {this.projectStatusCard(
                                    this.state.project,
                                    t
                                  )}
                                </h5>
                              </div>

                              {/*update status buttons*/}

                              <div className="col-12 md:col-6 lg:col-6 mb-4">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {" "}
                                  {!isViewerExpert() && (
                                    <Button
                                      label={t("updateStatus")}
                                      className="p-button-outlined"
                                      onClick={(e) => {
                                        this.setState({
                                          updateStatusDialogVisible: true,
                                          updateProjectStatus:
                                            this.emptyUpdateStatus,
                                        });
                                      }}
                                      disabled={
                                        this.state.project.projectStatus ===
                                          "2" ||
                                        this.state.project.projectStatus ===
                                          "5" ||
                                        this.state.project.projectStatus === "9"
                                      }
                                    />
                                  )}
                                  &nbsp;&nbsp;&nbsp;
                                  <Button
                                    label={t("viewStatusLog")}
                                    className="p-button-outlined"
                                    onClick={(e) => {
                                      this.setState({
                                        viewStatusLogDialogVisible: true,
                                      });
                                      this.viewStatusLog();
                                    }}
                                  />
                                </div>
                              </div>

                              {(this.state.project.projectStatus === "2" ||
                                this.state.project.projectStatus === "5") &&
                                (isPlatformManager() || isSupervisor()) && (
                                  <>
                                    <div className="w-full">
                                      <Button
                                        label={t("acceptPM")}
                                        style={{ width: "49%" }}
                                        className="p-button-mb"
                                        onClick={(e) => {
                                          this.state.project.projectStatus ===
                                          "2"
                                            ? this.setState({
                                                pmAcceptConfirmDialogVisible: true,
                                              })
                                            : this.setState({
                                                p4RequestAcceptConfirmDialogVisible: true,
                                              });
                                        }}
                                      />
                                      &nbsp; &nbsp; &nbsp;
                                      <Button
                                        label={t("rejectPM")}
                                        style={{ width: "49%" }}
                                        className="p-button-mb-2"
                                        onClick={(e) => {
                                          this.state.project.projectStatus ===
                                          "2"
                                            ? this.setState({
                                                pmRejectDialogVisible: true,
                                              })
                                            : this.setState({
                                                pmReject4DialogVisible: true,
                                              });
                                          this.state.project.pmNote = "";
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                            </div>
                          </>
                        </Card>
                      </>
                    )}

                  {/* Archive project reason card */}
                  {this.state.project.status === 4 && (
                    <>
                      <br />
                      <Card style={{ background: "#FFF2EB" }}>
                        <>
                          <div
                            class=" mr-2 mb-2 custom-chip-constant "
                            style={{
                              fontSize: "14px",
                              fontStyle: "Roboto",
                              maxWidth: "38%",
                              borderRadius: "30px",
                            }}
                          >
                            <p
                              class="card-text"
                              style={{ fontStyle: "Manrope", fontSize: "14px" }}
                            >
                              {" "}
                              <b>
                                {t("reason")}
                                {": "}{" "}
                              </b>
                              {this.state.project.archiveProjectReason}
                              {/* {t(
                                this.provideReasonTemplate(
                                  this.state.project.archiveProjectReason
                                )
                              )} */}
                            </p>
                          </div>
                        </>
                      </Card>
                    </>
                  )}
                  <br />

                  <TabView>
                    {/*phases */}
                    <TabPanel
                      header={
                        <h5>
                          <i
                            className="pi pi-share-alt"
                            style={{ fontSize: "1.2rem" }}
                          ></i>{" "}
                          {t("phases")}
                        </h5>
                      }
                    >
                      {/*Phase1 */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{ pathname: "/pi", state: this.state.project }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 1: " + t("phase1Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*Phase2 */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{ pathname: "/fs", state: this.state.project }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 2: " + t("phase2Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*Phase 3*/}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{
                              pathname: "/mortgageProcess",
                              state: this.state.project,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 3: " + t("phase3Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*Phase4 */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{
                              pathname: "/contractManagement",
                              state: this.state.project,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 4: " + t("phase4Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*Phase5 */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{
                              pathname: "/movingAndTemporaryRelocation",
                              state: this.state.project,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 5: " + t("phase5Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      <div style={{ display: isDevelopment() ? "" : "none" }}>
                        {/*Phase6 */}
                        <div className="flex ">
                          <span className="col-6">
                            <Link
                              to={{
                                pathname: "/financialManagement",
                                state: this.state.project,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Manrope",
                                  fontWeight: "bolder",
                                }}
                                label={t("phase") + " 6: " + t("phase6Name")}
                                className="mr-2 mb-2 custom-chip"
                              />
                            </Link>
                          </span>
                          <Divider layout="vertical" />
                        </div>
                        <hr />
                      </div>

                      {/*Phase7 */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{
                              pathname: "/practicalCompletion",
                              state: this.state.project,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 7: " + t("phase7Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      <div style={{ display: isDevelopment() ? "" : "none" }}>
                        {/*Phase8 */}
                        <div className="flex ">
                          <span className="col-6">
                            <Link
                              to={{
                                pathname: "/postCompletion",
                                state: this.state.project,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Manrope",
                                  fontWeight: "bolder",
                                }}
                                label={t("phase") + " 8: " + t("phase8Name")}
                                className="mr-2 mb-2 custom-chip"
                              />
                            </Link>
                          </span>
                          <Divider layout="vertical" />
                        </div>
                        <hr />
                      </div>

                      {/*Phase9 */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{
                              pathname: "/indoorQE",
                              state: this.state.project,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Chip
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                fontWeight: "bolder",
                              }}
                              label={t("phase") + " 9: " + t("phase9Name")}
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />
                    </TabPanel>

                    {/*clientLog */}
                    <TabPanel
                      header={
                        <h5>
                          <i
                            className="pi pi-map-marker"
                            style={{ fontSize: "1.2rem" }}
                          ></i>{" "}
                          {t("clientLog")}
                        </h5>
                      }
                    >
                      {/*addClientLog Button*/}
                      {!isViewerExpert() && (
                        <div className="field col-12 mb-0">
                          <Button
                            style={{
                              color: "0094A8",
                              fontSize: "12px",
                              fontStyle: "Manrope",
                            }}
                            disabled={
                              this.state.project.status === 3 ||
                              this.state.project.status === 4
                            }
                            label={t("btnAddClientLog")}
                            className="p-button p-component p-button-outlined p-button-sm"
                            onClick={() => {
                              this.setState({
                                clientLogDialogVisible: true,

                                contactType: null,
                                description: "",
                                notes: "",
                                uploadDate: null,
                              });
                            }}
                          />
                        </div>
                      )}

                      {/*ClientLog DataTable*/}
                      <div className="col-12">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                          <DataTable
                            name="dt"
                            size="small"
                            showGridlines
                            //selectionMode="single"
                            //value={this.state.project.clientLogList}
                            value={this.state.clientLogAndMonitoringVisit}
                            dataKey="id"
                            stripedRows
                            responsiveLayout="scroll"
                            emptyMessage={t("noLogFound")}
                            header={this.renderClientLogHeaderTableTop(t)}
                            paginator
                            rows={10}
                            filters={this.state.filters1}
                            filterDisplay="menu"
                            loading={this.state.loading}
                            globalFilterFields={[
                              "contactType",
                              "description",
                              "contactDate",
                              "notes",
                              "user",
                              "logTime",
                            ]}
                          >
                            <Column
                              header={t("communicationType")}
                              body={this.contactTypeTemplate}
                              sortable
                              sortField="contactType"
                            ></Column>

                            <Column
                              field="description"
                              header={t("description")}
                              style={{ width: "15em" }}
                            ></Column>

                            <Column
                              body={this.contactDateTemplate}
                              header={t("contactDate")}
                              sortField="uploadDate"
                              sortable
                            ></Column>

                            <Column
                              //field="notes"
                              header={t("notes")}
                              style={{ width: "25em" }}
                              body={this.notesSort}
                            ></Column>

                            <Column
                              field="userName"
                              header={t("user")}
                              sortable
                            ></Column>

                            <Column
                              body={this.clientLogTimeStampTemplate}
                              header={t("timeStamp")}
                              sortField="logTime"
                              sortable
                            ></Column>
                            <Column
                              header={t("action")}
                              style={{ textAlign: "center", width: "8em" }}
                              body={this.actionTemplate2}
                            />
                          </DataTable>
                        </div>
                      </div>
                    </TabPanel>

                    {/*documents*/}
                    <TabPanel
                      header={
                        <h5>
                          <i
                            className="pi pi-file"
                            style={{ fontSize: "1.2rem" }}
                          ></i>{" "}
                          {t("documents")}
                        </h5>
                      }
                    >
                      <div className="col-12">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                          <DataTable
                            name="dt"
                            size="small"
                            showGridlines
                            selectionMode="single"
                            value={this.state.documents}
                            dataKey="id"
                            stripedRows
                            responsiveLayout="scroll"
                            emptyMessage={t("noDocumentFound")}
                            header={this.renderHeaderTableTop(t)}
                            paginator
                            rows={10}
                            filters={this.state.filters1}
                            filterDisplay="menu"
                            loading={this.state.loading}
                            globalFilterFields={[
                              "fileName",
                              "phase",
                              "documentDate",
                            ]}
                          >
                            <Column
                              field="fileName"
                              header={t("documentName")}
                              sortable
                            ></Column>
                            <Column
                              field="phase"
                              header={t("phase")}
                              sortable
                            ></Column>
                            <Column
                              field="documentDate"
                              header={t("date")}
                              sortable
                            ></Column>
                            <Column
                              header={t("action")}
                              style={{ textAlign: "center", width: "8em" }}
                              body={this.actionTemplate}
                            />
                          </DataTable>
                        </div>
                      </div>
                    </TabPanel>

                    {/*images*/}
                    <TabPanel
                      header={
                        <h5>
                          <i
                            className="pi pi-image"
                            style={{ fontSize: "1.2rem" }}
                          ></i>{" "}
                          {t("photos")}
                        </h5>
                      }
                    >
                      {this.state.imageList1.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 1</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria1}
                              value={this.state.imageList1}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList1 &&
                                this.state.imageList1.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria1.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {this.state.imageList2.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 2</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria2}
                              value={this.state.imageList2}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList2 &&
                                this.state.imageList2.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria2.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {this.state.imageList3.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 3</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria3}
                              value={this.state.imageList3}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList3 &&
                                this.state.imageList3.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria3.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {this.state.imageList4.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 4</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria4}
                              value={this.state.imageList4}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList4 &&
                                this.state.imageList4.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria4.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {this.state.imageList5.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 5</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria5}
                              value={this.state.imageList5}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList5 &&
                                this.state.imageList5.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria5.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {this.state.imageList6.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 6</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria6}
                              value={this.state.imageList6}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList6 &&
                                this.state.imageList6.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria6.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {this.state.imageList7.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 7</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria7}
                              value={this.state.imageList7}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList7 &&
                                this.state.imageList7.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria7.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                      <br />

                      {this.state.imageList8.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 8</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria8}
                              value={this.state.imageList8}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList8 &&
                                this.state.imageList8.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria8.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                      <br />

                      {this.state.imageList9.length > 0 && (
                        <>
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <h5>Phase 9</h5>
                            <br />

                            <Galleria
                              ref={this.state.galleria9}
                              value={this.state.imageList9}
                              numVisible={1}
                              style={{ maxWidth: "850px" }}
                              activeIndex={this.state.activeIndex}
                              onItemChange={(e) => {
                                this.setState({
                                  activeIndex: e.index,
                                });
                              }}
                              circular
                              fullScreen
                              showItemNavigators
                              showThumbnails={false}
                              item={this.itemTemplate}
                              thumbnail={this.thumbnailTemplate}
                              customContent={
                                <Button
                                  label="Show Details"
                                  icon="pi pi-external-link"
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                    });
                                  }}
                                />
                              }
                              itemStyle={{ maxWidth: "100%" }}
                              thumbnailStyle={{
                                width: "20px",
                                height: "20px",
                              }}
                            />

                            <div className="grid" style={{ maxWidth: "400px" }}>
                              {this.state.imageList9 &&
                                this.state.imageList9.map((image, index) => {
                                  let imgEl = (
                                    <img
                                      src={image.thumbnailImageSrc}
                                      alt={image.alt}
                                      style={{
                                        cursor: "pointer",
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          activeIndex: index,
                                        });
                                        this.state.galleria9.current.show();
                                      }}
                                    />
                                  );
                                  return (
                                    <div className="col-3" key={index}>
                                      {imgEl}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}

                      <Dialog
                        visible={this.state.visible}
                        style={{ width: "50vw" }}
                        onHide={() => {
                          this.setState({
                            visible: false,
                          });
                        }}
                      >
                        {this.state.imageList &&
                          this.state.imageList[this.state.activeIndex] &&
                          this.itemTemplate(
                            this.state.imageList[this.state.activeIndex]
                          )}
                      </Dialog>
                    </TabPanel>

                    {/*Tasks*/}
                    <TabPanel
                      header={
                        <h5>
                          <i
                            className="pi pi-file"
                            style={{ fontSize: "1.2rem" }}
                          ></i>{" "}
                          {t("tasks")}
                        </h5>
                      }
                    >
                      <Tasks
                        pid={this.props.location.state.id}
                        projectName={this.state.project.projectName}
                      />
                    </TabPanel>

                    <br />
                  </TabView>
                </div>
              </div>

              {/*update status dialog */}
              <Dialog
                visible={this.state.updateStatusDialogVisible}
                style={{ width: "700px" }}
                modal
                header={t("updateStatusHeader")}
                className="p-fluid"
                onHide={() => {
                  this.setState({ updateStatusDialogVisible: false });
                }}
              >
                <div className="mb-1">
                  {(this.state.project.projectStatus === "8" ||
                    this.state.project.projectStatus === "4") && (
                    <label
                      htmlFor="projectStatus"
                      className="platformColor"
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                    >
                      {t("projectStatus")}
                    </label>
                  )}

                  {(this.state.project.projectStatus !== "8" ||
                    this.state.project.projectStatus !== "4") && (
                    <label
                      htmlFor="projectStatus"
                      className="platformColor"
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                    >
                      {t("projectStatus")} (*)
                    </label>
                  )}

                  {(this.state.project.projectStatus === "0" ||
                    this.state.project.projectStatus === "1" ||
                    this.state.project.projectStatus === "3" ||
                    this.state.project.projectStatus === "6") && (
                    <div className="mb-2">
                      <Dropdown
                        id="projectStatus"
                        options={projectStatus3}
                        value={this.state.updateProjectStatus.projectStatus}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "projectStatus"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  )}

                  {this.state.project.projectStatus === "4" && (
                    <div className="mb-2">
                      <Dropdown
                        id="projectStatus"
                        options={projectStatus4}
                        value={this.state.updateProjectStatus.projectStatus}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "projectStatus"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  )}

                  {this.state.project.projectStatus === "8" && (
                    <div className="mb-2">
                      <Dropdown
                        id="projectStatus"
                        options={projectStatus5}
                        value={this.state.updateProjectStatus.projectStatus}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "projectStatus"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  )}

                  {this.state.project.projectStatus === "10" && (
                    <div className="mb-2">
                      <Dropdown
                        id="projectStatus"
                        options={projectStatus6}
                        value={this.state.updateProjectStatus.projectStatus}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "projectStatus"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  )}

                  {this.state.project.projectStatus === "11" && (
                    <div className="mb-2">
                      <Dropdown
                        id="projectStatus"
                        options={projectStatus7}
                        value={this.state.updateProjectStatus.projectStatus}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "projectStatus"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  )}
                </div>

                {/*pausedReason */}
                {this.state.updateProjectStatus.projectStatus === "10" && (
                  <div className="mb-1">
                    <label
                      htmlFor="statusReason"
                      className="platformColor"
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                    >
                      {t("stateReason")} (*)
                    </label>

                    <div className="mb-2">
                      <Dropdown
                        id="projectStatus"
                        options={statusReason}
                        value={this.state.updateProjectStatus.pausedReason}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "pausedReason"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  </div>
                )}

                {/*closed Reason */}
                {this.state.updateProjectStatus.projectStatus === "11" && (
                  <div className="mb-1">
                    <label
                      htmlFor="closedReason"
                      className="platformColor"
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                    >
                      {t("stateReason")} (*)
                    </label>

                    <div className="mb-2">
                      <InputTextarea
                        fontSize="14px"
                        id="notes"
                        value={this.state.updateProjectStatus.closedReason}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeUpdateProjectStatus(
                            e,
                            "closedReason"
                          )
                        }
                        rows={3}
                        cols={20}
                        maxLength={100}
                        className="w-full"
                      />
                    </div>
                  </div>
                )}

                {/*notes */}
                <div className="p-float w-full mb-1">
                  <label className="platformColor" htmlFor="notes">
                    {t("notes")}
                  </label>

                  <InputText
                    id="notes"
                    onChange={(e) =>
                      this.onInputChangeUpdateProjectStatus(e, "notes")
                    }
                    maxLength={100}
                    className="w-full p-inputtext-sm block mb-3"
                    placeholder={t("pleaseEnter")}
                    value={this.state.updateProjectStatus.notes}
                  />
                </div>

                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({ updateStatusDialogVisible: false });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("SAVE")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.updateProjectStatus();
                    }}
                  />
                </div>
              </Dialog>

              {/*view status log dialog */}
              <Dialog
                visible={this.state.viewStatusLogDialogVisible}
                style={{ width: "700px" }}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ viewStatusLogDialogVisible: false });
                }}
                header={t("projectTimeline")}
              >
                <DataTable
                  name="dt"
                  size="small"
                  showGridlines
                  selectionMode="single"
                  value={this.state.projectStatusLogs}
                  dataKey="id"
                  stripedRows
                  responsiveLayout="scroll"
                  emptyMessage={t("noLogFound")}
                  paginator
                  rows={10}
                  filters={this.state.filters1}
                  filterDisplay="menu"
                  loading={this.state.loading}
                  globalFilterFields={[
                    "status",
                    "dataUpdated",
                    "user",
                    "notes",
                  ]}
                >
                  <Column
                    body={this.timelineStatusTemplate}
                    header={t("status")}
                    sortable
                  ></Column>

                  <Column
                    body={this.viewStatusLogTimeTemplate}
                    header={t("dataUpdated")}
                    sortable
                  ></Column>

                  <Column field="userName" header={t("user")} sortable>
                    {" "}
                  </Column>

                  <Column
                    body={this.statusLogNotesTemplate}
                    header={t("notes")}
                    sortable
                  ></Column>
                </DataTable>
              </Dialog>

              {/*add clientLog dialog */}
              <Dialog
                visible={this.state.clientLogDialogVisible}
                style={{ width: "700px" }}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ clientLogDialogVisible: false });
                }}
                header={t("addClientLog")}
              >
                {/*contactType*/}
                <div className="px-2">
                  <div id="dialogSearch">
                    <div className="p-float-label w-full">
                      <Dropdown
                        style={{ fontSize: "14px", fontStyle: "Roboto" }}
                        id="contactType"
                        options={contactType}
                        value={this.state.contactType}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.setState({ contactType: e.value })
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                      <label
                        htmlFor="contactType"
                        className="platformColor"
                        style={{ fontSize: "14px", fontStyle: "Roboto" }}
                      >
                        {t("contactType")} (*)
                      </label>
                    </div>
                  </div>
                </div>

                {/*description */}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <InputText
                      id="description"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      maxLength={100}
                      className="w-full p-inputtext-sm block mb-3"
                      value={this.state.description}
                    />
                    <label className="platformColor" htmlFor="description">
                      {t("description")} (*)
                    </label>
                  </div>
                </div>

                {/*notes */}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <InputTextarea
                      id="notes"
                      fontSize="14px"
                      onChange={(e) => this.setState({ notes: e.target.value })}
                      className="w-full p-inputtext-sm block mb-3"
                      value={this.state.notes}
                      rows={3}
                      cols={20}
                      maxLength={2000}
                    />
                    <label className="platformColor" htmlFor="notes">
                      {t("notes")}
                    </label>
                  </div>
                </div>

                {/*uploadDate*/}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <Calendar
                      id="uploadDate"
                      dateFormat="dd/mm/yy"
                      placeholder={t("pleaseEnter")}
                      value={this.state.uploadDate}
                      onChange={(e) => {
                        this.setState({
                          uploadDate: e.target.value,
                        });
                      }}
                      maxLength={10}
                      readOnlyInput={true}
                      disabled={this.state.nonEditAccess}
                    />
                    <label className="platformColor" htmlFor="date">
                      {t("contactDate")} (*)
                    </label>
                  </div>
                </div>
                <br />

                {/*save button */}
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    style={{ width: "20%" }}
                    label={t("save")}
                    className="p-button-mb"
                    onClick={() => {
                      this.addClientLog();
                    }}
                  />
                </div>
              </Dialog>

              {/*update clientLog dialog */}
              <Dialog
                visible={this.state.updateClientLogDialogVisible}
                style={{ width: "700px" }}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ updateClientLogDialogVisible: false });
                }}
                header={t("updateClientLog")}
              >
                {/*contactType*/}
                <div className="px-2">
                  <div id="dialogSearch">
                    <div className="p-float-label w-full">
                      <Dropdown
                        style={{ fontSize: "14px", fontStyle: "Roboto" }}
                        id="contactType"
                        options={contactType}
                        value={this.state.contactType}
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.setState({ contactType: e.value })
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                      <label
                        htmlFor="contactType"
                        className="platformColor"
                        style={{ fontSize: "14px", fontStyle: "Roboto" }}
                      >
                        {t("contactType")} (*)
                      </label>
                    </div>
                  </div>
                </div>

                {/*description */}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <InputText
                      id="description"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      maxLength={100}
                      className="w-full p-inputtext-sm block mb-3"
                      value={this.state.description}
                    />
                    <label className="platformColor" htmlFor="description">
                      {t("description")} (*)
                    </label>
                  </div>
                </div>

                {/*notes */}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <InputTextarea
                      id="notes"
                      fontSize="14px"
                      onChange={(e) => this.setState({ notes: e.target.value })}
                      className="w-full p-inputtext-sm block mb-3"
                      value={this.state.notes}
                      rows={3}
                      cols={20}
                      maxLength={2000}
                    />
                    <label className="platformColor" htmlFor="notes">
                      {t("notes")}
                    </label>
                  </div>
                </div>

                {/*uploadDate*/}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <Calendar
                      id="uploadDate"
                      dateFormat="dd/mm/yy"
                      placeholder={t("pleaseEnter")}
                      value={this.state.uploadDate}
                      onChange={(e) =>
                        this.setState({ uploadDate: e.target.value })
                      }
                      maxLength={10}
                      readOnlyInput={true}
                      disabled={this.state.nonEditAccess}
                    />
                    <label className="platformColor" htmlFor="date">
                      {t("contactDate")} (*)
                    </label>
                  </div>
                </div>
                <br />

                {/*save button */}
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    style={{ width: "20%" }}
                    label={t("update")}
                    className="p-button-mb"
                    onClick={() => {
                      this.updateClientLog();
                    }}
                  />
                </div>
              </Dialog>

              {/*pm Accept Confirm Dialog */}
              <ConfirmDialog
                visible={this.state.pmAcceptConfirmDialogVisible}
                onHide={() => {
                  this.setState({ pmAcceptConfirmDialogVisible: false });
                }}
                message={
                  <div>
                    <p>{t("pmAcceptMessage")}</p>
                    <p>
                      <b>{t("confirmMessage")}</b>
                    </p>
                  </div>
                }
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-green"
                accept={this.approvePhase1}
              />

              {/*pm Reject Dialog  */}
              <Dialog
                visible={this.state.pmRejectDialogVisible}
                style={{ width: "500px" }}
                header={t("rejectPM")}
                footer={rejectProjectDialogFooter}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ pmRejectDialogVisible: false });
                }}
              >
                <div>
                  <p>{t("pmRejectMessage")}</p>
                </div>

                {/*pm note */}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <InputText
                      id="notes"
                      value={this.state.project.pmNote}
                      maxLength={100}
                      onChange={(e) => {
                        this.setState({ pmNote: e.value });
                        this.onInputChange(e, "pmNote");
                      }}
                      className="w-full p-inputtext-sm block mb-3"
                    />
                    <label className="platformColor" htmlFor="notes">
                      {t("notes")}
                    </label>
                  </div>
                </div>
              </Dialog>

              {/*p4Request Accept Confirm Dialog */}
              <ConfirmDialog
                visible={this.state.p4RequestAcceptConfirmDialogVisible}
                onHide={() => {
                  this.setState({ p4RequestAcceptConfirmDialogVisible: false });
                }}
                message={
                  <div>
                    <p>{t("pmAcceptMessage")}</p>
                    <p>
                      <b>{t("confirmMessage")}</b>
                    </p>
                  </div>
                }
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-green"
                accept={this.approvePhase4}
              />

              <ConfirmDialog
                visible={this.state.deleteClientLogConfirmDialogVisible}
                onHide={() => {
                  this.setState({
                    deleteClientLogConfirmDialogVisible: false,
                  });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptLabel={t("Delete")}
                rejectLabel={t("cancel")}
                accept={this.acceptDeleteClientLog}
                reject={this.rejectDeleteClientLog}
              />

              {/*p4Request Reject Dialog  */}
              <Dialog
                visible={this.state.pmReject4DialogVisible}
                style={{ width: "500px" }}
                header={t("rejectPM")}
                footer={rejectp4ProjectDialogFooter}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ pmReject4DialogVisible: false });
                }}
              >
                <div>
                  <p>{t("pmRejectMessage")}</p>
                </div>

                {/*pm note */}
                <div className="px-2" id="dialogSearch">
                  <div className="p-float-label w-full">
                    <InputText
                      id="notes"
                      value={this.state.project.pmNote}
                      maxLength={100}
                      onChange={(e) => {
                        this.setState({ pmNote: e.value });
                        this.onInputChange(e, "pmNote");
                      }}
                      className="w-full p-inputtext-sm block mb-3"
                    />
                    <label className="platformColor" htmlFor="notes">
                      {t("notes")}
                    </label>
                  </div>
                </div>
              </Dialog>

              <WaitDialog loading={this.state.loading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(Project);
