import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import React, { useState } from "react";
import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";
import { InputTextarea } from "primereact/inputtextarea";
import { ToastContainer, toast } from "react-toastify";
import { Avatar } from "primereact/avatar";
import i18n from "i18next";

const SendEmail = ({
  t,
  selectedMembers,
  setLoading,
  selectedTask,
  setError,
  projectId,
}) => {
  const [mailText, setMailText] = useState("");

  const userTemplate = (user) => {
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    return (
      <div className="flex justify-content-center align-items-center">
        <Avatar
          shape="circle"
          label={user.firstName[0] + user.lastName[0]}
          style={{
            backgroundColor: "#0094A8",
            color: "#ffffff",
            borderRadius: "5rem",
          }}
          size="large"
          title={user.firstName + " " + user.lastName}
        ></Avatar>
      </div>
    );
  };

  const handleSendMail = () => {
    setLoading(true);
    SysService.sendTaskEmail(
      selectedTask?.taskName,
      mailText,
      projectId,
      selectedMembers
    )
      .then((response) => {
        setLoading(false);
        toast.success(i18n.t(response.data.message));
        setMailText("");
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <>
      <span className="platformColor">{t("sendThisTaskAsAnEmailToAllTaskMembers")}</span>
      <div
        className="flex flex-column gap-3 mt-3 mb-2 p-5"
        style={{
          width: "550px",
          height: "350px",
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: "#0094A8",
          backgroundColor: "#F6F4F4",
          borderRadius: "6px",
        }}
      >
        <span style={{ color: "#595959" }}>
          {t("assignedMembers")}
        </span>
        <Carousel
          value={selectedMembers}
          numVisible={6}
          numScroll={3}
          responsiveOptions={responsiveOptions}
          itemTemplate={userTemplate}
          className="w-full"
          showIndicators={false}
        />
        <div className="p-float-label w-full ">
          <InputTextarea
            style={{ resize: "none" }}
            value={mailText}
            onChange={(e) => setMailText(e.target.value)}
            rows={7}
            cols={80}
            id="mailText"
            className="w-full"
          />
          <label className="platformColor" htmlFor="mailText">
            {t("writeYourMessage")}
          </label>
        </div>
        <div className="flex aling-items-center justify-content-end">
          <Button
            label={t("send")}
            id="buttonFontWeight"
            style={{ height: "30px", width: "120px", background: "#F1812F" }}
            onClick={() => handleSendMail()}
          />
        </div>
      </div>

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.TOP_CENTER}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="colored"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
      />
    </>
  );
};

export default withTranslation()(SendEmail);
