const EnergyPerformanceClass = [
    { name: "Class A", code: "1" },
    { name: "Class B", code: "2" },
    { name: "Class C", code: "3" },
    { name: "Class D", code: "4" },
    { name: "Class E", code: "5" },
    { name: "Class F", code: "6" },
    { name: "Class G", code: "7" }
  ];
  
  export default EnergyPerformanceClass;