import axios from "axios";
import apios from "../common/apios";
import authHeader from "./auth-header";

class SysService {
  getUserInfo() {
    return apios.get("sys/userInfo", { headers: authHeader() });
  }

  changePassword(username, oldPassword, newPassword) {
    return apios.post(
      "sys/changeUserPassword",
      {
        username,
        oldPassword,
        newPassword,
      },
      { headers: authHeader() }
    );
  }

  updateUser(username, firstName, lastName, timeLogout) {
    return apios.post(
      "sys/updateUser",
      {
        username,
        firstName,
        lastName,
        timeLogout,
      },
      { headers: authHeader() }
    );
  }

  updatePlatformUser(username, firstName, lastName, role) {
    return apios.post(
      "sys/updatePlatformUser",
      {
        username,
        firstName,
        lastName,
        role,
      },
      { headers: authHeader() }
    );
  }

  getAdminsAndPlatformManagers() {
    return apios.get("sys/adminsAndPlatformManagers", {
      headers: authHeader(),
    });
  }

  getAllPlatformUserTypes() {
    return apios.get("sys/allPlatformUserTypes", {
      headers: authHeader(),
    });
  }

  addNewUser(username, firstName, lastName, role = null) {
    if (role === null) {
      return apios.post(
        "sys/addNewUser",
        {
          username,
          firstName,
          lastName,
        },
        { headers: authHeader() }
      );
    } else {
      return apios.post(
        "sys/addNewPlatformUser",
        {
          username,
          firstName,
          lastName,
          role,
        },
        { headers: authHeader() }
      );
    }
  }

  getUsers() {
    return apios.get("sys/users", { headers: authHeader() });
  }

  getProjectsByPlatformManager() {
    return apios.get("sys/projectsByPlatformManager", {
      headers: authHeader(),
    });
  }

  changeRole(username, rolename) {
    return apios.post(
      "sys/changeUserRole",
      {
        username,
        rolename,
      },
      { headers: authHeader() }
    );
  }

  changeProject(username, id) {
    return apios.post(
      "sys/changeUserProject",
      {
        username,
        id,
      },
      { headers: authHeader() }
    );
  }

  getUsersWithRole(roleName) {
    return apios.get("sys/usersWithRole", {
      params: {
        roleName: roleName,
      },
      headers: authHeader(),
    });
  }

  addNewProject(newProject) {
    return apios.post(
      "sys/addNewProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  updateProject(newProject) {
    return apios.post(
      "sys/updateProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  updateProjectRS(newProject) {
    return apios.post(
      "sys/updateProjectRS",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  getProjects() {
    return apios.get("sys/projects", { headers: authHeader() });
  }

  getSummary() {
    return apios.get("sys/summary", { headers: authHeader() });
  }

  getProject(pid) {
    return apios.get("sys/project", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  deleteProject(id) {
    return apios.get("sys/deleteProject", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  updateProjectStatus(newProject) {
    return apios.post(
      "sys/updateProjectStatus",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  archiveProject(newProject) {
    return apios.post(
      "sys/archiveProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  addClientLog(contactType, description, notes, uploadDate, projectId) {
    return apios.post(
      "sys/addClientLog",
      {
        contactType,
        description,
        notes,
        uploadDate,
        projectId,
      },
      { headers: authHeader() }
    );
  }

  updateClientLog(id, contactType, description, notes, uploadDate, projectId) {
    return apios.post(
      "sys/updateClientLog",
      {
        id,
        contactType,
        description,
        notes,
        uploadDate,
        projectId,
      },
      { headers: authHeader() }
    );
  }

  deleteClientLog(id) {
    return apios.get("sys/deleteClientLog", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  addMonitoringVisit(
    monitoringVisitsAndContacts,
    description,
    notes,
    uploadDate,
    projectId
  ) {
    return apios.post(
      "sys/addMonitoringVisit",
      {
        monitoringVisitsAndContacts,
        description,
        notes,
        uploadDate,
        projectId,
      },
      { headers: authHeader() }
    );
  }

  updateMonitoringVisit(
    id,
    monitoringVisitsAndContacts,
    description,
    notes,
    uploadDate
  ) {
    return apios.post(
      "sys/updateMonitoringVisit",
      {
        id,
        monitoringVisitsAndContacts,
        description,
        notes,
        uploadDate,
      },
      { headers: authHeader() }
    );
  }

  getMonitoringVisit(pid) {
    return apios.get("sys/getMonitoringVisit", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  deleteMonitoringVisit(id) {
    return apios.get("sys/deleteMonitoringVisit", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  acceptAddDocumentLink(
    fileDescription,
    documentName,
    documentURL,
    phase,
    projectId
  ) {
    return apios.post(
      "sys/acceptAddDocumentLink",
      {
        fileDescription,
        documentName,
        documentURL,
        phase,
        projectId,
      },
      { headers: authHeader() }
    );
  }

  deleteDocumentLink(id) {
    return apios.get("sys/deleteDocumentLink", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  getDocumentLink(pid, phase) {
    return apios.get("sys/getDocumentLinks", {
      params: {
        pid: pid,
        phase: phase,
      },
      headers: authHeader(),
    });
  }

  getBudgetBreakdownFileUploadURL(fileName, bbid, folder = null) {
    return apios.get("sys/budgetBreakdownFileUploadURL", {
      params: {
        fileName: fileName,
        bbid: bbid,
        folder: folder,
      },
      headers: authHeader(),
    });
  }

  getS3URL(fileName, phase, pid, folder = null) {
    return apios.get("sys/fileUploadURL", {
      params: {
        fileName: fileName,
        phase: phase,
        pid: pid,
        folder: folder,
      },
      headers: authHeader(),
    });
  }

  putFile2S3URL(fileUploadURL, file) {
    return axios.put(fileUploadURL, file, {
      headers: {
        "Content-Type": "txt/pdf",
      },
    });
  }

  getSensorData(eui) {
    return apios.get("sys/sensorData", {
      params: {
        eui: eui,
      },
      headers: authHeader(),
    });
  }

  getProjectStatusLogs(pid) {
    return apios.get("sys/projectStatusLogs", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  getBudget(pid) {
    return apios.get("sys/getBudget", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  getBudgetBreakdownByMonthAndYear(pid, month, year) {
    return apios.get("sys/getBudgetBreakdownByYearAndMonthValue", {
      params: {
        pid: pid,
        month: month,
        year: year,
      },
      headers: authHeader(),
    });
  }

  getBudgetBreakdowns(bid) {
    return apios.get("sys/getBudgetBreakdowns", {
      params: {
        bid: bid,
      },
      headers: authHeader(),
    });
  }

  updateBudgetBreakdown(newBudgetBreakdown) {
    return apios.post(
      "sys/updateBudgetBreakdown",
      {
        newBudgetBreakdown,
      },
      { headers: authHeader() }
    );
  }

  deleteBudgetBreakdown(bbid, bblength, orderId) {
    return apios.get("sys/deleteBudgetBreakdown", {
      params: {
        bbid: bbid,
        bblength: bblength,
        orderId: orderId,
      },
      headers: authHeader(),
    });
  }

  addBudgetBreakdownDocument(newBudgetBreakdown) {
    return apios.post(
      "sys/addBudgetBreakdownDocument",
      {
        newBudgetBreakdown,
      },
      { headers: authHeader() }
    );
  }

  getCostClaim(bbid) {
    return apios.get("sys/getCostClaim", {
      params: {
        bbid: bbid,
      },
      headers: authHeader(),
    });
  }

  deleteCostClaim(id) {
    return apios.get("sys/deleteCostClaim", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  updateCostClaim(claimStatus, amountClaimed, totaltoDate, note, bbid) {
    return apios.post(
      "sys/updateCostClaim",
      {
        claimStatus,
        amountClaimed,
        totaltoDate,
        note,
        bbid,
      },
      { headers: authHeader() }
    );
  }

  addCostClaim(
    month,
    year,
    claimStatus,
    amountClaimed,
    totaltoDate,
    note,
    projectId
  ) {
    return apios.post(
      "sys/addCostClaim",
      {
        month,
        year,
        claimStatus,
        amountClaimed,
        totaltoDate,
        note,
        projectId,
      },
      { headers: authHeader() }
    );
  }

  reLoadProject(newProject) {
    return apios.post(
      "sys/reLoadProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  trashProject(newProject) {
    return apios.post(
      "sys/trashProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  createNewOTPCode(newProject) {
    return apios.post(
      "sys/createNewOTPCode",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  getSensorDataByEui(euiString) {
    return apios.get("pub/sensorDataByEui", {
      params: {
        euiString: euiString,
      },
      headers: authHeader(),
    });
  }

  checkOTPCode(otpCode) {
    return apios.get("pub/checkOTPCode", {
      params: {
        otpCode: otpCode,
      },
      headers: authHeader(),
    });
  }

  addTask(
    taskName,
    notes,
    startDate,
    dueDate,
    priority,
    progress,
    projectId,
    assignMemberList,
    taskId
  ) {
    return apios.post(
      "sys/addTask",
      {
        taskName,
        notes,
        startDate,
        dueDate,
        priority,
        progress,
        projectId,
        assignMemberList,
        taskId,
      },
      { headers: authHeader() }
    );
  }

  getAllTasks(pid) {
    return apios.get("sys/getTasks", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  getTask(id) {
    return apios.get("sys/getTask", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  getTaskUsers(id) {
    return apios.get("sys/getTaskUsers", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  getAccessUserList(pid) {
    return apios.get("sys/getAccessUserList", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  deleteTask(id) {
    return apios.get("sys/deleteTask", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  updateTask(
    taskName,
    notes,
    startDate,
    dueDate,
    priority,
    progress,
    projectId,
    id,
    assignMemberList,
    calendarId,
    color
  ) {
    return apios.post(
      "sys/updateTask",
      {
        taskName,
        notes,
        startDate,
        dueDate,
        priority,
        progress,
        projectId,
        id,
        assignMemberList,
        calendarId,
        color,
      },
      { headers: authHeader() }
    );
  }

  getAllCalendarEvents(pid) {
    return apios.get("sys/calendarEvents", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  addCalendarEvent(subject, content, color, startDate, dueDate, projectId) {
    return apios.post(
      "sys/addCalendarEvent",
      {
        subject,
        content,
        color,
        startDate,
        dueDate,
        projectId,
      },
      { headers: authHeader() }
    );
  }

  updateCalendarEvent(
    calendarId,
    subject,
    content,
    color,
    startDate,
    dueDate,
    projectId,
    taskId
  ) {
    return apios.post(
      "sys/updateCalendarEvent",
      {
        calendarId,
        subject,
        content,
        color,
        startDate,
        dueDate,
        projectId,
        taskId,
      },
      { headers: authHeader() }
    );
  }

  deleteCalendarEvent(id) {
    return apios.get("sys/deleteCalendarEvent", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  sendTaskEmail(taskName, mailText, projectId, assignMemberList) {
    return apios.post(
      "sys/sendTaskEmail",
      {
        taskName,
        mailText,
        projectId,
        assignMemberList,
      },
      { headers: authHeader() }
    );
  }

  addTaskDiscussionMessage(projectId, taskId, body) {
    return apios.post(
      "sys/addTaskDiscussionMessage",
      {
        projectId,
        taskId,
        body,
      },
      { headers: authHeader() }
    );
  }

  getTaskDiscussions(taskId) {
    return apios.get("sys/taskDiscussions", {
      params: {
        taskId: taskId,
      },
      headers: authHeader(),
    });
  }

  deleteTaskDiscussionMessage(msgid) {
    return apios.get("sys/deleteTaskDiscussionMessage", {
      params: {
        msgid: msgid,
      },
      headers: authHeader(),
    });
  }
}
export default new SysService();
