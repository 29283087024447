const propertyType=[
    {name:"Flat",code:"1"},
    {name:"Detached",code:"2"},
    {name:"Semi-detached",code:"3"},
    {name:"Terraced",code:"4"},
    {name:"End of Terrace",code:"5"},
    {name:"Cottage",code:"6"},
    {name:"Bungalow",code:"7"},
    {name:"Other",code:"8"}
    ];
    
    export default propertyType;