const ethnicOrigin = [
  { name: "White British", code: "1" },
  { name: "White Irish", code: "2" },
  { name: "White Other Asian Indian", code: "3" },
  { name: "Chinese", code: "4" },
  { name: "Black Caribbean", code: "5" },
  { name: "Black Other", code: "6" },
  { name: "Mixed White & Black Caribbean", code: "7" },
  { name: "Asian Pakistani", code: "8" },
  { name: "Asian Bangladeshi", code: "9" },
  { name: "Asian Other", code: "10" },
  { name: "Mixed White & Asian", code: "11" },
  { name: "Mixed Other", code: "12" },
  { name: "Other", code: "13" },
  { name: "Prefer Not To Say", code: "14" }
];

export default ethnicOrigin;