import React, { useState, useEffect } from "react";
import { ScrollTop } from "primereact/scrolltop";
import WaitDialog from "../common/WaitDialog";
import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import {
  isAdmin,
  isPlatformManager,
  isProjectManager,
  isSupervisor,
  isViewerExpert,
  isProjectExpert,
} from "../common/token-check";
import { format } from "date-fns";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController,
} from "chart.js";

import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { tr } from "date-fns/locale";

const Home = ({ t }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    ArcElement,
    BarController
  );

  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);

  const [activeProjects, setActiveProjects] = useState([]);
  const [waitingProjects, setWaitingProjects] = useState([]);
  const [factfindingStageProjects, setFactfindingStageProjects] = useState([]);
  const [
    waitingForApprovalToProgressToSurveyProjects,
    setWaitingForApprovalToProgressToSurveyProjects,
  ] = useState([]);
  const [validationStageProjects, setValidationStageProjects] = useState([]);
  const [worksStageProjects, setWorksStageProjects] = useState([]);
  const [
    waitingForApprovalToContractWorksProjects,
    setWaitingForApprovalToContractWorksProjects,
  ] = useState([]);
  const [monitoringProjects, setMonitoringProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [pausedProjects, setPausedProjects] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);

  const [lastestActiveProjectDate, setLastestActiveProjectDate] = useState();
  const [lastestPausedProjectDate, setLastestPausedProjectDate] = useState();
  const [lastestCompletedProjectDate, setLastestCompletedProjectDate] =
    useState();
  const [lastestApprovedProjectDate, setLastestApprovedProjectDate] =
    useState();
  const [lastestActivityWorksStageDate, setLastestActivityWorksStageDate] =
    useState();

  const [allUsers, setAllUsers] = useState([]);
  const [allAssignedProjects, setAllAssignedProjects] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [lastestProjectManager, setLastestProjectManager] = useState();
  const [lastestUser, setLastestUser] = useState();

  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const [error, setError] = useState("");
  const [dataStackedBar, setDataStackedBar] = useState();

  const [statusProjectsList, setStatusProjectsList] = useState([]);
  const [statusProjectsListVisible, setStatusProjectsListVisible] =
    useState(false);
  const [statusProjectsListHeader, setStatusProjectsListHeader] = useState("");

  const dataBar = {
    labels: [
      t("factfindingStage"),
      t("waitingForApprovalToProgressToSurvey"),
      t("validationStage"),
      t("waitingForApprovalToContractWorks"),
      t("worksStage"),
      t("monitoring"),
    ],
    datasets: [
      {
        label: "Data",
        backgroundColor: [
          "#6C9AD9",
          "#B9B9B9",
          "#EE6C4F",
          "rgba(75, 192, 192, 0.2)",
          "#FBBA58",
          "#79BC7F",
        ],
        borderColor: [
          "#6C9AD9",
          "#B9B9B9",
          "#EE6C4F",
          "rgba(75, 192, 192, 1)",
          "#FBBA58",
          "#79BC7F",
        ],
        // barThickness: 20, // Barların kalınlığı
        // maxBarThickness: 25, // Barların maksimum kalınlığı
        // barPercentage: 0.7, // Her barın genişliği
        // categoryPercentage: 0.3, // Barlar arasındaki boşluk
        data: [
          -factfindingStageProjects?.length,
          -waitingForApprovalToProgressToSurveyProjects?.length,
          -validationStageProjects?.length,
          -waitingForApprovalToContractWorksProjects?.length,
          -worksStageProjects?.length,
          -monitoringProjects?.length,
        ],
      },
    ],
  };

  const projectIDTemplate = (data) => {
    let arr = [];
    arr = Array.from(String(data.id), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }

    let arrRes = arrX.concat(arr);

    return arrRes;
  };

  const handleLabelClick = (event, item) => {
    const index = item[0].index;
    const count = item[0].element.$context.raw; // - olarak tanımlı.
    if (count < 0) {
      setStatusProjectsListVisible(true);
    }

    if (index === 0) {
      setStatusProjectsList(factfindingStageProjects);
      setStatusProjectsListHeader(
        t("factfindingStage") + "( " + factfindingStageProjects.length + " )"
      );
    } else if (index === 1) {
      setStatusProjectsList(waitingForApprovalToProgressToSurveyProjects);
      setStatusProjectsListHeader(
        t("waitingForApprovalToProgressToSurvey") +
          "( " +
          waitingForApprovalToProgressToSurveyProjects.length +
          " )"
      );
    } else if (index === 2) {
      setStatusProjectsList(validationStageProjects);
      setStatusProjectsListHeader(
        t("validationStage") + "( " + validationStageProjects.length + " )"
      );
    } else if (index === 3) {
      setStatusProjectsList(waitingForApprovalToContractWorksProjects);
      setStatusProjectsListHeader(
        t("waitingForApprovalToContractWorks") +
          "( " +
          waitingForApprovalToContractWorksProjects.length +
          " )"
      );
    } else if (index === 4) {
      setStatusProjectsList(worksStageProjects);
      setStatusProjectsListHeader(
        t("worksStage") + "( " + worksStageProjects.length + " )"
      );
    } else if (index === 5) {
      setStatusProjectsList(monitoringProjects);
      setStatusProjectsListHeader(
        t("monitoring") + "( " + monitoringProjects.length + " )"
      );
    }
  };

  const optionsBar = {
    onClick: handleLabelClick,
    indexAxis: "y",
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Horizontal Bar Chart",
        position: "top",
      },
      datalabels: {
        anchor: "start",
        align: "end",
        color: "black",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: function (value, context) {
          return Math.abs(value);
        },
      },
    },
    barThickness: 25,

    scales: {
      y: {
        position: "left",
        grid: {
          display: false,
        },
        display: true,
      },
      x: {
        display: false,
      },
    },
  };

  const dataPie = {
    labels: [t("active"), t("closed"), t("completed"), t("paused")],
    datasets: [
      {
        label: "Data",
        backgroundColor: ["#648FC9", "#D8D8D8", "#0094A8", "#71716C"],
        borderColor: ["#648FC9", "#D8D8D8", "#0094A8", "#71716C"],
        borderWidth: 1,
        data: [
          activeProjects?.length,
          closedProjects?.length,
          completedProjects?.length,
          pausedProjects?.length,
        ],
      },
    ],
  };

  const optionsPie = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce(
              (acc, current) => acc + current,
              0
            );
            const percentage =
              total > 0 ? `${((value / total) * 100).toFixed(2)}%` : "";
            return `${label}: ${percentage}`;
          },
        },
      },

      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Pie Chart",
        position: "top",
      },
      datalabels: {
        display: false,
      },
    },
  };

  const optionsStackedBar = {
    indexAxis: "y",
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: false,
        text: "Stacked Bar Chart",
        position: "top",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        position: "left",
        grid: {
          display: false,
        },
        display: true,
      },
    },
  };

  // const optionsStackedBar = {
  //   type: "bar",
  //   options: {
  //     plugins: {
  //       tooltip: {
  //         enabled: false,
  //       },
  //       legend: {
  //         display: false,
  //       },
  //       title: {
  //         display: false,
  //         text: "Horizontal Bar Chart",
  //         position: "top",
  //       },
  //       datalabels: {
  //         anchor: "start",
  //         align: "end",
  //         color: "black",
  //         font: {
  //           weight: "bold",
  //           size: 12,
  //         },
  //       },
  //     },

  //     scales: {
  //       y: {
  //         position: "right",
  //         grid: {
  //           display: false,
  //           stacked: true,
  //         },
  //         display: false,
  //       },
  //       x: {
  //         display: false,
  //         stacked: true,
  //       },
  //     },
  //   },
  // };

  useEffect(() => {
    if (!isAdmin()) {
      setLoading(true);

      SysService.getUserInfo().then(
        (response) => {
          setUser(response.data);
          setLoading(false);
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data.tracid &&
              error.response.data.tracid.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString()
          );
        }
      );

      SysService.getUsers().then(
        (response) => {
          setAllUsers(response.data);

          const PMList = response.data.filter(
            (task) => task.userRole[0] === "ROLE_PROJECT_MANAGER"
          );

          setProjectManagers(PMList);

          const sortedAllUsers = response.data.sort(
            (objA, objB) =>
              Number(objB.registerDate) - Number(objA.registerDate)
          );

          setLastestUser(
            sortedAllUsers[0].firstname + " " + sortedAllUsers[0].lastname
          );

          const sortedProjectManagers = PMList.sort(
            (objA, objB) =>
              Number(objB.registerDate) - Number(objA.registerDate)
          );

          setLastestProjectManager(
            sortedProjectManagers[0]?.firstname +
              " " +
              sortedProjectManagers[0]?.lastname
          );
          setLoading(false);

          // const sortedList = activeP.sort(
          //   (objA, objB) =>
          //     Number(objB.registrationTime) - Number(objA.registrationTime)
          // );

          // setLastestActiveProjectDate(
          //   format(
          //     new Date(sortedList[sortedList.length - 1]?.registrationTime),
          //     "dd-MM-yyyy"
          //   )
          // );

          const projectManagersNames = PMList.map(
            (person) => `${person.firstname} ${person.lastname}`
          );
          const dataStackedBar = {
            labels: projectManagersNames,
            datasets: [
              {
                label: t("waiting"),
                backgroundColor: "#B9B9B9",
                data: PMList.map(
                  (person) =>
                    person.projectList?.filter(
                      (project) =>
                        project.projectStatus === "2" ||
                        project.projectStatus === "5"
                    ).length
                ),
              },
              {
                label: t("monitoring"),
                backgroundColor: "#70B877",
                data: PMList.map(
                  (person) =>
                    person.projectList?.filter(
                      (project) => project.projectStatus === "8"
                    ).length
                ),
              },
              {
                label: t("worksStage"),
                backgroundColor: "#FBBA58",
                data: PMList.map(
                  (person) =>
                    person.projectList?.filter(
                      (project) => project.projectStatus === "4"
                    ).length
                ),
              },
              {
                label: t("validation"),
                backgroundColor: "#EE6C4F",
                data: PMList.map(
                  (person) =>
                    person.projectList?.filter(
                      (project) =>
                        project.projectStatus === "1" ||
                        project.projectStatus === "6"
                    ).length
                ),
              },
              {
                label: t("factfinding"),
                backgroundColor: "#6C9AD9",
                data: PMList.map(
                  (person) =>
                    person.projectList?.filter(
                      (project) =>
                        project.projectStatus === "0" ||
                        project.projectStatus === "3"
                    ).length
                ),
              },
            ],
          };

          setDataStackedBar(dataStackedBar);
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data.tracid &&
              error.response.data.tracid.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString()
          );
        }
      );
      if (isPlatformManager() || isSupervisor()) {
        SysService.getProjectsByPlatformManager().then(
          (response) => {

            const activeP = response.data.projectActiveList.filter(
              (task) =>
                task.projectStatus === "0" ||
                task.projectStatus === "1" ||
                task.projectStatus === "2" ||
                task.projectStatus === "3" ||
                task.projectStatus === "4" ||
                task.projectStatus === "5" ||
                task.projectStatus === "6" ||
                task.projectStatus === "8"
            );

            const factfindingStage = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "0" || task.projectStatus === "3"
            );

            setFactfindingStageProjects(factfindingStage);
            const waitingForApprovalToProgressToSurvey =
              response.data.projectActiveList.filter(
                (task) => task.projectStatus === "2"
              );

            setWaitingForApprovalToProgressToSurveyProjects(
              waitingForApprovalToProgressToSurvey
            );

            const validationStage = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "1" || task.projectStatus === "6"
            );

            setValidationStageProjects(validationStage);

            const worksStage = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "4"
            );

            setWorksStageProjects(worksStage);

            const waitingForApprovalToContractWorks =
              response.data.projectActiveList.filter(
                (task) => task.projectStatus === "5"
              );

            setWaitingForApprovalToContractWorksProjects(
              waitingForApprovalToContractWorks
            );

            const monitoring = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "8"
            );

            setMonitoringProjects(monitoring);

            const completed = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "9"
            );

            setCompletedProjects(completed);

            const paused = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "10"
            );

            setPausedProjects(paused);

            const closed = response.data.projectActiveList.filter(
              (task) => task.projectStatus === "11"
            );

            setClosedProjects(closed);

            setActiveProjects(activeP);

            const sortedList = activeP.sort(
              (objA, objB) =>
                Number(objB.registrationTime) - Number(objA.registrationTime)
            );

            setLastestActiveProjectDate(
              format(
                new Date(sortedList[sortedList.length - 1]?.registrationTime),
                "dd-MM-yyyy"
              )
            );

            const waitingP = response.data.projectActiveList.filter(
              (data) => data.projectStatus === "2" || data.projectStatus === "5"
            );
            setWaitingProjects(waitingP);

            const completedProjectsSort = completed.sort(
              (objA, objB) =>
                Number(objB.lastModificationTime) -
                Number(objA.lastModificationTime)
            );

            setLastestApprovedProjectDate(
              format(
                new Date(
                  completedProjectsSort[
                    completedProjectsSort.length - 1
                  ]?.lastModificationTime
                ),
                "dd-MM-yyyy"
              )
            );

            //setUser(response.data);
            setLoading(false);
          },
          (error) => {
            setError(
              (error.response &&
                error.response.data.tracid &&
                error.response.data.tracid.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString()
            );
          }
        );
      } else if (isProjectManager() || isViewerExpert() || isProjectExpert()) {
        SysService.getProjects().then(
          (response) => {
            setAllAssignedProjects(
              response.data.projectResponseActiveList?.length +
                response.data.projectResponseArchiveList?.length +
                response.data.projectResponseDeleteList?.length
            );
            const activeP = response.data.projectResponseActiveList.filter(
              (task) =>
                task.projectStatus === "0" ||
                task.projectStatus === "1" ||
                task.projectStatus === "2" ||
                task.projectStatus === "3" ||
                task.projectStatus === "4" ||
                task.projectStatus === "5" ||
                task.projectStatus === "6" ||
                task.projectStatus === "8"
            );

            const factfindingStage =
              response.data.projectResponseActiveList.filter(
                (task) =>
                  task.projectStatus === "0" || task.projectStatus === "3"
              );

            setFactfindingStageProjects(factfindingStage);

            const waitingForApprovalToProgressToSurvey =
              response.data.projectResponseActiveList.filter(
                (task) => task.projectStatus === "2"
              );

            setWaitingForApprovalToProgressToSurveyProjects(
              waitingForApprovalToProgressToSurvey
            );

            const validationStage =
              response.data.projectResponseActiveList.filter(
                (task) =>
                  task.projectStatus === "1" || task.projectStatus === "6"
              );

            setValidationStageProjects(validationStage);

            const worksStage = response.data.projectResponseActiveList.filter(
              (task) => task.projectStatus === "4"
            );

            setWorksStageProjects(worksStage);

            const waitingForApprovalToContractWorks =
              response.data.projectResponseActiveList.filter(
                (task) => task.projectStatus === "5"
              );

            setWaitingForApprovalToContractWorksProjects(
              waitingForApprovalToContractWorks
            );

            const monitoring = response.data.projectResponseActiveList.filter(
              (task) => task.projectStatus === "8"
            );

            setMonitoringProjects(monitoring);

            const completed = response.data.projectResponseActiveList.filter(
              (task) => task.projectStatus === "9"
            );

            setCompletedProjects(completed);

            const paused = response.data.projectResponseActiveList.filter(
              (task) => task.projectStatus === "10"
            );

            setPausedProjects(paused);

            const closed = response.data.projectResponseActiveList.filter(
              (task) => task.projectStatus === "11"
            );

            setClosedProjects(closed);

            const worksStageP = response.data.projectResponseActiveList.filter(
              (task) => task.projectStatus === "4"
            );

            setWorksStageProjects(worksStageP);

            const sortedListWorksStageP = worksStageP?.sort(
              (objA, objB) =>
                Number(objB?.lastModificationDate) -
                Number(objA?.lastModificationDate)
            );

            setLastestActivityWorksStageDate(
              format(
                new Date(
                  sortedListWorksStageP?.[
                    sortedListWorksStageP?.length - 1
                  ]?.lastModificationDate
                ),
                "dd-MM-yyyy"
              )
            );

            setActiveProjects(activeP);

            const sortedListActive = activeP.sort(
              (objA, objB) =>
                Number(objB.registrationTime) - Number(objA.registrationTime)
            );

            setLastestActiveProjectDate(
              format(
                new Date(
                  sortedListActive[
                    sortedListActive.length - 1
                  ]?.registrationTime
                ),
                "dd-MM-yyyy"
              )
            );

            const sortedList = paused?.sort(
              (objA, objB) =>
                Number(objB?.registrationTime) - Number(objA?.registrationTime)
            );

            setLastestPausedProjectDate(
              format(
                new Date(
                  sortedList?.[sortedList?.length - 1]?.registrationTime
                ),
                "dd-MM-yyyy"
              )
            );

            const sortedListCompleted = completed?.sort(
              (objA, objB) =>
                Number(objB?.registrationTime) - Number(objA?.registrationTime)
            );

            setLastestCompletedProjectDate(
              format(
                new Date(
                  sortedListCompleted?.[
                    sortedListCompleted?.length - 1
                  ]?.registrationTime
                ),
                "dd-MM-yyyy"
              )
            );

            const waitingP = response.data.projectResponseActiveList.filter(
              (data) => data.projectStatus === "2" || data.projectStatus === "5"
            );
            setWaitingProjects(waitingP);
            //setUser(response.data);
            setLoading(false);
          },
          (error) => {
            setError(
              (error.response &&
                error.response.data.tracid &&
                error.response.data.tracid.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString()
            );
          }
        );
      }
    }

    initFilters1();
  }, []);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setGlobalFilterValue1("");
  };

  return (
    <div>
      <ScrollTop />

      {(isPlatformManager() || isSupervisor()) && (
        <>
          <div className="col-12 md:col-6 lg:col-12 mb-5">
            <span
              style={{
                fontFamily: "Manrope",
                fontSize: "24px",
                color: "#0094A8",
              }}
            >
              <b>
                {t("welcome") +
                  " " +
                  user?.users?.firstName +
                  " " +
                  user?.users?.lastName}
              </b>
            </span>
          </div>

          {/* <div className="col-12 md:col-6 lg:col-12">
            <span style={{ fontFamily: "Manrope", fontSize: "20px" }}>
              <b>{t("projects")}</b>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-12 mb-5">
            <span
              style={{
                fontFamily: "Manrope",
                fontSize: "12px",
                color: "#79747E",
              }}
            >
              <b>{t("homePageText")}</b>
            </span>
          </div> */}

          <div className="grid">
            <div className="col-12 md:col-6 lg:col-6">
              <div>
                <span style={{ fontFamily: "Manrope", fontSize: "20px" }}>
                  <b>{t("projects")}</b>
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    color: "#79747E",
                  }}
                >
                  <b>{t("homePageText")}</b>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-6">
              <div>
                <span style={{ fontFamily: "Manrope", fontSize: "20px" }}>
                  <b>{t("users")}</b>
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    color: "#79747E",
                  }}
                >
                  <b>{t("homePageText2")}</b>
                </span>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span
                      className="block mb-3"
                      style={{ fontSize: "15px", color: "#414140" }}
                    >
                      {t("totalActiveProjects")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {activeProjects?.length}
                      </span>{" "}
                    </div>
                    {/* <div className="text-900 font-medium text-l">
                      <span className="text-green-500 font-medium text-xl">
                        {lastestActiveProjectDate}
                      </span>{" "}
                      {activeProjects?.length > 0 && "/ " + t("newestProject")}
                    </div> */}
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <img
                      src="/assets/img/totalActiveProjects.png"
                      className="doc-icons"
                    ></img>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span
                      className="block mb-3"
                      style={{ fontSize: "15px", color: "#414140" }}
                    >
                      {t("projectHeader")} {t("waitingForApproval")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {waitingProjects?.length}
                      </span>{" "}
                    </div>
                    {/* <div className="text-900 font-medium text-l">
                      <span className="text-green-500 font-medium text-xl">
                        {lastestApprovedProjectDate}
                      </span>{" "}
                      {waitingProjects?.length > 0 && "/ " + t("latestCompletedProject")}
                    </div> */}
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <img
                      src="/assets/img/projectWaitingApproval.png"
                      className="doc-icons"
                    ></img>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span
                      className="block mb-3"
                      style={{ fontSize: "15px", color: "#414140" }}
                    >
                      {t("projectManagers")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {projectManagers.length}
                      </span>{" "}
                    </div>
                    {/* <div className="text-900 font-medium text-l">
                      <span className="text-green-500 font-medium text-xl">
                        {lastestProjectManager}
                      </span>{" "}
                      {projectManagers?.length > 0 && "/ " + t("newestProjectManager")}

                    </div> */}
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <img
                      src="/assets/img/projectManagers.png"
                      className="doc-icons"
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span
                      className="block mb-3"
                      style={{ fontSize: "15px", color: "#414140" }}
                    >
                      {t("allUsers")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {allUsers.length}
                      </span>{" "}
                    </div>
                    {/* <div className="text-900 font-medium text-l">
                      <span className="text-green-500 font-medium text-xl">
                        {lastestUser}
                      </span>{" "}
                      {allUsers?.length > 0 && "/ " + t("newestUser")}

                    </div> */}
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <i className="pi pi-users text-black-500 text-4xl"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="grid">
            <div className="col-12 md:col-6 lg:col-6">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 250 }}
              >
                <div className="grid">
                  <div className="col-12" style={{ marginTop: "1rem" }}>
                    <label
                      className="mb-4"
                      style={{ color: "#414140", fontSize: "15px" }}
                    >
                      {t("activeProjects")}
                    </label>
                  </div>
                  <br />
                  <Bar data={dataBar} options={optionsBar} />
                </div>
              </div>
              <br />
              <div className="grid">
                <div className="col-12 md:col-4 lg:col-4">
                  <div
                    className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                    style={{ minHeight: 100 }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-700 font-medium text-xl mb-3">
                          {t("completedProjects")}
                        </span>
                        <div className="text-900 font-medium text-l">
                          <span className="text-black-500 font-large text-6xl">
                            {completedProjects?.length}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-4 lg:col-4">
                  <div
                    className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                    style={{ minHeight: 100 }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-700 font-medium text-xl mb-3">
                          {t("pausedProjects")}
                        </span>
                        <div className="text-900 font-medium text-l">
                          <span className="text-black-500 font-large text-6xl">
                            {pausedProjects?.length}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 md:col-4 lg:col-4">
                  <div
                    className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                    style={{ minHeight: 100 }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-700 font-medium text-xl mb-3">
                          {t("closedProjects")}
                        </span>
                        <div className="text-900 font-medium text-l">
                          <span className="text-black-500 font-large text-6xl">
                            {closedProjects?.length}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-6">
              {dataStackedBar && (
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ height: "100%" }}
                >
                  <label
                    className="mb-4"
                    style={{ color: "#414140", fontSize: "15px" }}
                  >
                    {t("assignedProjectsProjectManagers")}
                  </label>

                  <Bar data={dataStackedBar} options={optionsStackedBar} />
                </div>
              )}
            </div>
          </div>
          <br />
        </>
      )}

      {(isProjectManager() || isViewerExpert() || isProjectExpert()) && (
        <>
          <div className="col-12 md:col-6 lg:col-12 mb-5">
            <span
              style={{
                fontFamily: "Manrope",
                fontSize: "24px",
                color: "#0094A8",
              }}
            >
              <b>
                {t("welcome") +
                  " " +
                  user?.users?.firstName +
                  " " +
                  user?.users?.lastName}
              </b>
            </span>
          </div>

          {/* <div className="col-12 md:col-6 lg:col-12">
            <span style={{ fontFamily: "Manrope", fontSize: "20px" }}>
              <b>{t("projects")}</b>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-12 mb-5">
            <span
              style={{
                fontFamily: "Manrope",
                fontSize: "12px",
                color: "#79747E",
              }}
            >
              <b>{t("homePageText")}</b>
            </span>
          </div> */}

          <div className="grid">
            <div className="col-12 md:col-6 lg:col-6">
              <div>
                <span style={{ fontFamily: "Manrope", fontSize: "20px" }}>
                  <b>{t("activeProjects")}</b>
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    color: "#79747E",
                  }}
                >
                  <b>{t("homePageText3")}</b>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-6">
              <div>
                <span style={{ fontFamily: "Manrope", fontSize: "20px" }}>
                  <b>{t("overallProjects")}</b>
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    color: "#79747E",
                  }}
                >
                  <b>{t("homePageText4")}</b>
                </span>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-700 font-medium text-xl mb-3">
                      {t("activeProjects")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {activeProjects?.length}
                      </span>{" "}
                    </div>
                    
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <i className="pi pi-folder text-black-500 text-3xl"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-700 font-medium text-xl mb-3">
                      {t("projectsInWorksStage")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {worksStageProjects?.length}
                      </span>{" "}
                    </div>
                    
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <img
                      src="/assets/img/wrench.png"
                      className="doc-icons"
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-700 font-medium text-xl mb-3">
                      {t("totalAssignedProjects")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {allAssignedProjects}
                      </span>{" "}
                    </div>
                    
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <img
                      className="doc-icons"
                      src="/assets/img/assignedProject.png"
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ minHeight: 100 }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-700 font-medium text-xl mb-3">
                      {t("completedProjects")}
                    </span>
                    <div className="text-900 font-medium text-l">
                      <span className="text-black-500 font-large text-6xl">
                        {completedProjects.length}
                      </span>{" "}
                    </div>
                    {/* <div className="text-900 font-medium text-l">
                      <span className="text-green-500 font-medium text-xl">
                        {lastestCompletedProjectDate}
                      </span>{" "}
                      {completedProjects.length > 0 &&
                        "/ " + t("latestCompletedProject")}
                    </div> */}
                  </div>
                  <div
                    className="flex align-items-center justify-content-center border-round"
                    style={{
                      width: "4.0rem",
                      height: "4.0rem",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    <i className="pi pi-check-square text-black-500 text-4xl"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="grid">
            <div className="col-12 md:col-6 lg:col-6">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ height: "100%" }}
              >
                <div className="grid">
                  <div className="col-12">
                    <label
                      className="mb-4"
                      style={{ color: "#414140", fontSize: "15px" }}
                    >
                      {t("activeProjects")}
                    </label>
                  </div>
                  <div className="col-12">
                    <br />
                    <Bar data={dataBar} options={optionsBar} />
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div className="col-12 md:col-6 lg:col-6">
              <div
                className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                style={{ height: "100%" }}
              >
                <label
                  className="mb-4"
                  style={{ color: "#414140", fontSize: "15px" }}
                >
                  {t("assignedProjectsDetail")}
                </label>

                <div className="grid">
                  <div className="col-1"></div>
                  <div className="col-2" style={{ marginTop: "4rem" }}>
                    <div
                      class="d-flex justify-content-between mb-2"
                      style={{ borderBottom: "2px solid #FBBA58" }}
                    >
                      <div className="mb-2">{t("active")}</div>
                      <div className="mb-2">{activeProjects.length}</div>
                    </div>
                    <div
                      class="d-flex justify-content-between mb-2"
                      style={{ borderBottom: "2px solid #FBBA58" }}
                    >
                      <div className="mb-2">{t("closed")}</div>
                      <div className="mb-2">{closedProjects.length}</div>
                    </div>
                    <div
                      class="d-flex justify-content-between mb-2"
                      style={{ borderBottom: "2px solid #FBBA58" }}
                    >
                      <div className="mb-2">{t("paused")}</div>
                      <div className="mb-2">{pausedProjects.length}</div>
                    </div>
                    <div
                      class="d-flex justify-content-between mb-2"
                      style={{ borderBottom: "2px solid #FBBA58" }}
                    >
                      <div className="mb-2">{t("completed")}</div>
                      <div className="mb-2">{completedProjects.length}</div>
                    </div>
                    <div
                      class="d-flex justify-content-between mb-2"
                      style={{ borderBottom: "2px solid #FBBA58" }}
                    >
                      <div className="mb-2">{t("total")}</div>
                      <div className="mb-2">
                        {activeProjects.length +
                          closedProjects.length +
                          completedProjects.length +
                          pausedProjects.length}
                      </div>
                    </div>
                  </div>

                  <div className="col-3"></div>
                  <div
                    className="col-5"
                    //style={{ marginTop: "2rem" }}
                  >
                    <br />
                    <Pie data={dataPie} options={optionsPie} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </>
      )}

      <Dialog
        visible={statusProjectsListVisible}
        style={{ width: "%100", height: "%100" }}
        modal
        className="p-fluid"
        onHide={() => setStatusProjectsListVisible(false)}
        header={statusProjectsListHeader}
        headerStyle={{ color: "#C05222" }}
      >
        <div className="mb-4">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dtDoc"
              size="small"
              showGridlines
              selectionMode="single"
              value={statusProjectsList}
              dataKey="fileName"
              stripedRows
              responsiveLayout="scroll"
              rows={10}
              loading={loading}
              globalFilterFields={["id", "projectName"]}
            >
              <Column
                header={t("projectID")}
                sortable
                sortField="id"
                body={projectIDTemplate}
              ></Column>

              <Column
                header={t("projectName")}
                sortable
                sortField="projectName"
                //body={this.actionTemplateName}
                field="projectName"
              ></Column>
            </DataTable>
          </div>
        </div>
      </Dialog>

      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.TOP_CENTER}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="colored"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
      />

      <br />
    </div>
  );
};

export default withTranslation()(Home);
