import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { TabView, TabPanel } from "primereact/tabview";
import { format } from "date-fns";
import { Link, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation, Trans } from "react-i18next";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";

import {
  isPlatformManager,
  isProjectManager,
  isUser,
  isSupervisor,
  isProjectExpert,
  isViewerExpert,
} from "../common/token-check";
import { connect } from "react-redux";
import { addNewProject } from "../actions/sys";
import { deleteProject } from "../actions/sys";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import SysService from "../services/sys.service";
import cities from "../common/ccs_cities";
import WaitDialog from "../common/WaitDialog";

import DeleteProjectReason from "../common/ccs_deleteProjectReason";

import exportOptions from "../common/ccs_exportOptions";
import projectStatusOptions from "../common/ccs_projectStatusOptions";

import pdfLogo from "../resources/lrs-logo.png";

class Projects extends Component {
  emptyNewProject = {
    projectId: "",
    projectName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    city: "",
    streetName: "",
    houseNumber: "",
    areWeProceeding: "1",
    pmApprove: "",
    town: "",
  };

  emptyArchiveProject = {
    pruid: "",
    archiveProjectReason: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      content: "",
      newProject: this.emptyNewProject,
      submitted: false,
      visible: false,

      rejectConfirmVisible: false,

      projects: [],
      deleteProjects: [],
      archiveProjects: [],
      selectedProject: null,
      filters1: null,
      globalFilterValue1: "",
      loading: true,
      successful: false,
      blockedPanel: false,
      projectLenght: 0,
      projectIndex: 0,

      pid: "",
      project: "",
      newArchiveProject: this.emptyArchiveProject,
      archiveProjectDialogVisible: false,
      selectedProject2: null,
      selectedProject3: null,

      archiveProjectReason: "",
      deleteProjectReason: "",
      deleteProjectNotes: "",
      selectedReloadProject: null,
      projectInfoDialogVisible: false,
      deleteProjectDialogVisible: false,
      exportFileType: "",
      exportFileVisible: false,

      exportOptionValue: null,
      includedProjectValue: null,

      sortedList: [],
      pdfUserName:"",
      pdfUserRole:"",
    };

    this.fileTypeOptions2 = [{ name: "CSV", code: "02" }];

    this.fileTypeOptions = [
      { name: "PDF", code: "01" },
      { name: "CSV", code: "02" },
    ];

    this.exportCSV = this.exportCSV.bind(this);
    this.exportPdf = this.exportPdf.bind(this);
    this.exportExcel = this.exportExcel.bind(this);

    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.onGlobalFilterChange1 = this.onGlobalFilterChange1.bind(this);
    this.createNew = this.createNew.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputChangeArchiveReason =
      this.onInputChangeArchiveReason.bind(this);
    this.onInputChangeDeleteReason = this.onInputChangeDeleteReason.bind(this);
    this.onInputChangeDeleteNote = this.onInputChangeDeleteNote.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.actionTemplateArchive = this.actionTemplateArchive.bind(this);
    this.actionTemplateDelete = this.actionTemplateDelete.bind(this);

    this.cols = [
      { field: "id", header: "Project ID" },
      { field: "projectName", header: "Project Name" },
      { field: "projectStatus", header: "Project Status" },
      { field: "dateOfBirth", header: "Age" },
      { field: "postcode", header: "Postcode" },
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  exportCSV(selectionOnly) {
    const allData = this.state.projects.map((project) => ({
      "Project ID": project.id,
      "Project name": project.projectName,
      "Project status": this.projectStatusTemplateCSV(project),
      City: project.city,
      Age: this.homeownerAgeTemplate(project),
      Postcode: project.postcode,
      "RegisteredDisabled": project.registeredDisabled,
      "Funder Name": project.funderName,      
    }));

    const headers = Object.keys(allData[0]);

    const dataToExport = selectionOnly
      ? this.dt.filteredValue || this.dt.value
      : allData;
    let csv = headers.join(",") + "\n";

    csv += dataToExport
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  exportCSVDetail(selectionOnly) {
    const allData = this.state.projects.map((project) => ({
        /*P1_step1 */
        "Project ID": project.id,
        "Project status": this.projectStatusTemplateCSV(project),
        "Project name": project.projectName,
        "Title": this.titleTemp(project.title),
        "Last Name": project.lastName,
        "First Name": project.firstName,
        "Middle Name": project.middleName,
        "Marital Status": this.maritalStatusTemp(project.maritalStatus),
        "Ethnic Origin": this.ethnicOriginTemp(project.ethnicOrigin),
        "Date of Birth":project.dateOfBirth,
        "Gender":this.genderTemp(project.gender) ,
         Age: this.homeownerAgeTemplate(project),
         City: project.city,
        "Town": project.town,
        "Borough (if relevant)": project.borough,
        Postcode: project.postcode,
        "Street Name": project.streetName,
        "House Name/Number": project.houseNumber,
        "E-mail": project.email,
        "Phone": project.phoneNumber,
        "Title of second homeowner (if applicable)": project.secondTitle,
        "Last name of second homeowner (if applicable)": project.secondLastName,
        "First name of second homeowner (if applicable)": project.secondFirstName,
        "Middle Name of second homeowner (if applicable)": project.secondMiddleName,      
        "Date of birth of second homeowner (if applicable)": project.secondDateOfBirth,  
        "Is the 2nd homeowner registered disabled?": this.yesNoTemp(project.secondRegisteredDisabled),
        "All correspondence must be addressed to": project.correspondenceAddress,
        "How did the client hear about us?": this.howdidYouHearAboutUsTemp(project.howdidYouHearAboutUs),
        "What are the works required?": project.worksRequired,

        /*P1_step2 */
        "Property Type": this.propertyTypeTemp(project.propertyType),
        "Number of Bedrooms in Property": this.numberOfBedroomsTemp(project.numberOfBedrooms),
        "Has Contact with a Social Worker or Other?": this.yesNoTemp(project.socialWorker),
        "Information on Professional Support": project.socialSupportInformation,
        "Number of Occupants": project.occupants,
        "Number of dependants under 18": project.numberOfDependantsUnder18,
        "Date of birth for dependants under 18": project.dateOfBirthDependantsUnder18,
        "Number of dependants over 18": project.numberOfDependantsOver18,
        "Date of birth for dependants over 18": project.dateOfBirthDependantsOver18,
        "Number of other occupants (other than homeowners)": project.numberOfOccupantsOther,
        "Name of Homeowner’s Representative": project.homeownerRepresentativeName,
        "Email of Homeowner’s Representative": project.homeownerRepresentativeEmail,
        "Phone of Homeowner’s Representative": project.homeownerRepresentativePhone,
        "Notes(P1/Step-2)": project.p11Notes,

        /*P1_step3 */
        "Is Power of Attorney required?": this.yesNoTemp(project.powerOfAttorneyRequired),
        "Validation of land registry title": this.yesNoPendingTemp(project.validationOfLandRegistryTitle),
        "Is there any ongoing mortgage on the property?": this.yesNoTemp(project.ongoingMortgageStatus),
        "Is there any other existing charge on the property": this.yesNoTemp(project.chargeOnProperty),
        "Property Assessment - Eligibility": this.yesNoTemp(project.propertyRiskAssessment),
        "Validation of proof of identity": this.idTypesTemp(project.idType),
        "Validation of proof of identity Value": this.yesNoPendingTemp(project.validationOfLandRegistryIdentity),
        "Validation of proof of address": this.yesNoPendingTemp(project.validationOfLandRegistryAddress),
        "Building insurance": this.yesNoPendingTemp(project.validationOfBuildingInsurance),
        "Upload of LRS Home Improvement Referral Form": this.yesNoPendingTemp(project.uploadLRSHomeImprovementReferralForm),
        "Notes(P1/Step-3)": project.p12Notes,

        /*P1_step4 */
        "Current Valuation (Desktop Valuation) (£)": project.currentValuation,
        "Post-works Estimation (Desktop valuation)": project.postworksEstimation,
        "Desktop Feasibility Completed": this.yesNoTemp(project.desktopFeasibility),
        "Has the homeowner tried to secure funding for repairs before?": this.yesNoTemp(project.fundingForRepairs),
        "Grant applied for": this.yesNoTemp(project.grantApplied),       
        "Grant scheme used": project.grantSchemeUsed,
        "Grant amount received": project.grantAmountReceived, 
        "Energy efficiency grant applied for": this.yesNoPendingTemp(project.energyEfficiencyGrantApplied),
        "Energy efficiency grant scheme used": project.energyEfficiencyGrantSchemeUsed,
        "Any loan(s) other than mortgage": this.yesNoTemp(project.loanStatus),
        "Loan provider": project.loanProvider,
        "Loan amount": project.loanAmount,
        "Notes(P1/Step-4)": project.p13Notes,

        /*P1_step5 */
        "Energy efficiency walkthrough survey report": this.yesNoPendingNotRequiredTemp(project.energyEfficiencySurveyReport),
        "Energy efficiency whole house survey report": this.yesNoPendingNotRequiredTemp(project.energyEfficiencyWholeHouse),
        "Energy bills (historic) uploaded": this.yesNoPendingTemp(project.energyBills),
        "Energy Performance Certificate (pre-works) received": this.yesNoPendingTemp(project.energyPerformanceCertificate),
        "Energy Performance Class (pre-works)": this.energyPerformanceClassTemp(project.energyPerformanceClassP1),
        "Wall Insulation": this.wallInsulationTemp(project.wallInsulationP1),
        "Floor Insulation": this.floorInsulationTemp(project.floorInsulationP1), 
        "Roof Insulation": this.roofInsulationTemp(project.roofInsulationP1), 
        "Windows Upgrade": this.yesNoTemp(project.windowsUpgradeP1), 
        "External Door Replacement": this.externalDoorReplacementTemp(project.externalDoorReplacementP1), 
        "Heating System Upgrade": this.heatingSystemUpgradeTemp(project.heatingSystemUpgradeP1), 
        "Solar": this.solarTemp(project.solarP1),
        "Ventilation": this.ventilationTemp(project.ventilationP1),      
        "Annual Gas Consumption (kWh)(Project Information and Initial Assessment)": project.annualGasConsumptionP1,
        "Annual Gas Consumption (kWh)(Project Information and Initial Assessment) ": project.annualGasConsumptionTimePeriodP1,
        "Annual Electricity Consumption (kWh)(Project Information and Initial Assessment)": project.annualElectricityConsumptionP1,
        "Annual Electricity Consumption (kWh)(Project Information and Initial Assessment) ": project.annualElectricityConsumptionTimePeriodP1,
        "Notes(P1/Step-5)": project.p14Notes,


        /*P1_step6 */
        "Check with underwriter": this.yesNoPendingTemp(project.checkWithUnderwriter),
        "Response from the underwriter": this.yesNoPendingTemp(project.responseFromtheUnderwriter),
        "Refer to advisor": this.yesNoPendingNotRequiredTemp(project.referToAdvisor),
        "Signature on Expression of Interest": this.yesNoPendingNotRequiredTemp(project.signatureOnExpressionOfInterest),
        "Notes(P1/Step-6)": project.p15Notes,

        /*P2_step1 */
        "Survey Booked": this.yesNoTemp(project.surveyBooked),
        "Survey Report received": this.yesNoPendingNotRequiredTemp(project.surveyReportReceived),
        "Property construction year": project.constructionYear,
        "Site visit photos uploaded": this.yesNoPendingTemp(project.photosUploaded),
        "Agree specification": this.yesNoPendingTemp(project.agreeSpecification),
        "Schedule of works and cost plan": this.yesNoPendingTemp(project.workandCostPlan),
        "Cost plan specification & post works valuation to underwriter & advisor": this.yesNoPendingTemp(project.workandCostPlanValuationtoUnderwriter),
        


        /*P2_step3*/
        "Wall Insulation(P2)": this.wallInsulationTemp(project.wallInsulationP2),
        "Floor Insulation(P2)": this.floorInsulationTemp(project.floorInsulationP2),
        "Roof Insulation(P2)": this.roofInsulationTemp(project.roofInsulationP2),
        "Windows Upgrade(P2)": this.yesNoTemp(project.windowsUpgradeP2),
        "External Door Replacement(P2)": this.externalDoorReplacementTemp(project.externalDoorReplacementP2),
        "Heating System Upgrade(P2)": this.heatingSystemUpgradeTemp(project.heatingSystemUpgradeP2),
        "Solar(P2)": this.solarTemp(project.solarP2),
        "Ventilation(P2)": this.ventilationTemp(project.ventilationP2),

         /*P3_step1*/
         "Has the lender accepted the desktop valuations? (If rejected, need to submit comparables below)": this.yesNoTemp(project.lendersValuation),
         "Has the lender accepted the desktop valuations? (If rejected, need to submit comparables below) ": this.yesNoTemp(project.comparablesAccepted),
         "KFI for mortgage received": this.yesNoPendingTemp(project.kfiForMortgage),
         "Mortgage application submitted": this.yesNoPendingTemp(project.mortgageSubmitted),
         "Funder name": project.funderName,
         "Funder customer ID": project.funderCustomerId,
         "Mortgage offer amount": project.mortgageOffer,
         "Client acceptance": this.yesNoPendingTemp(project.clientAcceptance),

          /*P4_step1*/
          "Name of Quantity Surveyor": project.nameOfQuantitySurveyor,
          "Tender pack prepared by Quantity Surveyor": this.yesNoPendingTemp(project.tenderPackPreparedByQuantitySurveyor),
          "Works agreement with homeowner signed": this.yesNoPendingTemp(project.worksAgreementWithHomeownerSigned),
         

          /*P4_step2*/
          "Tender pack sent to 3 contractors": this.yesNoPendingTemp(project.tenderPackSentTo3Contractors),
          "Contractor offers received": this.yesNoTemp(project.contractorOffersReceived),
          "Tender report with QS recommmendations on contractor": this.yesNoPendingTemp(project.tenderReport),
          "Contractor appointed": this.yesNoPendingTemp(project.contractorAppointed),
          "Name of the contractor": project.nameOfTheContractor,
          "Works start on site": this.yesNoPendingTemp(project.worksStartOnSite),
          "Planned date the works start on site": project.plannedDateTheWorksStartOnSite,
          "Actual date the works start on site": project.actualDateTheWorksStartOnSite,
          "Contract period (length of the contract in weeks)": project.contractPeriod,
          "Contract end date (agreed end date)": project.contractEndDate,
          "Notes(P4/Step-2)": project.p41Notes,

          /*P5_step1*/
          "Does homeowner need to relocate to a temporary accommodation?": this.yesNoTemp(project.needRelocateTemporary),
          "Rental agreement uploaded": this.yesNoPendingTemp(project.rentalAgreement),
          "Storage of the goods of the homeowner": this.yesNoPendingTemp(project.storageOfGoods),
          "Date the homeowner moved to the temporary accommodation": project.dateTheHomeownerMovedToTheTemporaryAcommodation,
          "Signed rental agreement uploaded": this.yesNoPendingTemp(project.signedRentalAgreementUploaded),
          "Start date of the rental agreement": project.startDateOfTheRentalAgreement,
          "End date of the rental agreement": project.endDateOfTheRentalAgreement,
          "Duration of the rental agreement(in weeks)": project.durationOfTheRentalAgreement,
          "Notes(P5/Step-1)": project.p50Notes,

          /*P7_step1*/
          "LRS and QS Visit the site post work to complete snagging report": this.yesNoPendingTemp(project.lrsAndQSVisit),
          "Date of visit": project.dateOfVisit,
          "Contractor completes outstanding works (if any)": this.yesNoPendingTemp(project.contractorCompletesOutstandingWorks),
          "LRS and QS final visit to confirm work is complete": this.yesNoPendingTemp(project.lrsAndQSFinalVisit),
          "Practical Completion": this.yesNoPendingTemp(project.practicalCompletion),
          "Date of Practical Completion": project.dateofPracticalCompletion,
          "Final account agreed with contractor": project.finalAccountAgreedWithContractor,
          "Period of Defects Start Date": project.periodofDefectsStartDate,
          "Period of Defects End Date": project.periodofDefectsEndDate,

           /*P8_step1*/
           "Homeowner goods/assets returned from storage and/or purchased": this.yesNoPendingTemp(project.homeownerReturnedFromStoragePurchased),
           "Homeowner returns to property": this.yesNoPendingTemp(project.homeownerReturnToProperty),
           "Homeowner returns to property ": project.homeownerDateOfReturn,
           "Notes(P8/Step-1)": project.p80Notes,

           /*P8_step3*/
           "Energy bills (new) uploaded": this.yesNoPendingTemp(project.energyBillsUploaded),
           "Energy Performance Certificate (post-works) uploaded": this.yesNoPendingTemp(project.energyPerformanceCertificateReceived),
           "Energy Performance Class (post-works)": this.energyPerformanceClassTemp(project.energyPerformanceClassP8),
           "Annual Gas Consumption (kWh)(Post Completion)": project.annualGasConsumptionP8,
           "Annual Gas Consumption (kWh)(Post Completion) ": project.annualGasConsumptionTimePeriodP8,
           "Annual Electricity Consumption (kWh)(Post Completion)": project.annualElectricityConsumptionP8,
           "Annual Electricity Consumption (kWh)(Post Completion) ": project.annualElectricityConsumptionTimePeriodP8,

    }));

    const headers = Object.keys(allData[0]);

    const dataToExport = selectionOnly
      ? this.dt.filteredValue || this.dt.value
      : allData;
    let csv = headers.join(",") + "\n";

    csv += dataToExport
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  exportPdf = (t) => {
    
    let projects = "";

    if (this.state.includedProjectValue === "01") {
      projects = [...this.state.projects];
    } else if (this.state.includedProjectValue === "02") {
      let arr1 = [...this.state.projects];
      let arr2 = [...this.state.archiveProjects];
      projects = arr1.concat(arr2);
    } else if (this.state.includedProjectValue === "03") {
      projects = [...this.state.archiveProjects];
    }

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        // const doc = new jsPDF.default({
        //   orientation: "landscape", // Yatay (landscape) veya dikey (portrait) olacak şekilde ayarlayabilirsiniz
        //   unit: "mm", // Birim: milimetre
        //   format: "a4", // A4 boyutunda sayfa
        //   compress: true, // PDF dosyasını sıkıştırma
        // });

        doc.addImage(pdfLogo, "png", 150, 5, 40, 20);

        const sortedProjects = projects
          .sort((a, b) => a.id - b.id)
          .map((project) => ({
            ...project,
            id: project.id.toString().padStart(5, "0"),
            funderName: project.funderName,
            registeredDisabled: project.registeredDisabled === "1" ? "Registered" : "Not Registered"
          }));

        for (let i = 0; i < sortedProjects.length; i++) {
          if (
            sortedProjects[i].dateOfBirth !== null &&
            sortedProjects[i].dateOfBirth !== "" &&
            sortedProjects[i].dateOfBirth !== undefined
          ) {
            const dob = new Date(sortedProjects[i].dateOfBirth);
            const now = new Date();

            let age = now.getFullYear() - dob.getFullYear();
            const monthDiff = now.getMonth() - dob.getMonth();
            if (
              monthDiff < 0 ||
              (monthDiff === 0 && now.getDate() < dob.getDate())
            ) {
              age--;
            }
            sortedProjects[i].dateOfBirth = age;
          }
        }

        for (let i = 0; i < sortedProjects.length; i++) {
          if (
            sortedProjects[i].projectStatus !== null &&
            sortedProjects[i].projectStatus !== ""
          ) {
            if (sortedProjects[i].projectStatus === "11") {
              sortedProjects[i].projectStatus = "Closed";
            } else if (sortedProjects[i].projectStatus === "10") {
              sortedProjects[i].projectStatus = "Paused";
            } else if (sortedProjects[i].projectStatus === "9") {
              sortedProjects[i].projectStatus = "Completed";
            } else if (sortedProjects[i].projectStatus === "8") {
              sortedProjects[i].projectStatus = "Monitoring";
            } else if (sortedProjects[i].projectStatus === "6") {
              sortedProjects[i].projectStatus = "validation stage";
            } else if (sortedProjects[i].projectStatus === "5") {
              sortedProjects[i].projectStatus =
                "Waiting for approval to contract works";
            } else if (sortedProjects[i].projectStatus === "4") {
              sortedProjects[i].projectStatus = "Works Stage";
            } else if (sortedProjects[i].projectStatus === "3") {
              sortedProjects[i].projectStatus = "Factfinding stage";
            } else if (sortedProjects[i].projectStatus === "2") {
              sortedProjects[i].projectStatus = "Monitoring";
            } else if (sortedProjects[i].projectStatus === "1") {
              sortedProjects[i].projectStatus = "Validation stage";
            } else if (sortedProjects[i].projectStatus === "0") {
              sortedProjects[i].projectStatus = "Factfinding stage";
            }
          }
        }

      
        const hhhlInfo = `London Rebuilding Society\nHealty Home Healty Lives PlatForm\nProject summary report\n`;
        doc.setFontSize(12); // Başlık metni boyutunu ayarlama
        doc.text(hhhlInfo, 15, 30); // Başlık metnini PDF'e ekleme

        // Gri arka plan üzerine userInfo metnini ekleme
        const userInfo = `User: ${
          this.state.pdfUserName
        }${
          this.state.pdfUserRole
        }\nDate: ${new Date().toLocaleDateString()}\nTime: ${new Date().toLocaleTimeString()}`;

        doc.setFillColor(240, 240, 240); // Gri arka plan rengini ayarlama
        doc.rect(12, 42, 185, 20, "F"); // Gri arka plan üzerine dikdörtgen çizme
        doc.setFontSize(10); // Metin boyutunu küçültme
        doc.text(userInfo, 15, 48); // Metni PDF'e ekleme

        // Tabloyu userInfo metninden aşağıya kaydırma
        const tableStartY = 70; // Tablonun başlangıç yüksekliği
        doc.autoTable({
          columns: [
            { header: 'Project ID', dataKey: 'id' },
            { header: 'Project name', dataKey: 'projectName' },
            { header: 'Project status', dataKey: 'projectStatus' },
            { header: 'Age', dataKey: 'dateOfBirth' },
            { header: 'Postcode', dataKey: 'postcode' },           
            { header: 'Registered disabled', dataKey: 'registeredDisabled' }, 
            { header: 'Funder name', dataKey: 'funderName' },
          ],
          body: sortedProjects,
          startY: tableStartY,
        });
        doc.save("projects.pdf");
      });
    });
  };

  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.state.projects);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "projects");
    });
  }

  saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  }

  titleTemp = (value) =>{
    let result ="";
   
    if (value === "1") {
      result = "Mr";
    } else if (value === "2") {
      result = "Mrs";
    }else if (value === "3") {
      result = "Miss";
    }else if (value === "4") {
      result = "Ms";
    }
    else if (value === "5") {
      result = "Dr";
    }
    else if (value === "6") {
      result = "Other";
    }
    return result;
  }

maritalStatusTemp = (value) =>{
  let result ="";

  if (value === "1") {
    result = "Single";
  } else if (value === "2") {
    result = "Married";
  }else if (value === "3") {
    result = "Other";
  }else if (value === "4") {
    result = "Prefer Not To Say";
  }
  return result;
}

ethnicOriginTemp = (value) =>{
  let result ="";  

  if (value === "1") {
    result = "White British";
  } else if (value === "2") {
    result = "White Irish";
  }else if (value === "3") {
    result = "White Other Asian Indian";
  }else if (value === "4") {
    result = "Chinese";
  }
  else if (value === "5") {
    result = "Black Caribbean";
  }
  else if (value === "6") {
    result = "Black Othe";
  }
  else if (value === "7") {
    result = "Mixed White & Black Caribbean";
  }
  else if (value === "8") {
    result = "Asian Pakistani";
  }
  else if (value === "9") {
    result = "Asian Bangladeshi";
  }
  else if (value === "10") {
    result = "Asian Other";
  }
  else if (value === "11") {
    result = "Mixed White & Asian";
  }
  else if (value === "12") {
    result = "Mixed Other";
  }
  else if (value === "13") {
    result = "Other";
  }
  else if (value === "14") {
    result = "Prefer Not To Say";
  }
  return result;
}

howdidYouHearAboutUsTemp = (value) =>{
  let result ="";

  if (value === "1") {
    result = "Another Client";
  } else if (value === "2") {
    result = "Council";
  }else if (value === "3") {
    result = "Social Worker";
  }else if (value === "4") {
    result = "Flyer";
  }  else if (value === "5") {
    result = "Internet";
  }  else if (value === "6") {
    result = "Local Ad";
  }  else if (value === "7") {
    result = "Word Of Mouth";
  }  else if (value === "8") {
    result = "Referral agency";
  }  else if (value === "9") {
    result = "Event";
  }
  return result;
}

genderTemp= (value) =>{
  let result ="";

  if (value === "1") {
    result = "Male";
  } else if (value === "2") {
    result = "Female";
  }
  else if (value === "3") {
    result = "Other";
  }
  else if (value === "4") {
    result = "Prefer Not To Say";
  }
  return result;
}

yesNoTemp= (value) =>{
  let result ="";

  if (value === "1") {
    result = "Yes";
  } else if (value === "2") {
    result = "No";
  }
  return result;
}

yesNoPendingTemp= (value) =>{
  let result ="";

  if (value === "1") {
    result = "Yes";
  } else if (value === "2") {
    result = "No";
  }else if (value === "3") {
    result = "Pending";
  }
  
  return result;
}

yesNoPendingNotRequiredTemp= (value) =>{
  let result ="";

  if (value === "1") {
    result = "Yes";
  } else if (value === "2") {
    result = "No";
  }else if (value === "3") {
    result = "Pending";
  }
  else if (value === "4") {
    result = "Not Required";
  }
  
  return result;
}

numberOfBedroomsTemp= (value) =>{
let result = "";
  if (value === "1") {
    result = "1";
  } else if (value === "2") {
    result = "2";
  }else if (value === "3") {
    result = "3";
  }else if (value === "4") {
    result = "4";
  }else if (value === "5") {
    result = "5-or More";
  }
  return result;
}

idTypesTemp = (value) =>{
  let result = "";

    if (value === "1") {
      result = "Passport";
    } else if (value === "2") {
      result = "Driving Licence";
    }else if (value === "3") {
      result = "Birth Certificate";
    }else if (value === "4") {
      result = "Other (NI, NHS no.)";
    }

    return result;
  }


propertyTypeTemp= (value) =>{
      let result ="";

     if (value === "1") {
        result = "Flat";
      } else if (value === "2") {
        result = "Detached";
      }else if (value === "3") {
        result = "Semi-detached";
      }else if (value === "4") {
        result = "Terraced";
      }else if (value === "5") {
        result = "End of Terrace";
      }else if (value === "6") {
        result = "Cottage";
      }else if (value === "7") {
        result = "Bungalow";
      }else if (value === "8") {
        result = "Other";
      }

      return result;
}

energyPerformanceClassTemp= (value) =>{
  let result ="";

 if (value === "1") {
    result = "Class A";
  } else if (value === "2") {
    result = "Class B";
  }else if (value === "3") {
    result = "Class C";
  }else if (value === "4") {
    result = "Class D";
  }else if (value === "5") {
    result = "Class E";
  }else if (value === "6") {
    result = "Class F";
  }else if (value === "7") {
    result = "Class G";
  }

  return result;
}

wallInsulationTemp= (value) =>{
  let result ="";

 if (value === "1") {
    result = "External";
  } else if (value === "2") {
    result = "Internal";
  }else if (value === "3") {
    result = "Cavity";
  }else if (value === "4") {
    result = "More than one";
  }

  return result;
}

floorInsulationTemp= (value) =>{
  let result ="";

 if (value === "1") {
    result = "Timber";
  } else if (value === "2") {
    result = "Solid";
  }else if (value === "3") {
    result = "More than one";
  }

  return result;
}

roofInsulationTemp= (value) =>{
  let result ="";
 
  if (value === "1") {
    result = "Loft";
  } else if (value === "2") {
    result = "Room in roof";
  }else if (value === "3") {
    result = "Extension";
  }else if (value === "4") {
    result = "More than one";
  }

  return result;
}

externalDoorReplacementTemp = (value) =>{
  let result ="";
 
  if (value === "1") {
    result = "Front";
  } else if (value === "2") {
    result = "Back";
  }else if (value === "3") {
    result = "Front and back";
  }

  return result;
}

heatingSystemUpgradeTemp = (value) =>{
  let result ="";

  if (value === "1") {
    result = "New gas boiler";
  } else if (value === "2") {
    result = "Controls";
  }else if (value === "3") {
    result = "Underfloor heating";
  }  else if (value === "4") {
    result = "Heat pump";
  }else if (value === "5") {
    result = "More than one";
  }

  return result;
}
solarTemp = (value) =>{
    let result ="";
   
    if (value === "1") {
      result = "Photovoltaic (electricity)";
    } else if (value === "2") {
      result = "Thermal (hot water)";
    }

    return result;
  }

  ventilationTemp = (value) =>{
    let result ="";

    if (value === "1") {
      result = "Trickle vents";
    } else if (value === "2") {
      result = "Extractor fans";
    }else if (value === "3") {
      result = "Whole house system";
    }

    return result;
  }



toCapitalize(s) {
return s.charAt(0).toUpperCase() + s.slice(1);
}

  handleDeleteProject = (props) => {
    if (
      this.state.deleteProjectReason == null ||
      this.state.deleteProjectReason.trim() === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    } else {
      this.setState({
        rejectConfirmVisible: true,
      });
    }
  };

  deleteProject = () => {
    let pid = this.state.selectedProject2.id.toString();
    this.setState({ loading: true });

    this.props
      .dispatch(deleteProject(pid).toString())
      .then(() => {
        this.setState({
          loading: false,
          acceptConfirmVisible: false,
        });

        toast.success(<Trans i18nKey={this.props.message} />);
        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message} />);
      });
    this.componentDidMount();
  };

  deleteProject2 = () => {
    let pid = this.state.selectedProject2.id.toString();
    this.setState({ loading: true });

    SysService.deleteProject(pid).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          saveButtonStatus: false,
        });

        toast.success(<Trans i18nKey="deleteProjectSuccessful" />);
        this.componentDidMount();
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  updateProject = () => {
    if (
      this.state.deleteProjectReason == null ||
      this.state.deleteProjectReason.trim() === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({ loading: true, deleteProjectDialogVisible: false });
    }

    let deleteLocalProject = { ...this.state.selectedProject2 };
    deleteLocalProject[`${"deleteProjectReason"}`] =
      this.state.deleteProjectReason;
    deleteLocalProject[`${"deleteProjectNotes"}`] =
      this.state.deleteProjectNotes;
    deleteLocalProject[`${"projectId"}`] =
      this.state.selectedProject2.id.toString();
    deleteLocalProject.phase = "DeleteProject";

    SysService.updateProject(deleteLocalProject).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          saveButtonStatus: false,
        });

        this.deleteProject2();
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        toast.error(<Trans i18nKey={error.response} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.componentDidMount();
  };

  clickLink = () => {
    const hiddenLink = document.getElementById("hiddenLink");
    if (hiddenLink && this.state.successful) {
      hiddenLink.click();
    }
    this.setState({
      loading: false,
    });
  };

  accept() {
    const { history } = this.props;

    if (
      this.state.newProject.projectName == null ||
      this.state.newProject.projectName.trim() === "" ||
      this.state.newProject.firstName == null ||
      this.state.newProject.firstName.trim() === "" ||
      this.state.newProject.lastName == null ||
      this.state.newProject.lastName.trim() === "" ||
      this.state.newProject.streetName == null ||
      this.state.newProject.streetName.trim() === "" ||
      this.state.newProject.houseNumber == null ||
      this.state.newProject.houseNumber.trim() === "" ||
      ((this.state.newProject.city == null ||
        this.state.newProject.city.trim() === "") &&
        this.state.newProject.city !== "Other") ||
      ((this.state.newProject.town == null ||
        this.state.newProject.town.trim() === "") &&
        this.state.newProject.city === "Other")
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    this.setState({
      successful: false,
      loading: true,
      visible: false,
    });

    this.props
      .dispatch(addNewProject(this.state.newProject))
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
          newProject: this.emptyNewProject,
          submitted: false,
          visible: false,
          projectDialog: false,
        });
        toast.success(<Trans i18nKey={this.props.message} />);

        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          successful: false,
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message} />);
      });

    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.clickLink();
    }, 1500);
  }

  archiveProject = () => {
    if (
      this.state.archiveProjectReason == null ||
      this.state.archiveProjectReason.trim() === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({ loading: true, archiveProjectDialogVisible: false });
    }

    let archiveLocalProject = { ...this.state.newArchiveProject };
    archiveLocalProject[`${"projectId"}`] =
      this.state.selectedProject3.id.toString();
    archiveLocalProject[`${"archiveProjectReason"}`] =
      this.state.archiveProjectReason;

    SysService.archiveProject(archiveLocalProject).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          blockedPanel: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        this.componentDidMount();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  };

  dateTableTemplate = (data) => {
    return format(new Date(data.registrationTime), "dd/MM/yyyy");
  };

  viewStatusLogTimeTemplate(data) {
    return format(new Date(data.logTime), "dd/MM/yyyy");
  }

  statusLogNotesTemplate(data) {
    if (data.statusLogNotes === "projectCreated") {
      return <Trans i18nKey="projectCreated" />;
    } else {
      return data.statusLogNotes;
    }
  }

  timelineStatusTemplate(data) {
    let status = "";

    if (data.details === "0" || data.details === "3") {
      status = <Trans i18nKey={"factfindingStage"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "2") {
      status = <Trans i18nKey={"waitingForApprovalToProgressToSurvey"} />;
      return <div className={"mr-2 mb-2 p-float-label-orange"}>{status}</div>;
    } else if (data.details === "1" || data.details === "6") {
      status = <Trans i18nKey={"validationStage"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "4") {
      status = <Trans i18nKey={"worksStage"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "5") {
      status = <Trans i18nKey={"waitingForApprovalToContractWorks"} />;
      return <div className={"mr-2 mb-2 p-float-label-orange"}>{status}</div>;
    } else if (data.details === "8") {
      status = <Trans i18nKey={"monitoring"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    } else if (data.details === "9") {
      status = <Trans i18nKey={"completed"} />;
      return <div className={"mr-2 mb-2 p-float-label-green"}>{status}</div>;
    } else if (data.details === "10") {
      status = <Trans i18nKey={"paused"} />;
      return <div className={"mr-2 mb-2 p-float-label-graybold"}>{status}</div>;
    } else if (data.details === "11") {
      status = <Trans i18nKey={"closed"} />;
      return <div className={"mr-2 mb-2 p-float-label-gray"}>{status}</div>;
    }
  }

  exportProjectData = (t) => {
    if (
      this.state.exportFileType !== null &&
      this.state.exportFileType !== "" &&
      this.state.includedProjectValue !== null &&
      this.state.includedProjectValue !== ""
    ) {

      if (this.state.exportFileType === "01") {
        this.exportPdf(t);
      } else if (this.state.exportFileType === "02" && this.state.exportOptionValue === "02" ) {
        this.exportCSVDetail(false);
        //this.exportExcel();
      }
      else if (this.state.exportFileType === "02" && this.state.exportOptionValue === "01" ) {
        this.exportCSV(false);
       // this.exportExcel();
      }
      this.setState({ exportFileVisible: false });
    } else {
      toast.warning(<Trans i18nKey="warnExportFileEmpty" />);
      return;
    }
    this.setState({
      exportFileType: "",
      exportOptionValue:null,
       includedProjectValue:null,
    });
  };

  reject() {}

  componentDidMount() {
    this.setState({ loading: true });
    this.getUserInfo();

    if (isProjectManager() || isViewerExpert() || isProjectExpert()) {
      SysService.getProjects().then(
        (response) => {
          this.setState({
            projects: response.data.projectResponseActiveList,
            deleteProjects: response.data.projectResponseDeleteList,
            archiveProjects: response.data.projectResponseArchiveList,
            loading: false,
            blockedPanel: false,
          });

          this.setState({
            sortedList: response.data.projectResponseActiveList.sort(
              (a, b) =>
                new Date(b.registrationTime) - new Date(a.registrationTime)
            ),
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    } else {
      SysService.getProjectsByPlatformManager().then(
        (response) => {
          this.setState({
            projects: response.data.projectActiveList,
            deleteProjects: response.data.projectDeleteList,
            archiveProjects: response.data.projectArchiveList,
            loading: false,
            blockedPanel: false,
          });

          this.setState({
            sortedList: response.data.projectActiveList.sort(
              (a, b) =>
                new Date(b.registrationTime) - new Date(a.registrationTime)
            ),
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
    this.initFilters1();
  }

  createNew() {
    this.setState({
      newProject: this.emptyNewProject,
      submitted: false,
      projectDialog: true,
    });
  }

  hideDialog() {
    this.setState({
      submitted: false,
      projectDialog: false,
    });
  }

  onExportOptionChange(e) {
    const val = (e.target && e.target.value) || "";
    this.setState({
      exportOptionValue: val,
    });
    
  }

  onExportFileChange(e) {
    const val = (e.target && e.target.value) || "";
    this.setState({
      exportFileType: val,
    });
  }

  onIncludedProjectChange(e) {
    const val = (e.target && e.target.value) || "";
    this.setState({
      includedProjectValue: val,
    });
  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || "";
    let newProject = { ...this.state.newProject };
    newProject[`${name}`] = val;

    this.setState({
      newProject,
    });
  }

  onInputChangeArchiveReason(e) {
    const val = (e.target && e.target.value) || "";
    this.setState({ archiveProjectReason: val });
  }

  onInputChangeDeleteReason(e) {
    const val = (e.target && e.target.value) || "";
    this.setState({ deleteProjectReason: val });
  }

  onInputChangeDeleteNote(e) {
    const val = (e.target && e.target.value) || "";
    this.setState({ deleteProjectNotes: val });
  }

  onGlobalFilterChange1(e) {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  }

  initFilters1() {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  }

  renderHeaderTableTop(t, param) {
    return (
      <div className="flex justify-content-between">
        <span>{t(param)}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  actionTemplate(data, props) {
    if (data.projectStatus === "9" || data.projectStatus === "11") {
      return (
        <>
          <div>
            {(isSupervisor() || isPlatformManager()) &&
              data.status !== 3 &&
              data.status !== 4 && (
                <Button
                  style={{ color: "#0094A8" }}
                  icon="pi pi-inbox"
                  tooltip="Archive Project"
                  className="p-button-rounded p-button-text"
                  onClick={() => {
                    this.setState({
                      archiveProjectReason: "",
                      archiveProjectDialogVisible: true,
                      selectedProject3: data,
                    });
                  }}
                />
              )}

            {isPlatformManager() && (
              <Button
                icon="pi pi-trash"
                style={{ color: "#ADADAD" }}
                tooltip="Delete Project"
                className="p-button-rounded p-button-text"
                onClick={() => {
                  this.setState({
                    deleteProjectReason: null,
                    deleteProjectNotes: "",
                    selectedProject2: data,
                    deleteProjectDialogVisible: true,
                  });
                }}
              />
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          {isPlatformManager() && (
            <Button
              icon="pi pi-trash"
              style={{ color: "#ADADAD" }}
              tooltip="Delete Project"
              className="p-button-rounded p-button-text"
              onClick={() => {
                this.setState({
                  deleteProjectReason: null,
                  deleteProjectNotes: "",
                  selectedProject2: data,
                  deleteProjectDialogVisible: true,
                });
              }}
            />
          )}
        </>
      );
    }
  }

  actionTemplateArchive(data, props) {
    return (
      <>
        <div>
          {(isSupervisor() || isPlatformManager()) && (
            <>
              <Button
                style={{ color: "#0094A8" }}
                icon="pi pi-exclamation-circle"
                tooltip="Project Info"
                className="p-button-rounded p-button-text"
                onClick={() => {
                  this.setState({
                    projectInfoDialogVisible: true,
                  });
                  this.projectInfo(data);
                }}
              />
              <Button
                style={{ color: "#0094A8" }}
                icon="pi pi-replay"
                tooltip="Replay Project"
                className="p-button-rounded p-button-text"
                onClick={() => {
                  this.setState({
                    selectedReloadProject: data,
                    reloadConfirmDialogVisible: true,
                  });
                }}
              />
            </>
          )}

          {isPlatformManager() && (
            <Button
              style={{ color: "#ADADAD" }}
              icon="pi pi-trash"
              tooltip="Delete Project"
              className="p-button-rounded p-button-text"
              onClick={() => {
                this.setState({
                  deleteProjectReason: null,
                  deleteProjectNotes: "",
                  selectedProject2: data,
                  deleteProjectDialogVisible: true,
                });
              }}
            />
          )}
        </div>
      </>
    );
  }

  actionTemplateDelete(data, props) {
    return (
      <>
        <div>
          {(isSupervisor() || isPlatformManager()) && (
            <Button
              style={{ color: "#0094A8" }}
              icon="pi pi-replay"
              tooltip="Replay Project"
              className="p-button-rounded p-button-text"
              onClick={() => {
                this.reloadProject(data);
                this.componentDidMount();
              }}
            />
          )}
        </div>
      </>
    );
  }

  actionTemplateName(data) {
    return (
      <div>
        <Link
          to={{ pathname: "/project", state: data }}
          style={{
            color: "black",
            textDecoration: "none",
            fontStyle: "Roboto",
            fontWeight: "bold",
          }}
        >
          {data.projectName}
        </Link>{" "}
      </div>
    );
  }

  projectStatusTemplate(data) {
    let status = "";

    if (data.projectStatus !== "9" || data.projectStatus !== "10") {
      if (data.projectStatus === "0" || data.projectStatus === "3") {
        status = <Trans i18nKey={"factfindingStage"} />;
        return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
      } else if (data.projectStatus === "2") {
        status = <Trans i18nKey={"waitingForApprovalToProgressToSurvey"} />;
        return <div className={"mr-2 mb-2 p-float-label-orange"}>{status}</div>;
      } else if (data.projectStatus === "1" || data.projectStatus === "6") {
        status = <Trans i18nKey={"validationStage"} />;
        return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
      } else if (data.projectStatus === "4") {
        status = <Trans i18nKey={"worksStage"} />;
        return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
      } else if (data.projectStatus === "5") {
        status = <Trans i18nKey={"waitingForApprovalToContractWorks"} />;
        return <div className={"mr-2 mb-2 p-float-label-orange"}>{status}</div>;
      } else if (data.projectStatus === "8") {
        status = <Trans i18nKey={"monitoring"} />;
        return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
      } else if (data.projectStatus === "9") {
        status = <Trans i18nKey={"completed"} />;
        return <div className={"mr-2 mb-2 p-float-label-green"}>{status}</div>;
      } else if (data.projectStatus === "10") {
        status = <Trans i18nKey={"paused"} />;
        return (
          <div className={"mr-2 mb-2 p-float-label-graybold"}>{status}</div>
        );
      } else if (data.projectStatus === "11") {
        status = <Trans i18nKey={"closed"} />;
        return <div className={"mr-2 mb-2 p-float-label-gray"}>{status}</div>;
      }
    }
  }
  projectStatusTemplateCSV(data) {
    let status = "";

    if (data.projectStatus !== "9" || data.projectStatus !== "10") {
      if (data.projectStatus === "0" || data.projectStatus === "3") {
        status = "Factfinding stage";
      } else if (data.projectStatus === "2") {
        status = "Waiting for approval to progress to survey";
      } else if (data.projectStatus === "1" || data.projectStatus === "6") {
        status = "Validation stage";
      } else if (data.projectStatus === "4") {
        status = "Works stage";
      } else if (data.projectStatus === "5") {
        status = "Waiting for approval to contract works";
      } else if (data.projectStatus === "8") {
        status = "Monitoring";
      } else if (data.projectStatus === "9") {
        status = "Completed";
      } else if (data.projectStatus === "10") {
        status = "Paused";
      } else if (data.projectStatus === "11") {
        status = "Closed";
      }
    }
    return status;
  }

  projectHomeownerTemplate(data) {
    return (
      <div>
        {data.lastName}
        {", "}
        {data.firstName}
      </div>
    );
  }

  homeownerAgeTemplate(data) {
    if (
      data.dateOfBirth !== null &&
      data.dateOfBirth !== undefined &&
      data.dateOfBirth !== ""
    ) {
      const dob = new Date(data.dateOfBirth);
      const now = new Date();

      let age = now.getFullYear() - dob.getFullYear();
      const monthDiff = now.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
        age--;
      }

      return age;
    } else {
      return "";
    }
  }

  projectIDTemplate(data) {
    let arr = [];
    arr = Array.from(String(data.id), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }

    let arrRes = arrX.concat(arr);

    return arrRes;
  }

  getUserInfo = () => {
    this.setState({ loading: true });

   
    SysService.getUserInfo().then(
      (response) => {
        
        let fullName = response.data.users.firstName +"" + response.data.users.lastName;
        let role ="";

         if(response.data.users.roles[0].name==="ROLE_PLATFORM_MANAGER"){
           role = " (Platform Manager)";
         }
         else if(response.data.users.roles[0].name==="ROLE_SUPERVISOR"){
          role = " (Supervisor)";
         }
         else if(response.data.users.roles[0].name==="ROLE_PROJECT_MANAGER"){
          role = " (Project Manager)";
         }
         else if(response.data.users.roles[0].name==="ROLE_PROJECT_EXPERT"){
          role = " (Project Expert)";
         }
         else if(response.data.users.roles[0].name==="ROLE_VIEWER_EXPERT"){
          role = " (Project Expert - view only)";
         }
        
        this.setState({
          pdfUserName:fullName,
          pdfUserRole:role
        })
      

      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  };

  trashProject = (selectedProject) => {
    this.setState({ loading: true });

    let project = { ...selectedProject };
    project.projectId = selectedProject.id;
    SysService.trashProject(project).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          blockedPanel: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        this.componentDidMount();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  };

  reloadProject = (selectedProject) => {
    this.setState({ loading: true });

    let project = { ...selectedProject };
    project.projectId = selectedProject.id;
    SysService.reLoadProject(project).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          blockedPanel: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        this.componentDidMount();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  };

  acceptReloadProject = () => {
    this.reloadProject(this.state.selectedReloadProject);
  };

  projectInfo = (selectedProject) => {
    SysService.getProject(selectedProject.id.toString()).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          blockedPanel: false,
        });
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );

    SysService.getProjectStatusLogs(selectedProject.id.toString()).then(
      (response) => {
        this.setState({
          projectStatusLogs: response.data,
          loading: false,
          blockedPanel: false,
          nonEditAccess: response.data.status === 2 ? false : true,
        });
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  };

  fetchLocation = () => {
    let newResults = { ...this.state.newProject };

    axios
      .get(
        `https://api.postcodes.io/postcodes/${this.state.newProject.postcode}`
      )
      .then((response) => {

        newResults.city = response.data.result.region;
        newResults.streetName = response.data.result.admin_ward;
        this.setState({
          newProject: newResults,
        });
        //setLocation(response.data.result); // Assuming response.data.result contains location data
      })
      .catch((error) => {
      });

  };

  render() {
    const { t } = this.props;

    this.state.t = t;
    this.state.projectLenght = this.state.projects.length;

    const headerTable = (param) => {
      return this.renderHeaderTableTop(t, param);
    };

    const projectDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text platformColor"
          onClick={this.hideDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.accept()}
        />
      </React.Fragment>
    );

    const footer = (
      <div
        className="flex align-items-center export-buttons justify-content-end"
        style={{ backgroundColor: "transparent" }}
      >
        <Button
          style={{ color: "GrayText" }}
          type="button"
          icon={
            <i
              className="pi pi-file-export"
              style={{
                fontSize: "1.3em",
                color: "GrayText",
                marginRight: "8px",
              }}
            />
          }
          label={t("btnExportProjectData")}
          onClick={(e) => {
            this.setState({
              exportFileVisible: true,
              exportFileType: "",
            });
          }}
          className="p-button-outlined"
          data-pr-tooltip="Export Project"
        />
      </div>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <TabView>
                {/*Projects */}
                <TabPanel header={<h6>{t("projectsTabHeader")}</h6>}>
                  {(isProjectManager() ||
                    isPlatformManager() ||
                    isSupervisor()) && (
                    <div className="field col-12 mb-0">
                      <Button
                        label={t("addNewProject")}
                        icon="pi pi-plus"
                        className="p-button-mb p-button-sm mr-2"
                        onClick={this.createNew}
                      />
                    </div>
                  )}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />
                      <DataTable
                        className="custom-footer"
                        name="dt"
                        size="small"
                        showGridlines
                        value={this.state.projects}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noProjectFound")}
                        header={headerTable("projects")}
                        footer={footer}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "id",
                          "projectName",
                          "firstName",
                          "lastName",
                          "city",
                          "postcode",
                          "streetName",
                          "houseNumber",
                          "projectStatus",
                          "registrationTime",
                          "dateOfBirth",
                        ]}
                        ref={(el) => {
                          this.dt = el;
                        }}
                      >
                        <Column
                          header={t("projectID")}
                          sortable
                          sortField="id"
                          body={this.projectIDTemplate}
                        ></Column>

                        <Column
                          header={t("status")}
                          sortable
                          sortField="projectStatus"
                          body={this.projectStatusTemplate}
                        ></Column>

                        <Column
                          header={t("projectName")}
                          sortable
                          sortField="projectName"
                          body={this.actionTemplateName}
                        ></Column>

                        <Column
                          body={this.projectHomeownerTemplate}
                          header={t("homeowner")}
                          sortable
                          sortField="lastName"
                        ></Column>

                        <Column
                          field="city"
                          header={t("city")}
                          sortable
                        ></Column>
                        <Column
                          field="postcode"
                          header={t("postcode")}
                        ></Column>

                      
                        <Column
                          header={t("createdDate")}
                          sortable
                          sortField="registrationTime"
                          body={this.dateTableTemplate}
                        ></Column>

                        {(isPlatformManager() || isSupervisor()) && (
                          <Column
                            header={t("action")}
                            style={{ textAlign: "center", width: "8em" }}
                            body={this.actionTemplate}
                          />
                        )}
                      </DataTable>

                      <div style={{ display: "none" }}>
                        <Link
                          id="hiddenLink"
                          to={{
                            pathname: "/project",
                            state: this.state.sortedList[0],
                          }}
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontStyle: "Roboto",
                            fontWeight: "bold",
                          }}
                        ></Link>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                {/*Archive Projects*/}
                {(isSupervisor() || isPlatformManager()) && (
                  <TabPanel header={<h6>{t("goToArchiveProjects")}</h6>}>
                    <div className="col-12">
                      <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        <DataTable
                          name="dt"
                          size="small"
                          showGridlines
                          value={this.state.archiveProjects}
                          dataKey="id"
                          stripedRows
                          responsiveLayout="scroll"
                          emptyMessage={t("noProjectFound")}
                          header={headerTable("archivedProjectsTitle")}
                          paginator
                          rows={10}
                          filters={this.state.filters1}
                          filterDisplay="menu"
                          loading={this.state.loading}
                          globalFilterFields={[
                            "id",
                            "projectName",
                            "firstName",
                            "lastName",
                            "city",
                            "postcode",
                            "streetName",
                            "houseNumber",
                            "registrationTime",
                          ]}
                        >
                          <Column
                            header={t("projectID")}
                            sortable
                            sortField="id"
                            body={this.projectIDTemplate}
                          ></Column>

                          <Column
                            header={t("status")}
                            body={this.projectStatusTemplate}
                            sortable
                            sortField="projectStatus"
                          ></Column>

                          <Column
                            header={t("projectName")}
                            sortable
                            sortField="projectName"
                            body={this.actionTemplateName}
                          ></Column>

                          <Column
                            body={this.projectHomeownerTemplate}
                            header={t("homeowner")}
                            sortable
                            sortField="lastName"
                          ></Column>

                          <Column
                            field="city"
                            header={t("city")}
                            sortable
                          ></Column>
                          <Column
                            field="postcode"
                            header={t("postcode")}
                          ></Column>

                          <Column
                            field="streetName"
                            header={t("streetName")}
                            sortable
                          ></Column>

                          <Column
                            field="houseNumber"
                            header={t("houseNumber")}
                          ></Column>

                          <Column
                            header={t("createdDate")}
                            sortable
                            sortField="registrationTime"
                            body={this.dateTableTemplate}
                          ></Column>

                          {(isPlatformManager() || isSupervisor()) && (
                            <Column
                              header={t("action")}
                              style={{ textAlign: "center", width: "11em" }}
                              body={this.actionTemplateArchive}
                            />
                          )}
                        </DataTable>
                      </div>
                    </div>
                    <hr />
                  </TabPanel>
                )}

                {/*Deleted Projects*/}
                {isPlatformManager() && (
                  <TabPanel header={<h6>{t("deletedProjects")}</h6>}>
                    <div className="col-12">
                      <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        <DataTable
                          name="dt"
                          size="small"
                          showGridlines
                          value={this.state.deleteProjects}
                          dataKey="id"
                          stripedRows
                          responsiveLayout="scroll"
                          emptyMessage={t("noProjectFound")}
                          header={headerTable("deletedProjectsTitle")}
                          paginator
                          rows={10}
                          filters={this.state.filters1}
                          filterDisplay="menu"
                          loading={this.state.loading}
                          globalFilterFields={[
                            "id",
                            "projectName",
                            "firstName",
                            "lastName",
                            "city",
                            "postcode",
                            "streetName",
                            "houseNumber",
                            "registrationTime",
                          ]}
                        >
                          <Column
                            header={t("projectID")}
                            sortable
                            sortField="id"
                            body={this.projectIDTemplate}
                          ></Column>

                          <Column
                            header={t("status")}
                            body={this.projectStatusTemplate}
                            sortable
                            sortField="projectStatus"
                          ></Column>

                          <Column
                            header={t("projectName")}
                            sortable
                            sortField="projectName"
                            body={this.actionTemplateName}
                          ></Column>

                          <Column
                            body={this.projectHomeownerTemplate}
                            header={t("homeowner")}
                            sortable
                            sortField="lastName"
                          ></Column>

                          <Column
                            field="city"
                            header={t("city")}
                            sortable
                          ></Column>
                          <Column
                            field="postcode"
                            header={t("postcode")}
                          ></Column>

                          <Column
                            field="streetName"
                            header={t("streetName")}
                            sortable
                          ></Column>

                          <Column
                            field="houseNumber"
                            header={t("houseNumber")}
                          ></Column>

                          <Column
                            header={t("createdDate")}
                            sortable
                            sortField="registrationTime"
                            body={this.dateTableTemplate}
                          ></Column>

                          {isPlatformManager() && (
                            <Column
                              header={t("action")}
                              style={{ textAlign: "center", width: "8em" }}
                              body={this.actionTemplateDelete}
                            />
                          )}
                        </DataTable>
                      </div>
                    </div>
                  </TabPanel>
                )}
              </TabView>

              {/*Project Details Dialog */}
              <Dialog
                visible={this.state.projectDialog}
                style={{ width: "450px" }}
                header={t("projectDetails")}
                modal
                className="p-fluid"
                footer={projectDialogFooter}
                onHide={this.hideDialog}
              >
                <div className="p-float-label">
                  <InputText
                    id="projectName"
                    value={this.state.newProject.projectName}
                    onChange={(e) => this.onInputChange(e, "projectName")}
                    autoFocus
                    maxLength={100}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="projectName" className="platformColor">
                    {t("projectName")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="lastName"
                    value={this.state.newProject.lastName}
                    onChange={(e) => this.onInputChange(e, "lastName")}
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="lastName" className="platformColor">
                    {t("lastName")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="middleName"
                    value={this.state.newProject.middleName}
                    onChange={(e) => this.onInputChange(e, "middleName")}
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="middleName" className="platformColor">
                    {t("middleName")}
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="firstName"
                    value={this.state.newProject.firstName}
                    onChange={(e) => this.onInputChange(e, "firstName")}
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="firstName" className="platformColor">
                    {t("firstName")} (*)
                  </label>
                </div>

                <div className="mb-1"></div>
                <div className="p-inputgroup">
                  <div className="p-float-label">
                    <InputText
                      id="postcode"
                      fontSize="14px"
                      readOnly={this.state.nonEditAccess}
                      disabled={this.state.nonEditAccess}
                      value={this.state.newProject.postcode}
                      placeholder={t("pleaseEnter")}
                      onChange={(e) => this.onInputChange(e, "postcode")}
                      maxLength={50}
                      className="w-full"
                    />

                    <i
                      className="pi pi-search p-button p-button-secondary"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={this.fetchLocation}
                    />
                    <label
                      htmlFor="postcode"
                      className="platformColor"
                      style={{ fontSize: "14px" }}
                    >
                      {t("Fill your address information with postcode")} (!)
                    </label>
                  </div>
                </div>

                <div className="p-float-label">
                  <Dropdown
                    id="city"
                    value={this.state.newProject.city}
                    options={cities}
                    onChange={(e) => this.onInputChange(e, "city")}
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="city" className="platformColor">
                    {t("city")} (*)
                  </label>
                </div>

                {this.state.newProject.city === "Other" && (
                  <div className="p-float-label">
                    <InputText
                      id="town"
                      value={this.state.newProject.town}
                      onChange={(e) => this.onInputChange(e, "town")}
                      maxLength={50}
                      className="p-inputtext block mb-2"
                    />
                    <label htmlFor="streetName" className="platformColor">
                      {t("town")} (*)
                    </label>
                  </div>
                )}

                <div className="p-float-label">
                  <InputText
                    id="address"
                    value={this.state.newProject.streetName}
                    onChange={(e) => this.onInputChange(e, "streetName")}
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="streetName" className="platformColor">
                    {t("streetName")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="address"
                    value={this.state.newProject.houseNumber}
                    onChange={(e) => this.onInputChange(e, "houseNumber")}
                    maxLength={20}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="houseNumber" className="platformColor">
                    {t("houseNumber")} (*)
                  </label>
                </div>
              </Dialog>

              {/*archive project dialog */}
              <Dialog
                visible={this.state.archiveProjectDialogVisible}
                style={{ width: "700px" }}
                modal
                header={t("archiveDialogHeader")}
                className="p-fluid"
                onHide={() => {
                  this.setState({ archiveProjectDialogVisible: false });
                }}
              >
                {/*archiveProjectReason*/}
                <div className="p-float w-full mb-1">
                  <div className="p-float-label">
                    <InputTextarea
                      name="archiveProjectReason"
                      id="archiveProjectReason"
                      onChange={(e) => {
                        this.onInputChange(e, "archiveProjectReason");
                        this.onInputChangeArchiveReason(e);
                      }}
                      rows={5}
                      cols={30}
                      className="w-full p-inputtext-sm block mb-3"
                      maxLength={1000}
                      value={this.state.archiveProjectReason}
                    />

                    <label
                      className="platformColor"
                      htmlFor="archiveProjectReason"
                    >
                      {t("provideReasonHeader")} (*)
                    </label>
                  </div>
                </div>

                {/*Buttons*/}
                <div
                  className="flex justify-content-end px-2"
                  id="deleteButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "25%" }}
                    label={t("cancel")}
                    onClick={() => {
                      this.setState({ archiveProjectDialogVisible: false });
                    }}
                  />
                  &nbsp;
                  <Button
                    style={{ width: "25%" }}
                    label={t("archive")}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.archiveProject();
                    }}
                  />
                </div>
              </Dialog>

              {/*delete project dialog */}
              <Dialog
                visible={this.state.deleteProjectDialogVisible}
                style={{ width: "700px" }}
                modal
                header={t("deleteProjectHeader")}
                className="p-fluid"
                onHide={() => {
                  this.setState({ deleteProjectDialogVisible: false });
                }}
              >
                {/*Delete Project Reason Reason*/}
                <div className="mb-1">
                  <div className="p-float-label">
                    <Dropdown
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                      id="provideReason"
                      options={DeleteProjectReason}
                      value={this.state.deleteProjectReason}
                      onChange={(e) => {
                        this.onInputChange(e, "deleteProjectReason");
                        this.onInputChangeDeleteReason(e);
                      }}
                      optionLabel="name"
                      optionValue="code"
                      className="w-full"
                    />
                    <label
                      htmlFor="deleteProjectReason"
                      className="platformColor"
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                    >
                      {t("provideReason")} (*)
                    </label>
                  </div>
                </div>

                {/*Delete Project Notes*/}
                <div className="p-float w-full mb-1">
                  <div className="p-float-label">
                    <InputTextarea
                      name="archiveProjectNotes"
                      id="archiveProjectNotes"
                      onChange={(e) => {
                        this.onInputChange(e, "deleteProjectNotes");
                        this.onInputChangeDeleteNote(e);
                      }}
                      rows={5}
                      cols={30}
                      className="w-full p-inputtext-sm block mb-3"
                      maxLength={1000}
                      value={this.state.deleteProjectNotes}
                    />

                    <label
                      className="platformColor"
                      htmlFor="archiveProjectNotes"
                    >
                      {t("notes")}
                    </label>
                  </div>
                </div>

                {/*Buttons */}
                <div
                  className="flex justify-content-end px-2"
                  id="cancelButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ width: "25%" }}
                    label={t("cancel")}
                    severity="secondary"
                    onClick={() => {
                      this.setState({ deleteProjectDialogVisible: false });
                    }}
                  />
                  &nbsp;
                  <Button
                    style={{ width: "25%" }}
                    label={t("deleteProjectBtn")}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.handleDeleteProject();
                    }}
                  />
                </div>
              </Dialog>

              {/*projectInfoDialog */}
              <Dialog
                visible={this.state.projectInfoDialogVisible}
                style={{ width: "700px" }}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ projectInfoDialogVisible: false });
                }}
                header={t("projectTimeline")}
              >
                <DataTable
                  name="dt"
                  size="small"
                  showGridlines
                  selectionMode="single"
                  value={this.state.projectStatusLogs}
                  dataKey="id"
                  stripedRows
                  responsiveLayout="scroll"
                  emptyMessage={t("noLogFound")}
                  paginator
                  rows={10}
                  filters={this.state.filters1}
                  filterDisplay="menu"
                  loading={this.state.loading}
                  globalFilterFields={[
                    "status",
                    "dataUpdated",
                    "user",
                    "notes",
                  ]}
                >
                  <Column
                    body={this.timelineStatusTemplate}
                    header={t("status")}
                    sortable
                  ></Column>

                  <Column
                    body={this.viewStatusLogTimeTemplate}
                    header={t("dataUpdated")}
                    sortable
                  ></Column>

                  <Column field="userName" header={t("user")} sortable>
                    {" "}
                  </Column>

                  <Column
                    body={this.statusLogNotesTemplate}
                    header={t("notes")}
                    sortable
                  ></Column>
                </DataTable>
              </Dialog>

              {/*export Project Dialog */}
              <Dialog
                visible={this.state.exportFileVisible}
                style={{ width: "500px" }}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({
                    exportFileVisible: false,
                    exportOptionValue:null,
                    exportFileType: "",
                    includedProjectValue:null,

                  });
                }}
                header={t("exportProjectData")}
              >
                {/*export option */}
                <div className="p-float-label">
                  <Dropdown
                    id="exportOptionValue"
                    value={this.state.exportOptionValue}
                    options={exportOptions}
                    onChange={(e) => this.onExportOptionChange(e)}
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="exportOptionValue" className="platformColor">
                    {t("exportOptionValue")} (*)
                  </label>
                </div>

                {/*report format */}
                {this.state.exportOptionValue === "01" ? (
                  <div className="p-float-label">
                    <Dropdown
                      id="reportFormat"
                      value={this.state.exportFileType}
                      options={this.fileTypeOptions}
                      onChange={(e) => this.onExportFileChange(e)}
                      optionLabel="name"
                      optionValue="code"
                    />
                    <label htmlFor="reportFormat" className="platformColor">
                      {t("reportFormat")} (*)
                    </label>
                  </div>
                ) : (
                  <div className="p-float-label">
                    <Dropdown
                      id="reportFormat"
                      value={this.state.exportFileType}
                      options={this.fileTypeOptions2}
                      onChange={(e) => this.onExportFileChange(e)}
                      optionLabel="name"
                      optionValue="code"
                    />
                    <label htmlFor="reportFormat" className="platformColor">
                      {t("reportFormat")} (*)
                    </label>
                  </div>
                )}


                {/*included projects*/}
                <div className="p-float-label">
                  <Dropdown
                    id="includedProjectValue"
                    value={this.state.includedProjectValue}
                    options={projectStatusOptions}
                    onChange={(e) => this.onIncludedProjectChange(e)}
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="includedProjects" className="platformColor">
                    {t("includedProjects")} (*)
                  </label>
                </div>

                <br />

                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        exportFileVisible: false,
                        exportFileType: "",
                        exportOptionValue:null,
                         includedProjectValue:null,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("btnExport")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    onClick={this.exportProjectData}
                  />
                </div>

              </Dialog>

              <ConfirmDialog
                visible={this.state.rejectConfirmVisible}
                onHide={() => {
                  this.setState({ rejectConfirmVisible: false });
                }}
                message={t("confirmDeleteProjectMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.updateProject}
                reject={this.reject}
              />

              <ConfirmDialog
                visible={this.state.reloadConfirmDialogVisible}
                onHide={() => {
                  this.setState({ reloadConfirmDialogVisible: false });
                }}
                message={t("confirmReloadProjectMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptReloadProject}
                reject={this.reject}
              />

              <WaitDialog loading={this.state.loading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(Projects));
