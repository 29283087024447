import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { Galleria } from "primereact/galleria";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";

import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import DocumentUpload from "../common/documentUpload.jsx";

import { isUser, isViewerExpert } from "../common/token-check";
import { handleDownloadDocument } from "../common/functions";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";

import lendersValuation from "../common/ccs_lendersValuation";
import comparablesAccepted from "../common/ccs_comparablesAccepted";
import kfiForMortgage from "../common/ccs_KFIforMortgage";
import mortgageSubmitted from "../common/ccs_mortgageSubmitted";
import YesNoPending from "../common/ccs_yesNoPending";
import PdfViewer from "../common/pdfViewer.jsx";

import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

class mortgageProcess extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      nonEditAccess: true,
      activeIndex: 0,

      p30Documents: [],
      p30DocumentIndex: -1,
      p30SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      saveButtonStatus: false,

      documents: [],
      selectedDocument: null,

      galleria1: React.createRef(null),
      imageList1: [],
      galleriaIndex: 0,

      pdfVisible: false,
      documentUrl: "",

      saveButtonConfirmDialog:false,
      saveButtonPrevConfirmDialog:false,

      prevPhaseButtonConfirmDialog:false,
      nextPhaseButtonConfirmDialog:false,

    };
  }

  /*JS_methods*/

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img
          src={this.state.documentUrl}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    );
  };

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };

  actionTemplate = (data, props) => {
    const { t } = this.props;

    return (
      <div>
        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        />
        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.handleDeleteDocument(data, props);
            }}
          />
        )}
      </div>
    );
  };

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 0) {
      const index = this.state.p30Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p30Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];

    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P1" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 0) {
        let localP30Documents = [...this.state.p30Documents];
        localP30Documents = localP30Documents.concat(localArray);
        this.setState({
          loading: false,
          p30Documents: localP30Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
      documents: [],
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P3" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState(
          { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
          () => {
            this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
            this.state.newDocument.fileName = this.state.s3Response.fileName;
            this.state.newDocument.documentName =
              this.state.s3Response.documentName;
            this.state.newDocument.documentDate =
              this.state.s3Response.documentDate;
            this.state.newDocument.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.put2S3();

        let p30doc = [...this.state.p30Documents];

        if (this.state.activeIndex === 0) {
          p30doc.push(this.state.newDocument);
          this.setState({ p30Documents: p30doc, saveButtonStatus: true });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectAddDocument = () => {};

  //////////////////////////////////////////////

  acceptForPrevPhaseButton = () => {
    this.setState({ prevPhaseButtonConfirmDialog: false });
    this.stepSaveAndPrevPhase();
  };

  acceptForNextPhaseButton = () => {
    this.setState({ nextPhaseButtonConfirmDialog: false });
    this.stepSaveAndNextPhase();
  };

  rejectForNextPhaseButton = () => {
    this.props.history.push({
      pathname: "/contractManagement",
      state: this.state.project,
    });
  };

  rejectForPrevPhaseButton = () => {
    this.props.history.push({
      pathname: "/fs",
      state: this.state.project,
    });
  };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp30Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P30") {
              temp30Documents.push(response.data.documentList[i]);
            }

            if (
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpg") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".png") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpeg")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc =
                response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;
              if (response.data.documentList[i].phase === "P30") {
                this.state.imageList1.push(newObj);
              }
            }
          }
        }

        this.setState({
          project: response.data,
          p30Documents: temp30Documents,
          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }


  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p30Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P3" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageList1: [],
            });
            this.componentDidMount();

            window.scrollTo(0, 0);

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            window.scrollTo(0, 0);

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }
  }

  stepSaveAndNextPhase() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p30Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P3" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageList1: [],
            });
            this.componentDidMount();

            window.scrollTo(0, 0);

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            window.scrollTo(0, 0);

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      this.props.history.push({
        pathname: "/contractManagement",
        state: this.state.project,
      });
    }
  }

  stepSaveAndPrevPhase() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p30Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P3" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageList1: [],
            });
            this.componentDidMount();

            window.scrollTo(0, 0);

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            window.scrollTo(0, 0);

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      this.props.history.push({
        pathname: "/fs",
        state: this.state.project,
      });
    }
  }



  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  projectIDTemplate(data) {
    let arr = [];

    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");
    return str;
  }

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/contractManagement",
        state: this.state.project,
      });
    } else {
      this.setState({
        nextPhaseButtonConfirmDialog: true,
      });
      //toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  prevPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/fs",
        state: this.state.project,
      });
    } else {
      this.setState({
        prevPhaseButtonConfirmDialog: true,
      });
      //toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentDialogVisible: false,
      documents: [],
    });
  };

  handleKeyDown = (event, name) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      let project = { ...this.state.project };
      project[`${name}`] = null;
      this.setState({
        project,
        saveButtonStatus: true,
      });
    }
  };

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);

    this.stepItems = [
      {
        label: t("Mortgage Application"),
        command: (event) => {},
      },
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() =>
            this.setState({ addDocumentConfirmDialogVisible: true })
          }
        />

        <ConfirmDialog
          visible={this.state.addDocumentConfirmDialogVisible}
          onHide={() => {
            this.setState({ addDocumentConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddDocument}
          reject={this.rejectAddDocument}
        />
      </React.Fragment>
    );

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>

              <br />

              <br />

              <div className={"col-12 "}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />

                    <div>
                      <Chip
                        style={{ fontStyle: "Manrope", fontSize: "14px" }}
                        label={<b>{t("phase") + " 3: " + t("phase3Name")}</b>}
                        icon="pi pi-tag"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Mortgage Application*/}
                    <TabPanel header={t("Mortgage Application")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p31Text")}
                      </div>

                      <div className="grid">
                        {/*lendersValuationHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {" "}
                            {t("lendersValuation")}
                          </label>
                        </div>

                        {/*lendersValuation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="lendersValuation"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.lendersValuation}
                              options={lendersValuation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "lendersValuation")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "lendersValuation")
                              }
                            />
                          </div>

                          <div
                            style={{
                              display:
                                this.state.project.lendersValuation === "2"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {/*comparablesAccepted*/}
                            <div>
                              <div className="mb-1">
                                <br />
                                <label
                                  htmlFor="comparablesAccepted"
                                  className="platformColor"
                                >
                                  {t("comparablesAccepted")}{" "}
                                </label>
                              </div>
                              <div>
                                <Dropdown
                                  id="comparablesAccepted"
                                  style={{ fontSize: "14px" }}
                                  disabled={this.state.nonEditAccess}
                                  value={this.state.project.comparablesAccepted}
                                  options={comparablesAccepted}
                                  placeholder={t("pleaseSelect")}
                                  onChange={(e) =>
                                    this.onInputChange(e, "comparablesAccepted")
                                  }
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(e, "comparablesAccepted")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*kfiForMortgageHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("kfiForMortgage")}
                          </label>
                        </div>

                        {/*kfiForMortgage*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="kfiForMortgage"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.kfiForMortgage}
                              options={kfiForMortgage}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "kfiForMortgage")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "kfiForMortgage")
                              }
                            />
                          </div>
                        </div>

                        {/*mortgageSubmittedHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("mortgageSubmitted")}
                          </label>
                        </div>

                        {/*mortgageSubmitted*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="mortgageSubmitted"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.mortgageSubmitted}
                              options={mortgageSubmitted}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "mortgageSubmitted")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "mortgageSubmitted")
                              }
                            />
                          </div>
                        </div>

                        {/*funderNameHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("funderName")}
                          </label>
                        </div>

                        {/*funderName*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="funderName"
                              value={this.state.project.funderName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "funderName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*funderCustomerIdHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("funderCustomerId")}
                          </label>
                        </div>

                        {/*funderCustomerId*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="funderCustomerId"
                              value={this.state.project.funderCustomerId}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "funderCustomerId")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*mortgageOfferHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("mortgageOffer")}
                          </label>
                        </div>

                        {/*mortgageOffer*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="mortgageOffer"
                              value={this.state.project.mortgageOffer}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "mortgageOffer")
                              }
                              maxLength={20}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*clientAcceptanceHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("clientAcceptance")}
                          </label>
                        </div>

                        {/*clientAcceptance*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="kfiForMortgage"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.clientAcceptance}
                              options={YesNoPending}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "clientAcceptance")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "clientAcceptance")
                              }
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p30Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageList1.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleria1}
                                  value={this.state.imageList1}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageList1 &&
                                    this.state.imageList1.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleria1.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                    class="btn-toolbar justify-content-center mb-3"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div class="btn-group" role="group"  aria-label="First group">
                      <div class="input-group-prepend">
                        {this.state.activeIndex === 0 && (
                          
                          <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                            marginRight :"10px",
                          }}
                        
                          label={t("prevPhase")}
                          className="p-button-sm p-button-mb-prev"
                          onClick={() => this.prevPhase()}
                        ></Button>
                        )} 

                        {/* {this.state.activeIndex > 0 && ( 
                          <Button
                          style={{ fontSize: "14px", 
                            fontStyle: "Manrope" , 
                            marginRight :"10px",
                            width:"92px"
                          }}                        
                          label={t("previous")}
                          className="p-button-sm p-button-mb-prev"
                          onClick={() => this.stepPrev()}
                        />
                        )}                        */}
                        
                        {!isViewerExpert() && (
                          <Button
                            disabled={!this.state.saveButtonStatus}
                            style={{ fontSize: "14px", fontStyle: "Manrope", width:"92px" }}
                            label={
                              this.state.activeIndex < this.stepItems.length - 1
                                ? t("save")
                                : t("save")
                            }
                            className="p-button-sm p-button-mb-saveNext"
                            onClick={() => this.stepSaveAndNext()}
                          />
                        )}

                        {/* {this.state.activeIndex < this.stepItems.length - 1  && (
                           <Button                         
                           style={{ fontSize: "14px", fontStyle: "Manrope", marginLeft :"10px", width:"92px" }}
                           label={t("next")}
                           className="p-button-sm p-button-mb-next"
                           onClick={() => {
                             this.stepNext();
                           }}
                         />
                        )} */}

                        {this.state.activeIndex === this.stepItems.length - 1  && (
                           <Button
                           style={{
                             fontSize: "14px",
                             fontStyle: "Manrope",
                              marginLeft :"10px",
                           }}
                           iconPos="right"
                           label={t("nextPhase")}
                           className="p-button-sm p-button-mb-next"
                           onClick={() => this.nextPhase()}
                         ></Button>
                        )}
                       
                      </div>
                    </div>

               </div>
              </div>
              <br />

              {/* addDocument dialogu */}
              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.documents}
                  setDocuments={this.setDocuments}
                  selectedDocument={this.state.selectedDocument}
                  setSelectedDocument={this.setSelectedDocument}
                  setSaveButtonVisible={this.setSaveButtonVisible}
                  uploadType={"D"}
                  isMulti={true}
                  isRequired={this.state.isRequiredDocumentCompliance}
                  phase={"P3" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>

              <Dialog
                visible={this.state.pdfVisible}
                style={{ width: "%100", height: "%100" }}
                modal
                className="p-fluid"
                onHide={() => this.setState({ pdfVisible: false })}
              >
                {this.state.documentUrl.endsWith(".png") ||
                this.state.documentUrl.endsWith(".jpeg") ||
                this.state.documentUrl.endsWith(".jpg") ? (
                  <Galleria
                    value={this.state.documentUrl}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    item={this.itemTemplatePdf}
                  />
                ) : this.state.documentUrl.endsWith(".pdf") ? (
                  <PdfViewer pdfUrl={this.state.documentUrl} />
                ) : (
                  <div>No valid document format found</div>
                )}
              </Dialog>

              <ConfirmDialog
                visible={this.state.nextPhaseButtonConfirmDialog}
                onHide={() => {
                  this.setState({ nextPhaseButtonConfirmDialog: false });
                }}
                message={t("saveButtonConfirmText")}
                header={t("confirm")}
                accept={this.acceptForNextPhaseButton}
                reject={this.rejectForNextPhaseButton}
                acceptLabel={t("btnSaveAndCon")}
                rejectLabel={t("btnDiscard")}
                acceptClassName="p-button-mb-saveNext"
                rejectClassName="p-button-mb-gray"
              />

              <ConfirmDialog
                visible={this.state.prevPhaseButtonConfirmDialog}
                onHide={() => {
                  this.setState({ prevPhaseButtonConfirmDialog: false });
                }}
                message={t("saveButtonConfirmText")}
                header={t("confirm")}
                accept={this.acceptForPrevPhaseButton}
                reject={this.rejectForPrevPhaseButton}
                acceptLabel={t("btnSaveAndCon")}
                rejectLabel={t("btnDiscard")}
                acceptClassName="p-button-mb-saveNext"
                rejectClassName="p-button-mb-gray"
              />

              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(mortgageProcess));
