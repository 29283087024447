const hearAboutUs = [
    { name: "Another Client", code: "1" },
    { name: "Council", code: "2" },
    { name: "Social Worker", code: "3" },
    { name: "Flyer", code: "4" },
    { name: "Internet", code: "5" },
    { name: "Local Ad", code: "6" },
    { name: "Word Of Mouth", code: "7" },
    { name: "Referral agency", code: "8" },
    { name: "Event", code: "9" },

  ];
  
  export default hearAboutUs;