const sections = [
    { name: "Electrical", code: "1" },
    { name: "Plumbing", code: "2" },
    { name: "HVAC (Heating, Ventilation, and Air Conditioning)", code: "3" },
    { name: "Roofing", code: "4" },
    { name: "Carpentry and Woodworking", code: "5" },
    { name: "Painting and finishing", code: "6" },
    { name: "Flooring", code: "7" },
    { name: "Masonry and Concrete", code: "8" },
    { name: "Structural", code: "9" },
    { name: "Appliance", code: "10" },
    { name: "Insulation and Weatherproofing", code: "11" },
    { name: "General Maintenance and Handyman Services", code: "12" },


  ];
  
  export default sections;