import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import AppPassport from "./AppPassport";
import "./i18n";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      {window.location.pathname.includes("/passport/") ? (
        <AppPassport />
      ) : (
        <App />
      )}{" "}
    </Suspense>
  </Provider>,

  document.getElementById("root")
);
