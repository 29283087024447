import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { logout } from "../actions/auth";
import SysService from "../services/sys.service";
import authService from "../services/auth.service";

const LogoutTimer = ({ t, props }) => {
  //const timeout = 3000; //timeout süresi
  const [timeout, setTimeOut] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [remaining, setRemaining] = useState(timeout);
  const [dialogVisible, setDialogVisible] = useState(false);
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    //toast.info("You have been logged out.", { autoClose: 2000 });
    authService.logout();

    setDialogVisible(true);
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const logOut = () => {
    dispatch(logout());
    setCurrentUser(undefined);
  };

  const getUser = () => {
    SysService.getUserInfo().then(
      (response) => {
        setTimeOut(response.data.users.timeLogout * 60000);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
          loading: false,
        });
        //toast.error(<Trans i18nKey={this.state.content} />);
        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  useEffect(() => {
    getUser();

    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, []);

  return (
    <div>
      <Dialog
        visible={dialogVisible}
        style={{ width: "350px" }}
        modal
        closable={false}
        //header={t("Warning!")}
        className="p-fluid"
      >
        <div
          className="mb-1 "
          style={{
            fontWeight: "bolder",
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          <div
            style={{ fontSize: "18px" }}
            className="pi pi-exclamation-triangle icon-orange"
          ></div>{" "}
          {t("logoutTimerTxt")}
        </div>
        <br />

        <div className="flex justify-content-end px-2" id="dialogButton">
          <Button
            label={t("backToLogin")}
            //style={{ width: "20%" }}
            className="p-button-mb"
            onClick={(e) => {
              logOut();
              setDialogVisible(false);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default withTranslation()(LogoutTimer);
