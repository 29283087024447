import { BlockUI } from "primereact/blockui";
import React, { useState, useEffect } from "react";
import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import TaskInfo from "../common/taskInfo";
import MessageBoard from "../common/messageBoard";
import SendEmail from "../common/sendEmail";
import { Dialog } from "primereact/dialog";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

import i18n from "i18next";

import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import {
  isPlatformManager,
  isProjectManager,
  isSupervisor,
  isProjectExpert,
} from "../common/token-check";

const Tasks = ({ t, pid, projectName }) => {
  const user = useSelector((state) => state.auth.user);
  const emptyTaskContent = {
    taskName: "",
    notes: "",
  };

  const emptyUpdateContent = {
    content: "",
    emailStatus: "n",
  };

  const emptyCalendarEvent = {
    title: "",
    start: "",
    end: "",
    description: "",
    color: "",
    backgroundColor: "",
  };

  const [blockedPanel, setBlockedPanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [members, setMembers] = useState([]);
  const [allTasks, setAllTasks] = useState([]);

  const [newTaskContent, setNewTaskContent] = useState(emptyTaskContent);
  const [startDate, setStartDate] = useState([]);
  const [dueDate, setDueDate] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(2);
  const [selectedProgress, setSelectedProgress] = useState(1);

  const [isTaskInfoVisible, setIsTaskInfoVisible] = useState(false);
  const [isMessageBoardVisible, setIsMessageBoardVisible] = useState(false);
  const [isSendEmailVisible, setIsSendEmailVisible] = useState(false);

  const [addTaskDialogVisible, setAddTaskDialogVisible] = useState(false);

  const [updateTaskDialogVisible, setUpdateTaskDialogVisible] = useState(false);
  const [updateTaskDialogConfirmVisible, setUpdateTaskDialogConfirmVisible] =
    useState(false);
  const [deleteTaskDialogVisible, setDeleteTaskDialogVisible] = useState(false);

  const [addNewUpdatesConfirmVisible, setAddNewUpdatesConfirmVisible] =
    useState(false);
  const [workingGroupUpdatesContent, setWorkingGroupUpdatesContent] =
    useState(emptyUpdateContent);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);

  const [selectedTask, setSelectedTask] = useState([]);

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [eventDialog, setEventDialog] = useState(false);
  const [eventConfirmDialog, setEventConfirmDialog] = useState(false);
  const [calendarEvent, setCalendarEvent] = useState(emptyCalendarEvent);
  const [selectedDate, setSelectedDate] = useState("");
  const [isUpdateEvent, setIsUpdateEvent] = useState(false);
  const [eventDetailDialog, setEventDetailDialog] = useState(false);
  const [deleteEventConfirmDialog, setDeleteEventConfirmDialog] =
    useState(false);

  const [deleteUpdateConfirmDialog, setDeleteUpdateConfirmDialog] =
    useState(false);
  const [addUpdateDialogVisible, setAddUpdateDialogVisible] = useState(false);

  const [completedTasks, setCompletedTasks] = useState([]);
  const [completedTasksVisible, setCompletedTasksVisible] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [filterAssignedMember, setFilterAssignedMember] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [activeIndex, setActiveIndex] = useState(0);

  const [notes, setNotes] = useState("");
  const [updateCount, setUpdateCount] = useState(0);

  const colorOptions = [
    { text: t("yellow"), value: "#F09300" },
    { text: t("orange"), value: "#F4511E" },
    { text: t("red"), value: "#D50000" },
    { text: t("lightGreen"), value: "#33B679" },
    { text: t("darkGreen"), value: "#009688" },
    { text: t("blue"), value: "#3F51B5" },
    { text: t("plato"), value: "#CC87D8" },
    { text: t("pink"), value: "#CF5396" },
    { text: t("purple"), value: "#6B2F7B" },
  ];

  const selectedColorTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center gap-2">
          <span
            style={{
              width: "17px",
              height: "17px",
              backgroundColor: `${option.value}`,
              borderRadius: "50%",
            }}
          />
          <div>{option.text}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const colorOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span
          style={{
            width: "17px",
            height: "17px",
            backgroundColor: `${option.value}`,
            borderRadius: "50%",
          }}
        />
        <div>{option?.text}</div>
      </div>
    );
  };

  const getAllTasks = () => {
    setLoading(true);
    SysService.getAllTasks(pid).then(
      (response) => {
        const completedTasks = response.data.filter(
          (task) => task.progress === 3
        );

        const tasks = response.data.filter(
          (task) => task.progress === 1 || task.progress === 2
        );
        setTasks(tasks);
        setNotes(tasks[0].notes)
        setCompletedTasks(completedTasks);
        setLoading(false);
        setAllTasks(response?.data);

        if (response?.data?.length > 0) {
          fetchRemote();
          getCalendarEvents();
        }

        if (tasks?.length > 0) {
          handleShowTaskInfo(tasks[0]);
          setIsTaskInfoVisible(true);
        }
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
  };

  const filterTasksByMember = (id) => {
    setIsSendEmailVisible(false);
    setIsMessageBoardVisible(false);
    setIsTaskInfoVisible(false);
    setSelectedItemIndex(null);
    setLoading(true);

    SysService.getAllTasks(pid).then(
      (response) => {
        let filteredTasks = [];
        for (let i = 0; i < response.data.length; i++) {
          for (let j = 0; j < response.data[i]?.memberList?.length; j++) {
            if (response.data[i].memberList[j].id === id) {
              filteredTasks.push(response.data[i]);
            }
          }
        }

        setTasks(filteredTasks);
        setLoading(false);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
  };

  const filterTasksByProgress = (filt) => {
    setIsSendEmailVisible(false);
    setIsMessageBoardVisible(false);
    setIsTaskInfoVisible(false);
    setSelectedItemIndex(null);

    SysService.getAllTasks(pid).then(
      (response) => {
        const filteredTasks = response.data.filter(
          (task) => task.progress === filt
        );
        setTasks(filteredTasks);
        setLoading(false);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
  };

  const getAccessUserList = () => {
    setLoading(true);

    SysService.getAccessUserList(pid).then(
      (response) => {
        setMembers(response.data);
        setLoading(false);

        const items = response.data.map((item) => ({
          label: item.firstName + " " + item.lastName,
          icon: "pi pi-user",
          command: () => {
            filterTasksByMember(item.id);
          },
        }));

        setFilterAssignedMember(items);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
  };

  const getUpdates = () => {
    setLoading(true);
  };

  const dateTemplate = (data) => {
    return format(new Date(data), "dd/MM/yyyy HH:mm");
  };

  const dateTemplateEvent = (data) => {
    if (data !== undefined && data !== null) {
      const date = new Date(data);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Paris",
      };
      return date.toLocaleDateString(undefined, options);
    } else {
      return "";
    }
  };

  const getCalendarEvents = () => {
    let events = [];
    SysService.getAllCalendarEvents(pid).then(
      (response) => {
        setLoading(false);

        for (let i = 0; i < response.data.length; i++) {
          const obj = {
            event_id: response.data[i].id,
            start: new Date(response.data[i]?.startDate),
            end: new Date(response.data[i]?.dueDate),
            title: response.data[i].subject,
            description: response.data[i].content,
            taskId: response.data[i].taskId,

            color: response.data[i].color,
            backgroundColor: response.data[i].color,
          };
          events.push(obj);
        }
        setCalendarEvents(events);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
  };

  const saveCalendarEvent = () => {
    if (!isUpdateEvent) {
      const calendarApi = selectedDate.view.calendar;
      SysService.addCalendarEvent(
        calendarEvent.title,
        calendarEvent.description,
        calendarEvent.color,
        calendarEvent.start,
        calendarEvent.end,
        pid
      ).then(
        (response) => {
          calendarApi.addEvent({
            ...calendarEvent,
            event_id: response.id,
          });
          calendarApi.unselect();
          setSelectedDate("");
          setEventDialog(false);
          toast.success(i18n.t(response.data.message));
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data.cluster &&
              error.response.data.cluster.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString()
          );
        }
      );
    } else {
      const calendarApi = selectedDate.event;

      SysService.updateCalendarEvent(
        calendarEvent.event_id,
        calendarEvent.title,
        calendarEvent.description,
        calendarEvent.color,
        calendarEvent.start,
        calendarEvent.end,
        pid
      ).then(
        (response) => {
          calendarApi.setExtendedProp("event_id", calendarEvent.event_id);
          calendarApi.setExtendedProp("description", calendarEvent.description);
          calendarApi.setExtendedProp("creator", calendarEvent.creator);
          calendarApi.setProp("title", calendarEvent.title);
          calendarApi.setProp("backgroundColor", calendarEvent.backgroundColor);
          calendarApi.setStart(calendarEvent.start);
          calendarApi.setEnd(calendarEvent.end);
          setSelectedDate("");
          setEventDialog(false);
          setEventDetailDialog(false);
          setIsUpdateEvent(false);
          toast.success(i18n.t(response.data.message));
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data.cluster &&
              error.response.data.cluster.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString()
          );
        }
      );
    }
  };

  const handleDeleteEvent = () => {
    SysService.deleteCalendarEvent(calendarEvent.event_id).then(
      (response) => {
        selectedDate.event.remove();
        setEventDetailDialog(false);
        setCalendarEvent(emptyCalendarEvent);
        handleDeleteTask2();
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.cluster &&
            error.response.data.cluster.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );

    SysService.deleteTask(selectedDate.event._def.extendedProps.taskId).then(
      (response) => {
        setLoading(false);
        setDeleteTaskDialogVisible(false);
        setDeleteEventConfirmDialog(false);
      },

      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    getAllTasks();
  };

  const handleDeleteTask2 = () => {
    SysService.deleteTask(selectedDate.event._def.extendedProps.taskId).then(
      (response) => {
        setLoading(false);
        setDeleteTaskDialogVisible(false);
        setDeleteEventConfirmDialog(false);

        toast.success(i18n.t(response.data.message));

        getAllTasks();
      },

      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  useEffect(() => {
    getAccessUserList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllTasks();
    getUpdates();
  }, [updateCount]);

  useEffect(() => {
    if (selectedItemIndex === null && allTasks && allTasks.length > 0) {
      setSelectedItemIndex(0);
    }
  }, [allTasks]);

  const handleUpdateNotification = () => {
    setUpdateCount(updateCount + 1);
  };

  const tabHeaders = (header) => {
    return (
      <div className="flex align-items-center justify-content-center">
        <span
          style={{
            fontSize: "16px",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
        >
          {t(header)}
        </span>
      </div>
    );
  };

  const reject = () => { };

  const hideEventDialog = () => {
    setEventDialog(false);
    setEventDetailDialog(false);
    setIsUpdateEvent(false);
    setCalendarEvent(emptyCalendarEvent);
  };

  const hideEventDetailDialog = () => {
    setEventDetailDialog(false);
    setCalendarEvent(emptyCalendarEvent);
  };

  const eventDialogFooter = (
    <div className="flex align-items-center justify-content-evenly">
      <Button
        label={t("cancel")}
        className="p-button-text"
        onClick={hideEventDialog}
        id="buttonFontWeight"
      />
      <Button
        label={t("save")}
        onClick={() => setEventConfirmDialog(true)}
        style={{ backgroundColor: "#F1812F" }}
        id="buttonFontWeight"
      />

      <ConfirmDialog
        visible={eventConfirmDialog}
        onHide={() => setEventConfirmDialog(false)}
        message={t("confirmationMessage")}
        accept={saveCalendarEvent}
        reject={reject}
      />
    </div>
  );


  const handleDateClick = (selected) => {
    setSelectedMembers(null);
    setSelectedPriority(2);
    setSelectedProgress(1);
    setStartDate(selected.start);
    setDueDate(selected.end);
    setAddTaskDialogVisible(true);
    setIsTaskInfoVisible(false);
    setSelectedDate(selected);
  };

  const handleEventClick = (selected) => {
    setSelectedDate(selected);

    setStartDate(selected.event.start);
    setDueDate(selected.event.end);
    setCalendarEvent({
      ...calendarEvent,
      event_id: selected.event._def.extendedProps.event_id,
      title: selected.event.title,
      start: selected.event.start,
      end: selected.event.end,
      description: selected.event._def.extendedProps.description,
      creator: selected.event._def.extendedProps.creator,
      color: selected.event._def.ui.backgroundColor,
      backgroundColor: selected.event._def.ui.backgroundColor,
    });
    //setEventDetailDialog(true);

    setLoading(true);

    SysService.getTask(selected.event._def.extendedProps.taskId).then(
      (response) => {
        setNewTaskContent(response.data);
        setSelectedPriority(response.data.priority);
        setSelectedProgress(response.data.progress);
        setSelectedMembers(response.data.memberList);
        setLoading(false);
        setUpdateTaskDialogVisible(true);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );

    setIsUpdateEvent(true);
  };

  const acceptAddNewTask = () => {
    setLoading(true);

    if (newTaskContent.taskName.trim() === "") {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    setLoading(true);
    SysService.addTask(
      newTaskContent.taskName,
      newTaskContent.notes,
      startDate,
      dueDate,
      selectedPriority,
      selectedProgress,
      pid,
      selectedMembers
    ).then(
      (response) => {
        setLoading(false);
        setAddTaskDialogVisible(false);
        toast.success(i18n.t(response.data.message));

        getAllTasks();
        fetchRemote();
        getCalendarEvents();
      }

      // (error) => {
      //   const errorMessage =
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //     error.message ||
      //     error.timeoutErrorMessage ||
      //     error.toString();

      //   if (
      //     error.response &&
      //     (error.response.status === 401 ||
      //       error.response.status === 403 ||
      //       error.response.status === 404)
      //   )
      //   {
      //     switch (error.response.status) {
      //       case 401:
      //         window.location.href = "/401";
      //         break;
      //       case 403:
      //         window.location.href = "/403";
      //         break;
      //       case 404:
      //         window.location.href = "/404";
      //         break;
      //       default:
      //         break;
      //     }
      //   }
      // }
    );
  };

  const hideAddTaskDialog = () => {
    setAddTaskDialogVisible(false);
    setNewTaskContent(emptyTaskContent);
    setSelectedMembers([]);
    setStartDate([]);
    setDueDate([]);
    setSelectedPriority(null);
    setSelectedProgress(null);
  };

  const addTaskDialogFooter = (
    <div className="flex align-items-center justify-content-center gap-3">
      <Button
        label={t("addTask")}
        style={{ backgroundColor: "#0094A8", color: "#fff", width: "100%" }}
        onClick={acceptAddNewTask}
        id="buttonFontWeight"
      />


    </div>
  );

  const hideUpdateTaskDialog = () => {
    setUpdateTaskDialogVisible(false);
    setNewTaskContent(emptyTaskContent);
    setSelectedMembers([]);
    setStartDate([]);
    setDueDate([]);
    setSelectedPriority(null);
    setSelectedProgress(null);
    setSelectedDate("");
  };

  const acceptUpdateTask = () => {
    setLoading(true);
    if (
      newTaskContent.taskName.trim() === "" ||
      newTaskContent.taskName === null ||
      startDate === null ||
      startDate === "" ||
      dueDate === null ||
      dueDate === ""
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    SysService.updateTask(
      newTaskContent.taskName,
      newTaskContent.notes,
      startDate,
      dueDate,
      selectedPriority,
      selectedProgress,
      pid,
      newTaskContent.id,
      selectedMembers,
      newTaskContent.calendarId,
      selectedColor
    ).then(
      (response) => {
        setLoading(false);
        setUpdateTaskDialogConfirmVisible(false);
        setUpdateTaskDialogVisible(false);
        toast.success(i18n.t(response.data.message));

        getAllTasks();
      },

      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    const calendarApi = selectedDate.event;

    SysService.updateCalendarEvent(
      newTaskContent.calendarId,
      newTaskContent.taskName,
      newTaskContent.notes,
      selectedColor,
      startDate,
      dueDate,
      pid,
      selectedDate.event._def.extendedProps.taskId
    ).then(
      (response) => {
        calendarApi.setExtendedProp("event_id", calendarEvent.event_id);
        calendarApi.setExtendedProp("description", calendarEvent.description);
        calendarApi.setExtendedProp("creator", calendarEvent.creator);
        calendarApi.setProp("title", calendarEvent.title);
        calendarApi.setProp("backgroundColor", calendarEvent.backgroundColor);
        calendarApi.setStart(calendarEvent.start);
        calendarApi.setEnd(calendarEvent.end);
        setSelectedDate("");
        setEventDialog(false);
        setEventDetailDialog(false);
        setIsUpdateEvent(false);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.cluster &&
            error.response.data.cluster.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
  };

  const updateTaskDialogFooter = (
    <div className="flex align-items-center justify-content-center gap-3">
      <Button
        label={t("updateTask")}
        style={{ backgroundColor: "#0094A8", color: "#fff", width: "100%" }}
        onClick={acceptUpdateTask}
        id="buttonFontWeight"
      />


    </div>
  );


  const searchMember = (event) => {
    setTimeout(() => {
      let _filteredMembers;
      if (!event.query.trim().length) {
        _filteredMembers = [...members];
      } else {
        _filteredMembers = members.filter((country) => {
          return country.firstName
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredMembers(_filteredMembers);
    }, 250);
  };

  const handleDeleteTask = (task) => {
    setSelectedTask(task);
    setDeleteTaskDialogVisible(true);
  };

  const acceptDeleteTask = () => {
    setLoading(true);
    SysService.deleteTask(selectedTask.id).then(
      (response) => {
        setLoading(false);
        setDeleteTaskDialogVisible(false);
        setIsSendEmailVisible(false);
        setIsMessageBoardVisible(false);
        setIsTaskInfoVisible(false);
        setSelectedItemIndex(null);

        toast.success(i18n.t(response.data.message));

        getAllTasks();
      },

      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    if (selectedTask.calendarId !== null) {
      SysService.deleteCalendarEvent(selectedTask.calendarId).then(
        (response) => {
          selectedDate.event.remove();
          setEventDetailDialog(false);
          setCalendarEvent(emptyCalendarEvent);
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data.cluster &&
              error.response.data.cluster.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString()
          );
        }
      );
    }
    getAllTasks();
  };

  const acceptDeleteUpdate = () => {
    setLoading(true);
  };

  const handleShowTaskInfo = (task) => {
    SysService.getTaskUsers(task.id).then(
      (response) => {
        setSelectedMembers(response.data);
        setLoading(false);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );

    setIsMessageBoardVisible(false);
    setIsSendEmailVisible(false);
    let _startDate = task?.startDate;
    _startDate = _startDate && new Date(_startDate);
    let _dueDate = task?.dueDate;
    _dueDate = _dueDate && new Date(_dueDate);

    let _selectedPriority = task?.priority;
    let _selectedProgress = task?.progress;

    setStartDate(_startDate);
    setDueDate(_dueDate);
    setSelectedPriority(_selectedPriority);
    setSelectedProgress(_selectedProgress);
    setIsTaskInfoVisible(true);
  };

  const handleSendMail = (task) => {
    setIsSendEmailVisible(true);
    setIsTaskInfoVisible(false);
    setIsMessageBoardVisible(false);

    let _memberList = [];
    for (let i = 0; i < task?.assignMemberList?.length; i++) {
      _memberList.push(task.assignMemberList[i].user);
    }

    setSelectedTask(task);
    setSelectedMembers(_memberList);
  };

  const handleHighLightedTask = (task, index) => {
    setSelectedItemIndex(index);
    const _task = {
      ...task,
      calendarStatus: task.calendarId !== null ? true : false,
    };
    setSelectedTask(_task);

    SysService.getTaskUsers(task.id).then(
      (response) => {
        setSelectedMembers(response.data);
        setLoading(false);
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );

    let _startDate = task?.startDate;
    _startDate = _startDate && new Date(_startDate);
    let _dueDate = task?.dueDate;
    _dueDate = _dueDate && new Date(_dueDate);

    let _selectedPriority = task?.priority;
    let _selectedProgress = task?.progress;
    let _selectedNotes = task?.notes;

    setStartDate(_startDate);
    setDueDate(_dueDate);
    setSelectedPriority(_selectedPriority);
    setSelectedProgress(_selectedProgress);
    setNotes(_selectedNotes);
    setIsTaskInfoVisible(true);
  };

  const calendarTemplate = (data, props) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  const fetchRemote = async (query) => {
    let events = [];
    SysService.getAllCalendarEvents(pid).then(
      (response) => {
        setLoading(false);
        for (let i = 0; i < response.data.length; i++) {
          const obj = {
            event_id: response.data[i].id,
            start: new Date(response.data[i].startDate),
            end: calendarTemplate(response.data[i].dueDate),
            title: response.data[i].subject,
            description: response.data[i].content,
            color: response.data[i].color,
          };
          events.push(obj);
        }
      },
      (error) => {
        setError(
          (error.response &&
            error.response.data.tracid &&
            error.response.data.tracid.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString()
        );
      }
    );
    return new Promise((res) => {
      res(events);
    });
  };


  const items3 = [
    {
      label: <Tag value="Not started" severity="success" rounded></Tag>,
      command: () => {
        filterTasksByProgress(1);
      },
    },
    {
      label: <Tag value="In progress" severity="info" rounded></Tag>,
      command: () => {
        filterTasksByProgress(2);
      },
    },
  ];

  const priorityOptions = [
    { name: t("priorityLow"), code: 1 },
    { name: t("priorityMedium"), code: 2 },
    { name: t("priorityHigh"), code: 3 },
  ];

  const progressOptions = [
    { name: t("notStarted"), code: 1 },
    { name: t("inProgress"), code: 2 },
    { name: t("completed"), code: 3 },
  ];

  const acItemTemplate = (data) => {
    return (
      <>
        {data.firstName} {data.lastName}
      </>
    );
  };

  const handleChangeTab = (index) => {
    setActiveIndex(index);
    if (activeIndex === 1) {
      setIsTaskInfoVisible(false);
    }
  };

  return (
    <div id="wgContainer">
      <BlockUI blocked={blockedPanel} autoZIndex={false} fullScreen="true">
        <div className="row">
          <div className="col-12">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              {completedTasksVisible === false && (
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => handleChangeTab(e.index)}
                >
                  <TabPanel header={tabHeaders("tasks")}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <SplitButton
                        label={t("filterByAssignTo")}
                        model={filterAssignedMember}
                        className="p-button-outlined mr-2 mb-2"
                      ></SplitButton>
                      &nbsp; &nbsp;
                      <SplitButton
                        label={t("filterByProgress")}
                        model={items3}
                        className="p-button-outlined mr-2 mb-2"
                      ></SplitButton>
                    </div>

                    <br />
                    <br />
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div
                          className="flex flex-column  gap-3 px-6"
                          id="wgUpdate"
                        >
                          <Button
                            label={t("btnAddTask")}
                            icon="pi pi-plus"
                            iconPos="left"
                            className=""
                            style={{
                              backgroundColor: "#0094A8",
                              color: "#fff",
                              height: "35px",
                              width: "120px",
                            }}
                            onClick={() => {
                              setNewTaskContent(emptyTaskContent);
                              setSelectedMembers(null);
                              setSelectedPriority(2);
                              setSelectedProgress(1);
                              setStartDate(null);
                              setDueDate(null);
                              setAddTaskDialogVisible(true);
                            }}
                            id="buttonFontWeight"
                          />

                          <div
                            className="taskManagement col-lg-6 col-md-12 col-sm-12  w-full"
                            id="updates"
                          >
                            {tasks &&
                              tasks.map((task, index) => (
                                <div
                                  className={`task-item-wg ps-4 pe-3 py-2 me-1 mb-3 flex ${index === selectedItemIndex ||
                                      index === selectedItemIndex
                                      ? "selected"
                                      : ""
                                    }`}
                                  style={{ position: "relative" }}
                                  key={task.id}
                                  onClick={() =>
                                    handleHighLightedTask(task, index)
                                  }
                                >
                                  <div style={{ width: "100%" }}>
                                    {task?.priority === 3 && (
                                      <>
                                        <img
                                          src={"/assets/img/urgentIcon.png"}
                                          alt=""
                                          style={{
                                            position: "absolute",
                                            width: "15px",
                                            height: "15px",
                                            right: 10,
                                            top: 10,
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-10 col-md-10 col-sm-10 flex flex-column gap-0">
                                    <h4
                                      className={`task-title mb-0 ${index === selectedItemIndex ||
                                          index === selectedItemIndex
                                          ? "selected"
                                          : ""
                                        }`}
                                    > {task?.taskName.length > 42 ?
                                      <>
                                        {`${task?.taskName.substring(0, 42)}`}
                                        <br />
                                        {`${task?.taskName.substring(42, 85)}`}
                                        {task?.taskName.length > 85 ? "..." : ""}
                                        <br /> <br />
                                      </> : <>
                                        {task?.taskName}
                                        <br /> <br /> <br />
                                      </>
                                      }
                                    </h4>


                                    <div
                                      className={`task-item-content ${index === selectedItemIndex ||
                                          index === selectedItemIndex
                                          ? "selected"
                                          : ""
                                        }`}
                                      style={{
                                        maxHeight: "120px",
                                      }}
                                    >
                                      {dateTemplate(task.creationTime)}
                                    </div>
                                    <div
                                      className={`posted mb-0 ${index === selectedItemIndex ||
                                          index === selectedItemIndex
                                          ? "selected"
                                          : ""
                                        }`}
                                    >
                                      {t("addedBy")} {task?.createdBy}
                                    </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2 col-sm-2 d-flex align-items-end justify-content-end">
                                    {task?.progress === 1 ? (
                                      <>
                                        <Tag
                                          value={t("notStarted")}
                                          style={{
                                            whiteSpace: "nowrap",
                                            marginRight: "1rem",
                                            color: "#414140",
                                            fontSize: "12px",
                                            fontWeight: "normal",
                                            backgroundColor: "#B0EF7F",
                                          }}
                                        ></Tag>{" "}
                                      </>
                                    ) : task?.progress === 2 ? (
                                      <Tag
                                        value={t("inProgress")}
                                        style={{
                                          whiteSpace: "nowrap",
                                          marginRight: "1rem",
                                          color: "#414140",
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                          background: "#7FCBEF",
                                        }}
                                      ></Tag>
                                    ) : task?.progress === 3 ? (
                                      <Tag
                                        value={t("completed")}
                                        style={{
                                          whiteSpace: "nowrap",
                                          marginRight: "1rem",
                                          color: "#414140",
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                          background: "#E7E5E2",
                                        }}
                                      ></Tag>
                                    ) : (
                                      ""
                                    )}{" "}
                                    {/* <span
                                      className="me-2"
                                      onClick={() => handleShowTaskInfo(task)}
                                    >
                                      <i className={"pi pi-info-circle"}></i>
                                    </span> */}
                                    <span
                                      className="me-2"
                                      onClick={() => {
                                        setSelectedTask(task);
                                        setIsMessageBoardVisible(true);
                                        setIsTaskInfoVisible(false);
                                        setIsSendEmailVisible(false);
                                      }}
                                    >
                                      <i className={"pi pi-comment"}></i>
                                    </span>
                                    <span
                                      className="me-2"
                                      onClick={() => handleSendMail(task)}
                                    >
                                      <i className={"pi pi-envelope"}></i>
                                    </span>
                                    {(isProjectManager() ||
                                      isPlatformManager() ||
                                      isProjectExpert() ||
                                      isSupervisor() ||
                                      task?.createdBy === user?.fullname) && (
                                        <>
                                          <span
                                            className="me-2"
                                            onClick={() => handleDeleteTask(task)}
                                          >
                                            <i className={"pi pi-trash"}></i>
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="flex justify-content-center">
                          <div className="flex flex-column">
                            <br />
                            <br />
                            {isTaskInfoVisible && (
                              <TaskInfo
                                startDate={startDate ? startDate : null}
                                dueDate={dueDate}
                                selectedMembers={selectedMembers}
                                selectedPriority={selectedPriority}
                                selectedProgress={selectedProgress}
                                selectedTask={selectedTask}
                                notes={notes}
                                projectName={projectName}
                                pid={pid}
                                notifyUpdate={handleUpdateNotification}
                                taskName={tasks[0].taskName}
                              />
                            )}
                            {isMessageBoardVisible && (
                              <MessageBoard
                                selectedTask={selectedTask}
                                loading={loading}
                                setLoading={setLoading}
                                error={error}
                                setError={setError}
                                projectId={pid}
                                selectedMembers={selectedMembers}
                                setBlockedPanel={setBlockedPanel}
                              />
                            )}
                            {isSendEmailVisible && (
                              <SendEmail
                                selectedMembers={selectedMembers}
                                projectId={pid}
                                selectedTask={selectedTask}
                                setError={setError}
                                setLoading={setLoading}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    {completedTasks?.length > 0 && (
                      <label
                        style={{
                          color: "#FB8F58",
                          marginLeft: "3rem",
                          textDecoration: "underline",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCompletedTasksVisible(true);
                          handleHighLightedTask(completedTasks[0], 0);
                        }}
                      >
                        {t("completedTasks")}
                      </label>
                    )}
                  </TabPanel>
                  <TabPanel header={tabHeaders("calendarView")}>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FullCalendar
                          themeSystem="Simplex"
                          plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            listPlugin,
                          ]}
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,listMonth",
                          }}
                          initialView="dayGridMonth"
                          selectable={true}
                          dayMaxEvents={true}
                          eventClick={handleEventClick}
                          select={handleDateClick}
                          events={calendarEvents}
                          dayHeaderContent={(arg) => {
                            return (
                              <div
                                style={{
                                  color: "black",
                                  textDecoration: "underline",
                                  textDecorationColor: "#f8f9fa",
                                }}
                              >
                                {arg.date.toLocaleDateString("default", {
                                  weekday: "long",
                                })}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              )}

              {completedTasksVisible === true && (
                <TabView>
                  <TabPanel header={tabHeaders("completedTasks")}>
                    <div className="flex justify-content-between">
                      <div>
                        <label
                          style={{
                            color: "#FB8F58",
                            marginLeft: "3rem",
                            textDecoration: "underline",
                            marginTop: "2rem",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCompletedTasksVisible(false);
                            handleHighLightedTask(tasks[0], 0);
                          }}
                        >
                          {t("goBackActiveTasks")}
                        </label>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <SplitButton
                          label="Filter By Assign To"
                          model={filterAssignedMember}
                          className="p-button-raised p-button-text mr-2 mb-2"
                        ></SplitButton>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div
                          className="flex flex-column  gap-3 px-6"
                          id="wgUpdate"
                        >
                          <br />
                          <br />

                          <div
                            className="taskManagement col-lg-6 col-md-12 col-sm-12  w-full"
                            id="updates"
                          >
                            {completedTasks &&
                              completedTasks.map((task, index) => (
                                <div
                                  className={`task-item-wg ps-4 pe-3 py-2 me-1 mb-3 flex ${index === selectedItemIndex ||
                                      index === selectedItemIndex
                                      ? "selected"
                                      : ""
                                    }`}
                                  style={{ position: "relative" }}
                                  key={task.id}
                                  onClick={() =>
                                    handleHighLightedTask(task, index)
                                  }
                                >
                                  <div style={{ width: "100%" }}>
                                    {task?.priority === 3 && (
                                      <>
                                        <img
                                          src={"/assets/img/urgentIcon.png"}
                                          alt=""
                                          style={{
                                            position: "absolute",
                                            width: "15px",
                                            height: "15px",
                                            right: 10,
                                            top: 10,
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-10 col-md-10 col-sm-10 flex flex-column gap-0">
                                    <h4
                                      className={`task-title mb-0 ${index === selectedItemIndex ||
                                          index === selectedItemIndex
                                          ? "selected"
                                          : ""
                                        }`}
                                    >
                                      {task?.taskName.length > 42 ?
                                        <>
                                          {`${task?.taskName.substring(0, 42)}`}
                                          <br />
                                          {`${task?.taskName.substring(42, 85)}`}
                                          {task?.taskName.length > 85 ? "..." : ""}
                                          <br /> <br />
                                        </> : <>
                                          {task?.taskName}
                                          <br /> <br /> <br />
                                        </>
                                      }
                                    </h4>

                                    <div
                                      className={`task-item-content ${index === selectedItemIndex ||
                                          index === selectedItemIndex
                                          ? "selected"
                                          : ""
                                        }`}
                                      style={{
                                        maxHeight: "120px",
                                      }}
                                    >
                                      {dateTemplate(task.creationTime)}
                                    </div>
                                    <div
                                      className={`posted mb-0 ${index === selectedItemIndex ||
                                          index === selectedItemIndex
                                          ? "selected"
                                          : ""
                                        }`}
                                    >
                                      {t("addedBy")} {task?.createdBy}
                                    </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2 col-sm-2 d-flex align-items-end justify-content-end">
                                    {task?.progress === 1 ? (
                                      <>
                                        <Tag
                                          value={t("notStarted")}
                                          style={{
                                            whiteSpace: "nowrap",
                                            marginRight: "1rem",
                                            color: "#414140",
                                            fontSize: "12px",
                                            fontWeight: "normal",
                                            backgroundColor: "#B0EF7F",
                                          }}
                                        ></Tag>{" "}
                                      </>
                                    ) : task?.progress === 2 ? (
                                      <Tag
                                        value={t("inProgress")}
                                        style={{
                                          whiteSpace: "nowrap",
                                          marginRight: "1rem",
                                          color: "#414140",
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                          background: "#7FCBEF",
                                        }}
                                      ></Tag>
                                    ) : task?.progress === 3 ? (
                                      <Tag
                                        value={t("completed")}
                                        style={{
                                          whiteSpace: "nowrap",
                                          marginRight: "1rem",
                                          color: "#414140",
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                          background: "#E7E5E2",
                                        }}
                                      ></Tag>
                                    ) : (
                                      ""
                                    )}{" "}
                                    {/* <span
                                      className="me-2"
                                      onClick={() => handleShowTaskInfo(task)}
                                    >
                                      <i className={"pi pi-info-circle"}></i>
                                    </span> */}
                                    {(isProjectManager() ||
                                      isPlatformManager() ||
                                      isProjectExpert() ||
                                      isSupervisor() ||
                                      task?.createdBy === user?.fullname) && (
                                        <>
                                          <span
                                            className="me-2"
                                            onClick={() => handleDeleteTask(task)}
                                          >
                                            <i className={"pi pi-trash"}></i>
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="flex justify-content-center">
                          <div className="flex flex-column">
                            <br />
                            <br />
                            {isTaskInfoVisible && (
                              <TaskInfo
                                startDate={startDate}
                                dueDate={dueDate}
                                selectedMembers={selectedMembers}
                                selectedPriority={selectedPriority}
                                selectedProgress={selectedProgress}
                                selectedTask={selectedTask}
                                projectName={projectName}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              )}
            </div>
          </div>
        </div>
      </BlockUI>
      <br />
      <br />
      <br />
      <br />

      <Dialog
        visible={addTaskDialogVisible}
        style={{ width: "800px", height: "1200px" }}
        modal
        className="p-fluid"
        footer={addTaskDialogFooter}
        onHide={hideAddTaskDialog}
        header={t("addTask")}
      >
        <>
          <div className="flex align-items-center">
            <div className="col-12">
              <div className="p-float-label w-full">
                <InputText
                  id="taskName"
                  value={newTaskContent.taskName}
                  className="w-full p-inputtext-sm"
                  onChange={(e) =>
                    setNewTaskContent({
                      ...newTaskContent,
                      taskName: e.target.value,
                    })
                  }
                  maxLength={100}
                />
                <label htmlFor="taskName">{t("taskName")} (*)</label>
              </div>
            </div>
          </div>
          <div className="flex aling-items-center">
            <div className="col-12">
              <div className="p-float-label w-full">
                <AutoComplete
                  value={selectedMembers}
                  suggestions={filteredMembers}
                  completeMethod={searchMember}
                  itemTemplate={acItemTemplate}
                  field="firstName"
                  multiple
                  onChange={(e) => setSelectedMembers(e.value)}
                  id="assignMembers"
                />
                <label className="platformColor" htmlFor="assignMembers">
                  {t("assignMembers")}
                </label>
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="col-6">
              <div className="p-float-label w-full">
                <Calendar
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.value);
                  }}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm w-full"
                  showIcon={true}
                  id="startDate"
                  maxDate={dueDate ? new Date(dueDate) : null}
                  onClearButtonClick={() => {
                    setStartDate([]);
                  }}
                  appendTo="self"
                  autoZIndex={true}
                  showButtonBar
                  showTime
                />
                <label htmlFor="startDate">{t("startDate")} </label>
              </div>
            </div>
            <div className="col-6">
              <div className="p-float-label w-full">
                <Calendar
                  value={dueDate}
                  onChange={(e) => setDueDate(e.value)}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm w-full"
                  id="dueDate"
                  minDate={startDate ? new Date(startDate) : null}
                  showTime
                  showIcon
                  showButtonBar
                  onClearButtonClick={() => {
                    setDueDate(null);
                  }}
                  appendTo="self"
                  autoZIndex={true}
                />
                <label htmlFor="dueDate">{t("dueDate")} </label>
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="col-6">
              <div className="p-float-label w-full">
                <Dropdown
                  value={selectedPriority}
                  options={priorityOptions}
                  onChange={(e) => setSelectedPriority(e.value)}
                  optionLabel="name"
                  optionValue="code"
                  className="p-inputtext-sm w-full"
                  id="priority"
                />
                <label htmlFor="priority">{t("priority")}</label>
              </div>
            </div>
            <div className="col-6">
              <div className="p-float-label w-full">
                <Dropdown
                  value={selectedProgress}
                  options={progressOptions}
                  onChange={(e) => setSelectedProgress(e.value)}
                  optionLabel="name"
                  optionValue="code"
                  className="p-inputtext-sm w-full"
                  id="progress"
                />
                <label htmlFor="progress">{t("progress")}</label>
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="col-12">
              <div className="p-float-label w-full">
                <InputTextarea
                  id="worksRequired"
                  fontSize="14px"
                  value={newTaskContent.notes}
                  onChange={(e) =>
                    setNewTaskContent({
                      ...newTaskContent,
                      notes: e.target.value,
                    })
                  }
                  placeholder={t("pleaseEnter")}
                  rows={3}
                  cols={20}
                  maxLength={1000}
                  className="w-full"
                />

                {/* <InputTextarea
                  value={newTaskContent.notes}
                  onChange={(e) =>
                    setNewTaskContent({
                      ...newTaskContent,
                      notes: e.target.value,
                    })
                  }
                  rows={5}
                  cols={30}
                  className="w-full"
                  id="notes"
                  maxLength={1000}
                /> */}
                <label className="platformColor" htmlFor="notes">
                  {t("notes")}
                </label>
              </div>
            </div>
          </div>
        </>
      </Dialog>

      <Dialog
        visible={updateTaskDialogVisible}
        style={{ width: "800px", height: "100vh" }}
        modal
        className="p-fluid"
        footer={updateTaskDialogFooter}
        onHide={hideUpdateTaskDialog}
        header={t("updateTask")}
      >
        <>
          <div className="flex align-items-center">
            <div className="col-12">
              <div className="p-float-label w-full">
                <InputText
                  id="taskName"
                  value={newTaskContent.taskName}
                  className="w-full p-inputtext-sm"
                  onChange={(e) =>
                    setNewTaskContent({
                      ...newTaskContent,
                      taskName: e.target.value,
                    })
                  }
                  maxLength={100}
                />
                <label htmlFor="taskName">{t("taskName")} (*)</label>
              </div>
            </div>
          </div>
          <div className="flex aling-items-center">
            <div className="col-12">
              <div className="p-float-label w-full">
                <AutoComplete
                  value={selectedMembers}
                  suggestions={filteredMembers}
                  completeMethod={searchMember}
                  itemTemplate={acItemTemplate}
                  field="firstName"
                  multiple
                  onChange={(e) => setSelectedMembers(e.value)}
                  id="assignMembers"
                />
                <label className="platformColor" htmlFor="assignMembers">
                  {t("assignMembers")}
                </label>
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="col-6">
              <div className="p-float-label w-full">
                <Calendar
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.value);
                  }}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm w-full"
                  showIcon={true}
                  id="startDate"
                  maxDate={dueDate ? new Date(dueDate) : null}
                  showButtonBar
                  onClearButtonClick={() => {
                    setStartDate([]);
                  }}
                  appendTo="self"
                  autoZIndex={true}
                  showTime
                />
                <label htmlFor="startDate">{t("startDate")} (*)</label>
              </div>
            </div>
            <div className="col-6">
              <div className="p-float-label w-full">
                <Calendar
                  value={dueDate}
                  onChange={(e) => {
                    setDueDate(e.value);
                  }}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm w-full"
                  showIcon={true}
                  id="dueDate"
                  minDate={startDate ? new Date(startDate) : null}
                  showButtonBar
                  onClearButtonClick={() => {
                    setDueDate(null);
                  }}
                  appendTo="self"
                  autoZIndex={true}
                  showTime
                />
                <label htmlFor="dueDate">{t("dueDate")} (*)</label>
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="col-6">
              <div className="p-float-label w-full">
                <Dropdown
                  value={selectedPriority}
                  options={priorityOptions}
                  onChange={(e) => setSelectedPriority(e.value)}
                  optionLabel="name"
                  optionValue="code"
                  className="p-inputtext-sm w-full"
                  id="priority"
                />
                <label htmlFor="priority">{t("priority")}</label>
              </div>
            </div>
            <div className="col-6">
              <div className="p-float-label w-full">
                <Dropdown
                  value={selectedProgress}
                  options={progressOptions}
                  onChange={(e) => setSelectedProgress(e.value)}
                  optionLabel="name"
                  optionValue="code"
                  className="p-inputtext-sm w-full"
                  id="progress"
                />
                <label htmlFor="progress">{t("progress")}</label>
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="col-12">
              <div className="p-float-label w-full">
                <InputTextarea
                  id="worksRequired"
                  fontSize="14px"
                  value={newTaskContent.notes}
                  onChange={(e) =>
                    setNewTaskContent({
                      ...newTaskContent,
                      notes: e.target.value,
                    })
                  }
                  placeholder={t("pleaseEnter")}
                  rows={3}
                  cols={20}
                  maxLength={1000}
                  className="w-full"
                />

                <label className="platformColor" htmlFor="notes">
                  {t("notes")}
                </label>
              </div>
            </div>
          </div>
        </>
      </Dialog>

      <ConfirmDialog
        visible={deleteTaskDialogVisible}
        onHide={() => setDeleteTaskDialogVisible(false)}
        header={t("deleteTask")}
        message={t("confirmationMessageDelete")}
        icon="pi pi-exclamation-triangle icon-red"
        accept={acceptDeleteTask}
        reject={reject}
        acceptLabel={t("delete")}
        rejectLabel={t("no")}
      />

      <ConfirmDialog
        visible={deleteUpdateConfirmDialog}
        onHide={() => setDeleteUpdateConfirmDialog(false)}
        message={t("confirmationDelete")}
        header={t("confirm")}
        icon="pi pi-exclamation-triangle"
        accept={acceptDeleteUpdate}
        reject={reject}
        className="delete"
        acceptLabel={t("delete")}
        rejectLabel={t("no")}
      />

      <Dialog
        visible={eventDialog}
        style={{ width: "800px", height: "100vh" }}
        modal
        className="p-fluid"
        onHide={hideEventDialog}
        footer={eventDialogFooter}
        header={t("event")}
      >
        <div id="dialogContainer" className="flex flex-column gap-3 mt-2">
          <div id="callInputs" className="flex flex-column gap-3">
            <div id="title">
              <div className="p-float-label w-full">
                <InputText
                  id="title"
                  type="text"
                  className="w-full p-inputtext-sm block"
                  value={calendarEvent.title}
                  maxLength={50}
                  onChange={(e) => {
                    setCalendarEvent({
                      ...calendarEvent,
                      title: e.target.value,
                    });
                  }}
                />
                <label className="platformColor" htmlFor="title">
                  {t("title")} (*)
                </label>
              </div>
            </div>

            <div id="details">
              <div className="p-float-label w-full">
                <InputTextarea
                  id="details"
                  type="text"
                  className="w-full p-inputtext-sm block"
                  value={calendarEvent.description}
                  maxLength={50}
                  rows={12}
                  onChange={(e) =>
                    setCalendarEvent({
                      ...calendarEvent,
                      description: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="details">
                  {t("details")} (*)
                </label>
              </div>
            </div>
            <div
              id="typeOfCallAndDeadline"
              className="flex align-items-center gap-2"
            >
              <div className="p-float-label w-full">
                <Calendar
                  className="w-full p-inputtext-sm"
                  id="start"
                  value={calendarEvent.start}
                  showTime
                  onChange={(e) =>
                    setCalendarEvent({
                      ...calendarEvent,
                      start: e.target.value,
                    })
                  }
                  dateFormat="dd/mm/yy"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="start">
                  {t("start")} (*)
                </label>
              </div>
              <div className="p-float-label w-full">
                <Calendar
                  className="w-full p-inputtext-sm"
                  id="end"
                  minDate={calendarEvent.start}
                  value={calendarEvent.end}
                  showTime
                  onChange={(e) =>
                    setCalendarEvent({
                      ...calendarEvent,
                      end: e.target.value,
                    })
                  }
                  dateFormat="dd/mm/yy"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="end">
                  {t("end")} (*)
                </label>
              </div>
            </div>

            <div id="color">
              <div className="p-float-label w-full mb-5">
                <Dropdown
                  id="color"
                  className="w-full p-inputtext-sm"
                  value={calendarEvent.color}
                  options={colorOptions}
                  valueTemplate={selectedColorTemplate}
                  itemTemplate={colorOptionTemplate}
                  onChange={(e) => {
                    setCalendarEvent({
                      ...calendarEvent,
                      color: e.value,
                      backgroundColor: e.value,
                    });
                  }}
                  optionLabel="text"
                  optionValue="value"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="color">
                  {t("color")} (*)
                </label>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* <Dialog
        visible={eventDetailDialog}
        style={{ width: "600px" }}
        modal
        className="p-fluid"
        footer={eventDetailDialogFooter}
        onHide={hideEventDetailDialog}
        header={eventDetailDialogTitle}
        closable={false}
      >
        <div id="dialogContainer" className="flex flex-column gap-3 mt-2">
          <div>
            {dateTemplateEvent(calendarEvent?.start)} -{" "}
            {dateTemplateEvent(calendarEvent?.end)}
          </div>
          <div>{calendarEvent.description}</div>
        </div>
      </Dialog> */}

      <ConfirmDialog
        visible={deleteEventConfirmDialog}
        onHide={() => setDeleteEventConfirmDialog(false)}
        message={t("confirmationMessage")}
        accept={handleDeleteEvent}
        reject={reject}
      />
    </div>
  );
};

export default withTranslation()(Tasks);
