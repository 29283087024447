import { InputText } from "primereact/inputtext";
import React, { useState, useEffect, useRef } from "react";
import SockJsClient from "react-stomp";
import { withTranslation, Trans } from "react-i18next";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import i18n from "i18next";
import SysService from "../services/sys.service";
import { Avatar } from "primereact/avatar";

const SOCKET_URL = process.env.REACT_APP_SERVER_ADDRESS + "/ws-discussion";

const MessageBoard = ({
  t,
  selectedTask,
  loading,
  setLoading,
  error,
  setError,
  setBlockedPanel,
  projectId,
}) => {
  const [content, setContent] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const [onDataReceived, setOnDataReceived] = useState("0");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [confirmDeleteDiscussion, setConfirmDeleteDiscussion] = useState(false);
  const [msg, setMsg] = useState("");
  const contentRef = useRef(null);

  const handleMessageDelete = (msg) => {
    setConfirmDeleteDiscussion(true);
    setSelectedMessage(msg);
  };

  const getUserInfo = () => {
    setLoading(true);

    if (selectedTask !== null || selectedTask !== undefined) {
      SysService.getTaskDiscussions(selectedTask?.id).then(
        (response) => {
          setContent(response.data);
          setBlockedPanel(false);
          setLoading(false);
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString()
          );

          setBlockedPanel(false);
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [onDataReceived, newMessage]);

  useEffect(() => {
    getUserInfo();
    if (newMessage) {
      setContent((prevContent) => [...prevContent, newMessage]);
      setNewMessage(null);
    }
  }, [selectedTask?.id, onDataReceived, newMessage]);

  const acceptAddDiscussion = () => {
    setLoading(true);

    if (msg.trim() === "") {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMessage" />);
      return;
    }

    if (msg.length > 500) {
      setLoading(false);
      toast.warning(<Trans i18nKey="contentDesc" />);
      return;
    }

    if (selectedTask !== undefined || selectedTask !== null) {
      SysService.addTaskDiscussionMessage(
        projectId,
        selectedTask?.id,
        msg
      ).then(
        (response) => {
          setMsg("");
          setNewMessage(response.data);

          setBlockedPanel(false);
          setLoading(false);
        },
        (error) => {
          setError(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString()
          );

          setBlockedPanel(true);
          setLoading(false);
        }
      );
    }
  };

  const acceptDeleteDiscussion = () => {
    setLoading(true);
    SysService.deleteTaskDiscussionMessage(selectedMessage.id).then(
      (response) => {
        setBlockedPanel(false);
        setLoading(false);
        toast.success(i18n.t(response.data.message));

        setContent((prevContent) =>
          prevContent.filter((msg) => msg.id !== selectedMessage.id)
        );
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(message);
        toast.error(i18n.t(message));
        setLoading(false);
      }
    );
  };

  const onConnected = () => {};

  const onDisconnected = () => {};

  let change = "";

  const onMessageReceived = (msg) => {
    setContent((prevContent) => [...prevContent, msg]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      acceptAddDiscussion();
    }
  };

  const reject = () => {};

  const dateTemplate = (data) => {
    const date = new Date(data);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      <span className="platformColor">{t("messageBoardAboutTheTask")}</span>
      <div
        className="taskInput mt-3"
        style={{
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: "#0094A8",
        }}
      >
        <div
          style={{
            width: "550px",
            height: "400px",
            backgroundColor: "#F6F4F4",
            borderRadius: "6px",
            overflowY: "auto",
          }}
          ref={contentRef}
        >
          <SockJsClient
            url={SOCKET_URL}
            topics={["/topic/message/task_" + selectedTask.id]}
            onConnect={onConnected()}
            onDisconnect={onDisconnected()}
            onMessage={(msg) => onMessageReceived(msg)}
            debug={true}
          />
          {content && content.length > 0 && (
            <div className="flex flex-column gap-3">
              {content?.map((disc, index) => (
                <div
                  className="task-item-message pe-3 py-2 me-1 mb-3 flex"
                  key={index}
                >
                  <div className="col-2 flex align-items-start justify-content-center">
                    <div className="">
                      <Avatar
                        shape="circle"
                        label={
                          disc?.userFullName?.charAt(0) +
                          disc?.userFullName?.charAt(
                            disc?.userFullName.indexOf(" ") + 1
                          )
                        }
                        style={{
                          backgroundColor: "#0094A8",
                          color: "#ffffff",
                          borderRadius: "5rem",
                        }}
                        size="large"
                      ></Avatar>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="organisation-name mb-0">
                      {disc?.userFullName} <br /> {disc?.organisationName}
                    </div>
                    <div className="task-item-content-message mb-0">
                      {" "}
                      {disc?.body}
                    </div>
                  </div>
                  <div className="col-2 flex flex-column align-items-end justify-content-end gap-5">
                    <div
                      style={{
                        fontSize: "10px",
                        lineHeight: "12px",
                        color: "#8C97AC",
                      }}
                    >
                      {dateTemplate(disc?.submitTime)}
                    </div>
                    <div>
                      <span
                        className=""
                        onClick={() => handleMessageDelete(disc)}
                      >
                        <i
                          className="pi pi-trash"
                          style={{
                            fontSize: "10px",
                            color: "#116182",
                          }}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="flex align-items-center justify-content-center gap-3 p-3"
          style={{ backgroundColor: "#F6F4F4" }}
        >
          <div className="w-full">
            <InputText
              id="firstName"
              type="text"
              className="w-full p-inputtext-sm block"
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              style={{ width: "468px", height: "39px" }}
              placeholder={t("typeNewMessageHere")}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div>
            <span
              className="w-full"
              label={t("save")}
              onClick={() => acceptAddDiscussion()}
            >
              <i
                className="pi pi-send"
                style={{
                  fontSize: "25px",
                  color: "#116182",
                  transform: "rotate(40deg)",
                  cursor: "pointer",
                }}
              ></i>{" "}
            </span>
          </div>
        </div>
      </div>

      <ConfirmDialog
        visible={confirmDeleteDiscussion}
        onHide={() => setConfirmDeleteDiscussion(false)}
        header={t("deleteDiscussion")}
        message={t("confirmationMessage")}
        icon="pi pi-exclamation-triangle"
        accept={acceptDeleteDiscussion}
        reject={reject}
      />
    </>
  );
};

export default withTranslation()(MessageBoard);
