import React, { Component } from "react";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { Galleria } from "primereact/galleria";

import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";
import { Divider } from "primereact/divider";

import { withTranslation, Trans, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { handleDownloadDocument } from "../common/functions";
import { isUser, isViewerExpert } from "../common/token-check";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";

import YesNoPending from "../common/ccs_yesNoPending";
import EnergyPerformanceClass from "../common/css_energyPerformanceClass.js";
import MonitoringVisitsAndContacts from "../common/ccs_monitoringVisitsAndContacts";
import { Calendar } from "primereact/calendar";
import { format, parseISO } from "date-fns";
import DocumentUpload from "../common/documentUpload.jsx";

import contactType from "../common/ccs_contactType";
import PdfViewer from "../common/pdfViewer.jsx";

class PostCompletion extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  emptyMonitoringVisit = {
    id: "",
    monitoringVisitsAndContacts: "",
    description: "",
    uploadDate: "",
    notes: "",
  };

  emptyClientLog = {
    contactType: "",
    description: "",
    notes: "",
    uploadDate: "",
    pid: "",
  };

  emptyNewDocumentLink = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    documentDate: "",
    projectId: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",
      pid: "",
      visibleCustomSidebar: false,
      visibleRight: false,

      nonEditAccess: true,
      activeIndex: 0,

      p80Documents: [],
      p81Documents: [],
      p82Documents: [],

      p80DocumentIndex: -1,
      p81DocumentIndex: -1,
      p82DocumentIndex: -1,

      p80SelectedDocument: null,
      p81SelectedDocument: null,
      p82SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      newDocumentLink: this.emptyNewDocumentLink,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,

      addDocumentLinkDialogVisible: false,
      addDocumentLinkConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      documentLinkData: "",

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      filters2: null,
      globalFilterValue2: "",

      filters3: null,
      globalFilterValue3: "",

      filters4: null,
      globalFilterValue4: "",

      saveButtonStatus: false,

      addMonitoringVisitDialogVisible: false,
      monitoringVisits: "",
      newMonitoringVisit: this.emptyMonitoringVisit,

      updateOrganisationDialogVisible: false,
      updateMonitoringVisitDialogVisible: false,

      homeownerDateOfReturn: "",
      uploadDate: "",
      uploadDocumentCheckConfirmDialog: false,
      projectId: "",

      selectedRow: null,
      selectedRow2: null,

      deleteDocumentConfirmDialogVisible: false,
      deleteDocumentLinkConfirmDialogVisible: false,

      annualGasConsumptionTimePeriod: "",
      annualElectricityConsumptionTimePeriod: "",

      documents: [],
      selectedDocument: null,

      galleriaP80: React.createRef(null),
      galleriaP81: React.createRef(null),
      galleriaP82: React.createRef(null),
      imageListP80: [],
      imageListP81: [],
      imageListP82: [],
      galleriaIndex: 0,

      pdfVisible: false,
      documentUrl: "",

      saveButtonConfirmDialog:false,
      saveButtonPrevConfirmDialog:false,
      nextPhaseButtonConfirmDialog: false,
      prevPhaseButtonConfirmDialog: false,
    };
  }

  /*JS_methods*/

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img
          src={this.state.documentUrl}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  projectIDTemplate(data) {
    let arr = [];
    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");

    return str;
  }

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };

  actionTemplate = (data, props) => {
    const { t } = useTranslation();

    return (
      <div>
        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        />
        <Button
          icon="pi pi-download"
          href
          tooltip={t("download")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip={t("delete")}
            className="p-button-rounded p-button-text"
            onClick={() =>
              this.setState({
                selectedRow: data,
                deleteDocumentConfirmDialogVisible: true,
              })
            }
          />
        )}
      </div>
    );
  };

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 0) {
      const index = this.state.p80Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p80Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 1) {
      const index = this.state.p81Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p81Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 2) {
      const index = this.state.p82Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p82Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];

    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P8" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 0) {
        let localP80Documents = [...this.state.p80Documents];
        localP80Documents = localP80Documents.concat(localArray);
        this.setState({
          loading: false,
          p80Documents: localP80Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 1) {
        let localP81Documents = [...this.state.p81Documents];
        localP81Documents = localP81Documents.concat(localArray);
        this.setState({
          loading: false,
          p81Documents: localP81Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 2) {
        let localP82Documents = [...this.state.p82Documents];
        localP82Documents = localP82Documents.concat(localArray);
        this.setState({
          loading: false,
          p82Documents: localP82Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
      documents: [],
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P8" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState(
          { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
          () => {
            this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
            this.state.newDocument.fileName = this.state.s3Response.fileName;
            this.state.newDocument.documentName =
              this.state.s3Response.documentName;
            this.state.newDocument.documentDate =
              this.state.s3Response.documentDate;
            this.state.newDocument.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.put2S3();

        let p80doc = [...this.state.p80Documents];
        let p81doc = [...this.state.p81Documents];
        let p82doc = [...this.state.p82Documents];

        if (this.state.activeIndex === 0) {
          p80doc.push(this.state.newDocument);
          this.setState({ p80Documents: p80doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 1) {
          p81doc.push(this.state.newDocument);
          this.setState({ p81Documents: p81doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 2) {
          p82doc.push(this.state.newDocument);
          this.setState({ p82Documents: p82doc, saveButtonStatus: true });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectAddDocument = () => {};

    /////////////////////////////////////////////////////

    acceptForPrevPhaseButton = () => {
      this.setState({ prevPhaseButtonConfirmDialog: false });
      this.stepSaveAndPrevPhase();
    };
  
    acceptForNextPhaseButton = () => {
      this.setState({ nextPhaseButtonConfirmDialog: false });
      this.stepSaveAndNextPhase();
    };
  
    rejectForNextPhaseButton = () => {
      this.props.history.push({
        pathname: "/indoorQE",
        state: this.state.project,
      });
    };
  
    rejectForPrevPhaseButton = () => {
      this.props.history.push({
        pathname: "/practicalCompletion",
        state: this.state.project,
      });
    };
  
    /////////////////////////////////////////////////////


  rejectForNext = () => {
    let inx = this.state.activeIndex;
    inx = inx + 1;
    this.setState({
      activeIndex: inx,
      saveButtonStatus:false
    });
    this.componentDidMount()
    window.scrollTo(0, 0);
  };

  rejectForPrev = () => {
    let inx = this.state.activeIndex;
    inx = inx - 1;
    this.setState({
      activeIndex: inx,
      saveButtonStatus:false
    });
    this.componentDidMount();
    window.scrollTo(0, 0);
  };

  acceptSaveForPrevButton = () => {
    this.setState({ saveButtonPrevConfirmDialog: false });
    this.stepSaveAndPrev();
  };

  acceptSaveForNextButton = () => {
    this.setState({ saveButtonConfirmDialog: false });
    this.stepSaveAndNext();
  };


  addClientLog() {
    SysService.addClientLog(
      this.state.newMonitoringVisit.monitoringVisitsAndContacts,
      this.state.newMonitoringVisit.description,
      this.state.newMonitoringVisit.notes,
      this.state.newMonitoringVisit.uploadDate,
      this.state.pid
    ).then(
      (response) => {
        this.setState({
          loading: false,
          blockedPanel: false,
        });
        toast.success(<Trans i18nKey={response.data.message} />);
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  addMonitoringVisit() {
    if (
      this.state.newMonitoringVisit.monitoringVisitsAndContacts == null ||
      this.state.newMonitoringVisit.monitoringVisitsAndContacts.trim() === "" ||
      this.state.newMonitoringVisit.uploadDate === null ||
      this.state.newMonitoringVisit.uploadDate === "" ||
      this.state.newMonitoringVisit.description == null ||
      this.state.newMonitoringVisit.description.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({
        loading: true,
        addMonitoringVisitDialogVisible: false,
      });

      SysService.addMonitoringVisit(
        this.state.newMonitoringVisit.monitoringVisitsAndContacts,
        this.state.newMonitoringVisit.description,
        this.state.newMonitoringVisit.notes,
        this.state.newMonitoringVisit.uploadDate,
        this.state.pid
      ).then(
        (response) => {
          toast.success(<Trans i18nKey={response.data.message} />);
          //this.addClientLog();
          this.getMonitoringVisit();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: true,
        addMonitoringVisitDialogVisible: false,
      });
    }
  }

  updateMonitoringVisits() {
    if (
      this.state.newMonitoringVisit.monitoringVisitsAndContacts == null ||
      this.state.newMonitoringVisit.monitoringVisitsAndContacts.trim() === "" ||
      this.state.newMonitoringVisit.description == null ||
      this.state.newMonitoringVisit.description.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({
        loading: true,
        updateMonitoringVisitDialogVisible: false,
      });
      SysService.updateMonitoringVisit(
        this.state.newMonitoringVisit.id.toString(),
        this.state.newMonitoringVisit.monitoringVisitsAndContacts,
        this.state.newMonitoringVisit.description,
        this.state.newMonitoringVisit.notes,
        this.state.newMonitoringVisit.uploadDate
      ).then(
        (response) => {
          this.setState({
            loading: false,
          });
          toast.success(<Trans i18nKey="164" />);
          //this.addClientLog();
          this.getMonitoringVisit();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  deleteMonitoringVisit(data) {
    this.setState({
      loading: true,
      updateMonitoringVisitDialogVisible: false,
    });

    let id = data.id.toString();

    SysService.deleteMonitoringVisit(id).then(
      (response) => {
        this.setState({
          loading: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        this.getMonitoringVisit();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  getMonitoringVisit() {
    SysService.getMonitoringVisit(this.props.location.state.id).then(
      (response) => {
        this.setState({
          monitoringVisits: response.data,
          loading: false,
          blockedPanel: false,
          uploadDate: this.calendarFormatTemplate(response?.data?.uploadDate),
        });
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  onInputChangeMonitoringVisit = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newMonitoringVisit = { ...this.state.newMonitoringVisit };
    newMonitoringVisit[`${name}`] = val;

    this.setState({
      newMonitoringVisit,
    });
  };

  onInputChangeMonitoringVisitUploadDate = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newMonitoringVisit = { ...this.state.newMonitoringVisit };
    newMonitoringVisit[`${name}`] = new Date(val);

    this.setState({
      newMonitoringVisit,
    });
  };

  onInputChangeCalendar1 = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      annualGasConsumptionTimePeriod: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeCalendar2 = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      annualElectricityConsumptionTimePeriod: val,
      saveButtonStatus: true,
    });
  };

  calendarFormatTemplate = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  calendarFormatTemplate2 = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const date = new Date(data);
      return date;
    } else {
      return "";
    }
  };

  calendarFormatTemplateTable = (rowData) => {
    if (rowData === null) {
      return "null";
    } else if (rowData !== undefined) {
      return format(new Date(rowData), "dd/MM/yyyy");
    } else {
      return "";
    }
  };

  calendarDateTimeStampTemplate(data) {
    return format(new Date(data.logTime), "dd/MM/yyyy");
  }

  calendarFormat = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp80Documents = [];
        let temp81Documents = [];
        let temp82Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P80") {
              temp80Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P81") {
              temp81Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P82") {
              temp82Documents.push(response.data.documentList[i]);
            }

            if (
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpg") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".png") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpeg")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc =
                response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;

              if (response.data.documentList[i].phase === "P80") {
                this.state.imageListP80.push(newObj);
              }
              if (response.data.documentList[i].phase === "P81") {
                this.state.imageListP81.push(newObj);
              }
              if (response.data.documentList[i].phase === "P82") {
                this.state.imageListP82.push(newObj);
              }
            }
          }
        }

        this.setState({
          project: response.data,
          p80Documents: temp80Documents,
          p81Documents: temp81Documents,
          p82Documents: temp82Documents,
          homeownerDateOfReturn: this.calendarFormatTemplate(
            response?.data?.homeownerDateOfReturn
          ),
          annualGasConsumptionTimePeriod: this.calendarFormat(
            response?.data?.annualGasConsumptionTimePeriodP8
          ),
          annualElectricityConsumptionTimePeriod: this.calendarFormat(
            response?.data?.annualElectricityConsumptionTimePeriodP8
          ),

          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.getMonitoringVisit();
    this.getDocumentLink();
    this.initFilters1();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        this.setState({
          saveButtonPrevConfirmDialog:true
        })
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        this.setState({
          saveButtonConfirmDialog:true
        })
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p80Documents;
        } else if (inx === 1) {
          this.state.project.documents = this.state.p81Documents;
        } else if (inx === 2) {
          this.state.project.documents = this.state.p82Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P8" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageListP80: [],
              imageListP81: [],
              imageListP82: [],
            });

            this.componentDidMount();
            toast.success(<Trans i18nKey="saved" />);
            window.scrollTo(0, 0);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  stepSaveAndPrevPhase() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p80Documents;
        } else if (inx === 1) {
          this.state.project.documents = this.state.p81Documents;
        } else if (inx === 2) {
          this.state.project.documents = this.state.p82Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P8" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageListP80: [],
              imageListP81: [],
              imageListP82: [],
            });

            this.componentDidMount();
            toast.success(<Trans i18nKey="saved" />);
            window.scrollTo(0, 0);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      this.props.history.push({
        pathname: "/practicalCompletion",
        state: this.state.project,
      });
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  stepSaveAndNextPhase() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p80Documents;
        } else if (inx === 1) {
          this.state.project.documents = this.state.p81Documents;
        } else if (inx === 2) {
          this.state.project.documents = this.state.p82Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P8" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageListP80: [],
              imageListP81: [],
              imageListP82: [],
            });

            this.componentDidMount();
            toast.success(<Trans i18nKey="saved" />);
            window.scrollTo(0, 0);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      this.props.history.push({
        pathname: "/indoorQE",
        state: this.state.project,
      });
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  stepSaveAndPrev() {
    let inx = this.state.activeIndex;
    
    if (inx <= 0) {
      return; 
    }
  
    if (!isViewerExpert()) {
      this.setState({
        loading: true,
      });

      this.state.project.documents = [];

      if (inx === 0) {
        this.state.project.documents = this.state.p80Documents;
      } else if (inx === 1) {
        this.state.project.documents = this.state.p81Documents;
      } else if (inx === 2) {
        this.state.project.documents = this.state.p82Documents;
      }

      this.state.project.projectId = this.state.pid;
      this.state.project.phase = "P8" + inx;

      SysService.updateProject(this.state.project).then(
        (response) => {
          this.setState({
            project: response.data,
            loading: false,
            saveButtonStatus: false,
            imageListP80: [],
            imageListP81: [],
            imageListP82: [],
          });

          this.componentDidMount();
          toast.success(<Trans i18nKey="saved" />);
          window.scrollTo(0, 0);
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          toast.error(<Trans i18nKey={error.response} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  
    if (inx > 0) {
      inx = inx - 1;
      this.setState({
        activeIndex: inx,
      });
    }
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop2(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue2}
            onChange={this.onGlobalFilterChange2}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop3(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue3}
            onChange={this.onGlobalFilterChange3}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop4(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue4}
            onChange={this.onGlobalFilterChange4}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let filters2 = { ...this.state.filters2 };
    filters2["global"].value = value;

    this.setState({ filters2, globalFilterValue2: value });
  };

  onGlobalFilterChange3 = (e) => {
    const value = e.target.value;
    let filters3 = { ...this.state.filters3 };
    filters3["global"].value = value;

    this.setState({ filters3, globalFilterValue3: value });
  };

  onGlobalFilterChange4 = (e) => {
    const value = e.target.value;
    let filters4 = { ...this.state.filters4 };
    filters4["global"].value = value;

    this.setState({ filters4, globalFilterValue4: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });

    this.setState({
      filters2: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue2: "",
    });

    this.setState({
      filters3: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue3: "",
    });

    this.setState({
      filters4: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue4: "",
    });
  };

  handleUpdateMonitoringVisit = (rowData) => {
    let newMonitoringVisit = { ...rowData };
    this.setState({
      newMonitoringVisit: newMonitoringVisit,
      updateMonitoringVisitDialogVisible: true,
    });
  };

  actionTemplate3 = (data, props) => {
    return (
      <div>
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={() =>
              this.setState({
                selectedRow2: data,
                deleteDocumentLinkConfirmDialogVisible: true,
              })
            }
          />
        )}
      </div>
    );
  };

  actionTemplate2 = (data, props) => {
    return (
      <>
        {!isViewerExpert() && (
          <Button
            icon="pi pi-pencil"
            href
            tooltip="Update"
            className="p-button-rounded p-button-text"
            onClick={() => this.handleUpdateMonitoringVisit(data)}
          />
        )}

        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.deleteMonitoringVisit(data);
            }}
          />
        )}
      </>
    );
  };

  monitoringVisitsAndContactsTemp = (data) => {
    if (data.monitoringVisitsAndContacts === "1") {
      return "E-mail";
    } else if (data.monitoringVisitsAndContacts === "2") {
      return "Site visit";
    } else if (data.monitoringVisitsAndContacts === "3") {
      return "Telephone call";
    } else if (data.monitoringVisitsAndContacts === "4") {
      return "Text message";
    } else if (data.monitoringVisitsAndContacts === "5") {
      return "Video call";
    }
  };

  hideUpdateMonitoringVisitDialog = () => {
    this.setState({
      updateMonitoringVisitDialogVisible: false,
    });
  };

  updateMonitoringVisitDialogFooter = () => {
    return (
      <>
        <Button
          label={<Trans i18nKey={"cancel"} />}
          icon="pi pi-times"
          className="p-button-text platformColor"
          onClick={this.hideUpdateMonitoringVisitDialog}
          id="buttonFontWeight"
        />
        <Button
          label={<Trans i18nKey={"save"} />}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.updateMonitoringVisits()}
          id="buttonFontWeight"
        />
      </>
    );
  };

  uploadDocumentCheck = (e) => {
    if (e.target.value === "1" && this.state.p82Documents.length < 1) {
      this.setState({ uploadDocumentCheckConfirmDialog: true });
    } else {
      this.setState({ uploadDocumentCheckConfirmDialog: false });
    }
  };

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/indoorQE",
        state: this.state.project,
      });
    } else {
      this.setState({
        nextPhaseButtonConfirmDialog: true,
      });
    }
  };

  prevPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/practicalCompletion",
        state: this.state.project,
      });
    } else {
      this.setState({
        prevPhaseButtonConfirmDialog: true,
      });
    }
  };

  onInputChangeCalendar = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      homeownerDateOfReturn: val,
      saveButtonStatus: true,
    });
  };

  dateTableTemplate = (data) => {
    return format(new Date(data.logTime), "dd-MM-yyyy");
  };

  documentURLTemplate = (data) => {
    return (
      <div>
        {!isViewerExpert() && (
          <a href={data.documentURL} target="_blank" rel="noopener noreferrer">
            {data.documentURL}
          </a>
        )}
      </div>
    );
  };

  hideAddDocumentLinkDialog = () => {
    this.setState({
      submitted: false,
      addDocumentLinkDialogVisible: false,
    });
  };

  handleAddDocumentLink = () => {
    this.setState({
      newDocumentLink: this.emptyNewDocumentLink,
      submitted: false,
      addDocumentLinkDialogVisible: true,
    });
  };

  onInputChangeDocLink = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocumentLink = { ...this.state.newDocumentLink };
    newDocumentLink[`${name}`] = val;

    this.setState({ newDocumentLink });
  };

  acceptAddDocumentLink = () => {
    let projectId = this.state.pid;
    this.setState({
      projectId: projectId.toString(),
    });

    if (
      this.state.newDocumentLink.documentName == null ||
      this.state.newDocumentLink.documentName.trim() === "" ||
      this.state.newDocumentLink.documentURL == null ||
      this.state.newDocumentLink.documentURL.trim() === "" ||
      this.state.newDocumentLink.fileDescription == null ||
      this.state.newDocumentLink.fileDescription.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({
        loading: true,
        addDocumentLinkDialogVisible: false,
      });

      SysService.acceptAddDocumentLink(
        this.state.newDocumentLink.fileDescription,
        this.state.newDocumentLink.documentName,
        this.state.newDocumentLink.documentURL,
        "P82",
        this.state.pid.toString()
      ).then(
        (response) => {
          this.setState({
            loading: false,
          });

          toast.success(<Trans i18nKey={response.data.message} />);

          this.getDocumentLink();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedRow);
  };

  rejectDeleteDocument = () => {};

  acceptDeleteDocumentLink = () => {
    this.deleteDocumentLink(this.state.selectedRow2);
  };

  updateDocumentLink() {
    if (
      this.state.newDocumentLink.documentName == null ||
      this.state.newDocumentLink.documentName.trim() === "" ||
      this.state.newDocumentLink.documentURL == null ||
      this.state.newDocumentLink.documentURL.trim() === "" ||
      this.state.newDocumentLink.fileDescription == null ||
      this.state.newDocumentLink.fileDescription.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({
        loading: true,
        updateDocumentLinkDialogVisible: false,
      });
      SysService.updateDocumentLink(
        this.state.newDocumentLink.fileDescription,
        this.state.newDocumentLink.documentName,
        this.state.newDocumentLink.documentURL,
        this.state.newDocumentLink.id.toString()
      ).then(
        (response) => {
          this.setState({
            loading: false,
          });
          toast.success(<Trans i18nKey="164" />);

          this.getDocumentLink();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  getDocumentLink() {
    SysService.getDocumentLink(this.props.location.state.id, "P82").then(
      (response) => {
        this.setState({
          documentLinkData: response.data,
          loading: false,
          blockedPanel: false,
        });
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  deleteDocumentLink(data) {
    this.setState({
      loading: true,
    });

    let newData = data.id.toString();

    SysService.deleteDocumentLink(newData).then(
      (response) => {
        this.setState({
          loading: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        this.getDocumentLink();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentDialogVisible: false,
      documents: [],
    });
  };

  handleKeyDown = (event, name) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      let project = { ...this.state.project };
      project[`${name}`] = null;
      this.setState({
        project,
        saveButtonStatus: true,
      });
    }
  };

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);
    const headerTable2 = this.renderHeaderTableTop2(t);
    const headerTable3 = this.renderHeaderTableTop3(t);
    const headerTable4 = this.renderHeaderTableTop4(t);

    this.stepItems = [
      {
        label: t("homeownerReturn"),
        command: (event) => {},
      },
      {
        label: t("monitoringVisits"),
        command: (event) => {},
      },
      {
        label: t("energyEfficiency(Post-works)"),
        command: (event) => {},
      },
    ];

    const addDocumentLinkDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentLinkDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.acceptAddDocumentLink()}
        />
      </React.Fragment>
    );

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <>
                <Card
                  style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
                >
                  <div className="flex align-items-center justify-content-center">
                    <Link
                      to={{ pathname: "/project", state: this.state.project }}
                      style={{ textDecoration: "none" }}
                    >
                      <Chip
                        style={{ fontSize: "18px", fontStyle: "Manrope" }}
                        label={
                          this.state.project.projectName +
                          " (ID: " +
                          this.projectIDTemplate(this.state.project.id) +
                          ")"
                        }
                        className="mb-2 custom-chip-home"
                      />
                    </Link>
                  </div>
                </Card>
                <br /> <br />
                <div className={"col-12"}>
                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                    <div className="flex justify-content-between mb-3">
                      <Button
                        style={{
                          color: "0094A8",
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "lighter",
                        }}
                        label={t("showPhases")}
                        className="p-button-sm p-button-mb"
                        onClick={() =>
                          this.setState({ visibleCustomSidebar: true })
                        }
                      />
                      <div>
                        <Chip
                          style={{
                            fontStyle: "Manrope",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                          label={t("phase") + " 8: " + t("phase8Name")}
                          icon="pi pi-check-circle"
                          className="mr-2 mb-2 custom-chip-constant"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="col-12 mb-0">
                      <Steps
                        model={this.stepItems}
                        activeIndex={this.state.activeIndex}
                        onSelect={(e) => {
                          if (!this.state.saveButtonStatus) {
                            this.setState({ activeIndex: e.index });
                          } else {
                            if (this.state.activeIndex !== e.index) {
                              toast.warning(
                                <Trans i18nKey="pleaseSaveFirst" />
                              );
                            }
                          }
                        }}
                        readOnly={false}
                      />
                    </div>

                    <TabView
                      className="projectNav"
                      activeIndex={this.state.activeIndex}
                      onTabChange={(e) =>
                        this.setState({ activeIndex: e.index })
                      }
                    >
                      {/*Phase80*/}
                      <TabPanel header={t("worksAgreementWithHomeowner")}>
                        <div
                          className="mb-4"
                          style={{
                            backgroundColor: "#FFFADD",
                            padding: "10px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="root-preline">{t("p81Text")}</div>
                        </div>

                        <Divider />

                        <div className="grid">
                          {/*homeowner Goods/Assets Returned From Storage And/Or Purchased Header*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t(
                                "homeownerGoods/AssetsReturnedFromStorageAnd/OrPurchased"
                              )}
                            </label>
                          </div>

                          {/*Homeowner goods/assets returned from storage and/or purchased Dropdown*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <Dropdown
                                fontSize="14px"
                                id="homeownerReturnedFromStoragePurchased"
                                disabled={this.state.nonEditAccess}
                                value={
                                  this.state.project
                                    .homeownerReturnedFromStoragePurchased
                                }
                                options={YesNoPending}
                                placeholder={t("pleaseSelect")}
                                onChange={(e) =>
                                  this.onInputChange(
                                    e,
                                    "homeownerReturnedFromStoragePurchased"
                                  )
                                }
                                optionLabel="name"
                                optionValue="code"
                                className="w-full"
                                onKeyDown={(e) =>
                                  this.handleKeyDown(
                                    e,
                                    "homeownerReturnedFromStoragePurchased"
                                  )
                                }
                              />
                            </div>
                          </div>

                          {/*Homeowner returns to property Header*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("homeownerReturnToProperty")}
                            </label>
                          </div>

                          {/*Homeowner returns to property Dropdown*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <Dropdown
                                fontSize="14px"
                                id="homeownerReturnToProperty"
                                disabled={this.state.nonEditAccess}
                                value={
                                  this.state.project.homeownerReturnToProperty
                                }
                                options={YesNoPending}
                                placeholder={t("pleaseSelect")}
                                onChange={(e) =>
                                  this.onInputChange(
                                    e,
                                    "homeownerReturnToProperty"
                                  )
                                }
                                optionLabel="name"
                                optionValue="code"
                                className="w-full"
                                onKeyDown={(e) =>
                                  this.handleKeyDown(
                                    e,
                                    "homeownerReturnToProperty"
                                  )
                                }
                              />
                            </div>
                            <div
                              style={{
                                display:
                                  this.state.project
                                    .homeownerReturnToProperty === "1"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {/*homeownerDateOfReturn*/}
                              <div>
                                <div className="mb-1">
                                  <br />
                                  <label
                                    htmlFor="homeownerDateOfReturn"
                                    className="platformColor"
                                  >
                                    {t("homeownerDateOfReturn")}{" "}
                                  </label>
                                </div>
                                <div className="card flex justify-content-center">
                                  <Calendar
                                    dateFormat="dd/mm/yy"
                                    id="homeownerDateOfReturn"
                                    placeholder={t("pleaseEnter")}
                                    disabled={this.state.nonEditAccess}
                                    value={this?.state?.homeownerDateOfReturn}
                                    onChange={(e) => {
                                      this.onInputChangeCalendar(
                                        e,
                                        "homeownerDateOfReturn"
                                      );
                                    }}
                                    maxLength={10}
                                    readOnlyInput={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*dividerLine*/}
                          <div className="col-12 md:col-12 lg:col-12">
                            <hr />
                          </div>

                          {/*addDocument80*/}
                          <div className="field col-12 mb-0">
                            {!isViewerExpert() && (
                              <Button
                                style={{
                                  color: "0094A8",
                                  fontSize: "12px",
                                  fontStyle: "Manrope",
                                }}
                                disabled={this.state.nonEditAccess}
                                label={t("btnAddDocument")}
                                className="p-button p-component p-button-outlined p-button-sm"
                                onClick={this.handleAddDocument}
                              />
                            )}
                          </div>

                          {/* p80 data table */}
                          <div className="col-12">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                              <DataTable
                                name="dt"
                                size="small"
                                showGridlines
                                selectionMode="single"
                                value={this.state.p80Documents}
                                dataKey="id"
                                stripedRows
                                responsiveLayout="scroll"
                                emptyMessage={t("noDocumentFound")}
                                header={headerTable}
                                paginator
                                rows={10}
                                filters={this.state.filters1}
                                filterDisplay="menu"
                                loading={this.state.loading}
                                globalFilterFields={[
                                  "fileName",
                                  "fileDescription",
                                ]}
                              >
                                <Column
                                  field="fileName"
                                  header={t("documentName")}
                                  sortable
                                ></Column>
                                <Column
                                  field="fileDescription"
                                  header={t("fileDescription")}
                                  sortable
                                ></Column>
                                <Column
                                  header={t("action")}
                                  style={{ textAlign: "center", width: "8em" }}
                                  body={this.actionTemplate}
                                />
                              </DataTable>
                            </div>

                            {this.state.imageListP80.length > 0 && (
                              <>
                                <br />
                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                  <h5>{t("uploadedPhotos")}</h5>
                                  <br />

                                  <Galleria
                                    ref={this.state.galleriaP80}
                                    value={this.state.imageListP80}
                                    numVisible={3}
                                    style={{ maxWidth: "850px" }}
                                    activeIndex={this.state.galleriaIndex}
                                    onItemChange={(e) => {
                                      this.setState({
                                        galleriaIndex: e.index,
                                      });
                                    }}
                                    circular
                                    fullScreen
                                    showItemNavigators
                                    showThumbnails={false}
                                    item={this.itemTemplate}
                                    thumbnail={this.thumbnailTemplate}
                                    customContent={
                                      <Button
                                        label="Show Details"
                                        icon="pi pi-external-link"
                                        onClick={() => {
                                          this.setState({
                                            visible: true,
                                          });
                                        }}
                                      />
                                    }
                                    itemStyle={{ maxWidth: "100%" }}
                                    thumbnailStyle={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />

                                  <div
                                    className="grid"
                                    style={{ maxWidth: "400px" }}
                                  >
                                    {this.state.imageListP80 &&
                                      this.state.imageListP80.map(
                                        (image, index) => {
                                          let imgEl = (
                                            <img
                                              src={image.thumbnailImageSrc}
                                              alt={image.alt}
                                              style={{
                                                cursor: "pointer",
                                                width: "80px",
                                                height: "80px",
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  galleriaIndex: index,
                                                });
                                                this.state.galleriaP80.current.show();
                                              }}
                                            />
                                          );
                                          return (
                                            <div className="col-3" key={index}>
                                              {imgEl}
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/* p80Notes */}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p81Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p80Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p80Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p80Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </TabPanel>

                      {/*Phase81*/}
                      <TabPanel header={t("contractor")}>
                        <div
                          className="mb-4"
                          style={{
                            backgroundColor: "#FFFADD",
                            padding: "10px",
                            fontSize: "14px",
                          }}
                        >
                          <div className="root-preline">{t("p82Text")}</div>
                        </div>

                        {/*add new button*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddNewVisitContract")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={() => {
                                this.setState({
                                  addMonitoringVisitDialogVisible: true,
                                  newMonitoringVisit: this.emptyMonitoringVisit,
                                });
                              }}
                            />
                          )}
                        </div>

                        {/*Phase81 data table 1 */}
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.monitoringVisits}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noMVFound")}
                              paginator
                              rows={10}
                              filters={this.state.filters}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "monitoringVisitsAndContacts",
                                "description",
                                "notes",
                                "uploadDate",
                              ]}
                            >
                              <Column
                                body={this.monitoringVisitsAndContactsTemp}
                                header={t("monitoringVisit&Contacts")}
                                sortable
                              ></Column>
                              <Column
                                field="description"
                                header={t("description")}
                                sortable
                              ></Column>

                              <Column
                                field="notes"
                                header={t("notes")}
                                sortable
                              ></Column>

                              <Column
                                header={t("date2")}
                                body={(rowData) =>
                                  this.calendarFormatTemplateTable(
                                    rowData.uploadDate
                                  )
                                }
                              />

                              {!isViewerExpert() && (
                                <Column
                                  header={t("action")}
                                  style={{ textAlign: "center", width: "8em" }}
                                  body={this.actionTemplate2}
                                />
                              )}
                            </DataTable>
                          </div>
                        </div>

                        {/*add document*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        {/*Phase81 data table 2 */}
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p81Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable2}
                              paginator
                              rows={10}
                              filters={this.state.filters2}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>

                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageListP81.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP81}
                                  value={this.state.imageListP81}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP81 &&
                                    this.state.imageListP81.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP81.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </TabPanel>

                      {/*Phase82*/}
                      <TabPanel header={t("contractor")}>
                        <>
                          <div
                            className="mb-4"
                            style={{
                              backgroundColor: "#FFFADD",
                              padding: "10px",
                              fontSize: "14px",
                            }}
                          >
                            <div className="root-preline">{t("p83Text")}</div>
                          </div>

                          <div className="grid">
                            {/*energy Bills (new) Uploaded Header*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("energyBills(new)Uploaded")}
                              </label>
                            </div>

                            {/*energy Bills (new) Uploaded Dropdown*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <Dropdown
                                  id="energyBillsUploaded"
                                  disabled={this.state.nonEditAccess}
                                  value={this.state.project.energyBillsUploaded}
                                  options={YesNoPending}
                                  placeholder={t("pleaseSelect")}
                                  onChange={(e) => {
                                    this.onInputChange(
                                      e,
                                      "energyBillsUploaded"
                                    );
                                    this.uploadDocumentCheck(e);
                                  }}
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(e, "energyBillsUploaded")
                                  }
                                />
                              </div>
                            </div>

                            {/*Energy Performance Certificate (post-works) received Header*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("energyPerformanceCertificateReceived")}
                              </label>
                            </div>

                            {/*Energy Performance Certificate (post-works) received Dropdown*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <Dropdown
                                  id="energyPerformanceCertificateReceived"
                                  disabled={this.state.nonEditAccess}
                                  value={
                                    this.state.project
                                      .energyPerformanceCertificateReceived
                                  }
                                  options={YesNoPending}
                                  placeholder={t("pleaseSelect")}
                                  onChange={(e) => {
                                    this.onInputChange(
                                      e,
                                      "energyPerformanceCertificateReceived"
                                    );
                                    this.uploadDocumentCheck(e);
                                  }}
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(
                                      e,
                                      "energyPerformanceCertificateReceived"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            {/*energyPerformanceClass Header */}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("energyPerformanceClass2")}
                              </label>
                            </div>

                            {/*energyPerformanceClass*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <Dropdown
                                  id="energyPerformanceClass"
                                  disabled={this.state.nonEditAccess}
                                  value={
                                    this.state.project.energyPerformanceClassP8
                                  }
                                  options={EnergyPerformanceClass}
                                  placeholder={t("pleaseSelect")}
                                  onChange={(e) => {
                                    this.onInputChange(
                                      e,
                                      "energyPerformanceClassP8"
                                    );
                                  }}
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(
                                      e,
                                      "energyPerformanceClassP8"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            {/*dividerLine*/}
                            <div className="col-12 md:col-12 lg:col-12">
                              <hr />
                            </div>

                            {/*Existing Energy Consumption*/}
                            <div className="col-12 md:col-12 lg:col-12">
                              <h4>{t("existingEnergyConsumptionPost")}</h4>
                            </div>

                            {/* Annual Gas Consumption (kWh) Header */}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("annualGasConsumption")}
                              </label>
                            </div>

                            {/* Annual Gas Consumption (kWh)*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <InputText
                                  id="p14Notes"
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  fontSize="16px"
                                  value={
                                    this.state.project.annualGasConsumptionP8
                                  }
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "annualGasConsumptionP8"
                                    )
                                  }
                                  maxLength={1000}
                                  className="w-full"
                                />
                              </div>
                            </div>

                            {/*  */}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {""}
                              </label>
                            </div>

                            {/* Annual Gas Consumption Time Period*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div className="card flex justify-content-center">
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  id="annualGasConsumptionTimePeriod"
                                  placeholder={t("pleaseEnter")}
                                  value={
                                    this?.state?.annualGasConsumptionTimePeriod
                                  }
                                  onChange={(e) => {
                                    this.onInputChangeCalendar1(
                                      e,
                                      "annualGasConsumptionTimePeriodP8"
                                    );
                                  }}
                                  readOnlyInput={true}
                                  disabled={this.state.nonEditAccess}
                                />
                              </div>
                            </div>

                            {/* Annual Electricity Consumption (kWh) Header */}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("annualElectricityConsumption")}
                              </label>
                            </div>

                            {/* Annual Electricity Consumption (kWh)*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <InputText
                                  id="p14Notes"
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  fontSize="16px"
                                  value={
                                    this.state.project
                                      .annualElectricityConsumptionP8
                                  }
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "annualElectricityConsumptionP8"
                                    )
                                  }
                                  maxLength={1000}
                                  className="w-full"
                                />
                              </div>
                            </div>

                            {/*  */}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {""}
                              </label>
                            </div>

                            {/* Annual Electricity Consumption Time Period*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div className="card flex justify-content-center">
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  id="annualElectricityConsumptionTimePeriod"
                                  placeholder={t("pleaseEnter")}
                                  value={
                                    this?.state
                                      ?.annualElectricityConsumptionTimePeriod
                                  }
                                  onChange={(e) => {
                                    this.onInputChangeCalendar2(
                                      e,
                                      "annualElectricityConsumptionTimePeriodP8"
                                    );
                                  }}
                                  readOnlyInput={true}
                                  disabled={this.state.nonEditAccess}
                                />
                              </div>
                            </div>
                          </div>

                          {/*dividerLine*/}
                          <div className="col-12 md:col-12 lg:col-12">
                            <hr />
                          </div>

                          {/*addDocument82*/}
                          <div className="field col-12 mb-0">
                            {!isViewerExpert() && (
                              <Button
                                style={{
                                  color: "0094A8",
                                  fontSize: "12px",
                                  fontStyle: "Manrope",
                                }}
                                disabled={this.state.nonEditAccess}
                                label={t("btnAddDocument")}
                                className="p-button p-component p-button-outlined p-button-sm"
                                onClick={this.handleAddDocument}
                              />
                            )}
                          </div>

                          {/*Phase82 data table */}
                          <div className="col-12">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                              <DataTable
                                name="dt"
                                size="small"
                                showGridlines
                                selectionMode="single"
                                value={this.state.p82Documents}
                                dataKey="id"
                                stripedRows
                                responsiveLayout="scroll"
                                emptyMessage={t("noDocumentFound")}
                                header={headerTable3}
                                paginator
                                rows={10}
                                filters={this.state.filters3}
                                filterDisplay="menu"
                                loading={this.state.loading}
                                globalFilterFields={[
                                  "fileName",
                                  "fileDescription",
                                ]}
                              >
                                <Column
                                  field="fileName"
                                  header={t("documentName")}
                                  sortable
                                ></Column>

                                <Column
                                  field="fileDescription"
                                  header={t("fileDescription")}
                                  sortable
                                ></Column>

                                <Column
                                  header={t("action")}
                                  style={{ textAlign: "center", width: "8em" }}
                                  body={this.actionTemplate}
                                />
                              </DataTable>
                            </div>

                            {this.state.imageListP82.length > 0 && (
                              <>
                                <br />
                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                  <h5>{t("uploadedPhotos")}</h5>
                                  <br />

                                  <Galleria
                                    ref={this.state.galleriaP82}
                                    value={this.state.imageListP82}
                                    numVisible={3}
                                    style={{ maxWidth: "850px" }}
                                    activeIndex={this.state.galleriaIndex}
                                    onItemChange={(e) => {
                                      this.setState({
                                        galleriaIndex: e.index,
                                      });
                                    }}
                                    circular
                                    fullScreen
                                    showItemNavigators
                                    showThumbnails={false}
                                    item={this.itemTemplate}
                                    thumbnail={this.thumbnailTemplate}
                                    customContent={
                                      <Button
                                        label="Show Details"
                                        icon="pi pi-external-link"
                                        onClick={() => {
                                          this.setState({
                                            visible: true,
                                          });
                                        }}
                                      />
                                    }
                                    itemStyle={{ maxWidth: "100%" }}
                                    thumbnailStyle={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />

                                  <div
                                    className="grid"
                                    style={{ maxWidth: "400px" }}
                                  >
                                    {this.state.imageListP82 &&
                                      this.state.imageListP82.map(
                                        (image, index) => {
                                          let imgEl = (
                                            <img
                                              src={image.thumbnailImageSrc}
                                              alt={image.alt}
                                              style={{
                                                cursor: "pointer",
                                                width: "80px",
                                                height: "80px",
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  galleriaIndex: index,
                                                });
                                                this.state.galleriaP82.current.show();
                                              }}
                                            />
                                          );
                                          return (
                                            <div className="col-3" key={index}>
                                              {imgEl}
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          {/*dividerLine*/}
                          <div className="col-12 md:col-12 lg:col-12">
                            <hr />
                          </div>

                          {/*addDocumentLink*/}
                          <div className="field col-12 mb-0">
                            {!isViewerExpert() && (
                              <Button
                                style={{
                                  color: "0094A8",
                                  fontSize: "12px",
                                  fontStyle: "Manrope",
                                }}
                                disabled={this.state.nonEditAccess}
                                label={t("btnAddDocumentLink")}
                                className="p-button p-component p-button-outlined p-button-sm"
                                onClick={this.handleAddDocumentLink}
                              />
                            )}
                          </div>

                          <div className="col-12">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                              <DataTable
                                name="dt"
                                size="small"
                                showGridlines
                                value={this.state.documentLinkData}
                                dataKey="id"
                                stripedRows
                                responsiveLayout="scroll"
                                emptyMessage={t("noDocumentLinkFound")}
                                header={headerTable4}
                                paginator
                                rows={10}
                                filters={this.state.filters4}
                                filterDisplay="menu"
                                loading={this.state.loading}
                                globalFilterFields={[
                                  "fileName",
                                  "fileDescription",
                                  "documentDate",
                                ]}
                              >
                                <Column
                                  field="documentName"
                                  header={t("documentName")}
                                  sortable
                                ></Column>
                                <Column
                                  field="fileDescription"
                                  header={t("fileDescription")}
                                  sortable
                                ></Column>
                                <Column
                                  body={this.dateTableTemplate}
                                  header={t("date")}
                                  sortable
                                ></Column>

                                <Column
                                  body={this.documentURLTemplate}
                                  header={t("documentURL")}
                                  sortable
                                ></Column>

                                <Column
                                  header={t("action")}
                                  style={{ textAlign: "center", width: "8em" }}
                                  body={this.actionTemplate3}
                                />
                              </DataTable>
                            </div>
                          </div>
                        </>
                      </TabPanel>
                    </TabView>
                  </div>
                  <br />
                  <div
                    class="btn-toolbar justify-content-center mb-3"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div class="btn-group" role="group"  aria-label="First group">
                      <div class="input-group-prepend">
                        {this.state.activeIndex === 0 && (
                          
                          <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                            marginRight :"10px",
                          }}
                          label={t("prevPhase")}
                          className="p-button-sm p-button-mb-prev"
                          onClick={() => this.prevPhase()}
                        ></Button>
                        )} 

                        {this.state.activeIndex > 0 && ( 
                          <Button
                          style={{ fontSize: "14px", 
                            fontStyle: "Manrope" , 
                            marginRight :"10px",
                            width:"92px"
                          }}
                          label={t("previous")}
                          className="p-button-sm p-button-mb-prev"
                          onClick={() => this.stepPrev()}
                        />
                        )}                       
                        
                        {!isViewerExpert() && (
                          <Button
                            disabled={!this.state.saveButtonStatus}
                            style={{ fontSize: "14px", fontStyle: "Manrope", width:"92px" }}
                            label={
                              this.state.activeIndex < this.stepItems.length - 1
                                ? t("save")
                                : t("save")
                            }
                            className="p-button-sm p-button-mb-saveNext"
                            onClick={() => this.stepSaveAndNext()}
                          />
                        )}

                        {this.state.activeIndex < this.stepItems.length - 1  && (
                           <Button
                           style={{ fontSize: "14px", fontStyle: "Manrope", marginLeft :"10px", width:"92px" }}
                           label={t("next")}
                           className="p-button-sm p-button-mb-next"
                           onClick={() => {
                             this.stepNext();
                           }}
                         />
                        )}

                        {this.state.activeIndex === this.stepItems.length - 1  && (
                           <Button
                           style={{
                             fontSize: "14px",
                             fontStyle: "Manrope",
                              marginLeft :"10px",
                           }}
                           iconPos="right"
                           label={t("nextPhase")}
                           className="p-button-sm p-button-mb-next"
                           onClick={() => this.nextPhase()}
                         ></Button>
                        )}
                       
                      </div>
                    </div>

               </div>
                </div>
                <br />
                {/*add document dialog */}
                <Dialog
                  visible={this.state.addDocumentDialogVisible}
                  style={{ width: "650px" }}
                  header={t("addDocument")}
                  modal
                  className="p-fluid"
                  footer={addDocumentFooter}
                  onHide={this.hideDocumentDialog}
                >
                  <DocumentUpload
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                    documents={this.state.documents}
                    setDocuments={this.setDocuments}
                    selectedDocument={this.state.selectedDocument}
                    setSelectedDocument={this.setSelectedDocument}
                    setSaveButtonVisible={this.setSaveButtonVisible}
                    uploadType={"D"}
                    isMulti={true}
                    isRequired={this.state.isRequiredDocumentCompliance}
                    phase={"P8" + this.state.activeIndex}
                    pid={this.state.pid}
                  />
                </Dialog>
                {/* addDocumentLink dialogu */}
                <Dialog
                  visible={this.state.addDocumentLinkDialogVisible}
                  style={{ width: "450px" }}
                  header={t("document")}
                  modal
                  className="p-fluid"
                  footer={addDocumentLinkDialogFooter}
                  onHide={this.hideAddDocumentLinkDialog}
                >
                  <div className="p-float-label">
                    <InputText
                      id="documentName1"
                      value={this.state.newDocumentLink.documentName}
                      onChange={(e) =>
                        this.onInputChangeDocLink(e, "documentName")
                      }
                      maxLength={50}
                      className="p-inputtext block mb-2"
                    />
                    <label htmlFor="documentName1" className="platformColor">
                      {t("documentName")} (*)
                    </label>
                  </div>

                  <div className="p-float-label">
                    <InputText
                      id="documentURL1"
                      value={this.state.newDocumentLink.documentURL}
                      onChange={(e) =>
                        this.onInputChangeDocLink(e, "documentURL")
                      }
                      maxLength={500}
                      className="p-inputtext block mb-2"
                    />
                    <label htmlFor="documentURL1" className="platformColor">
                      {t("documentURL")} (*)
                    </label>
                  </div>

                  <div className="p-float-label">
                    <InputTextarea
                      id="ad2"
                      value={this.state.newDocumentLink.fileDescription}
                      onChange={(e) =>
                        this.onInputChangeDocLink(e, "fileDescription")
                      }
                      rows={3}
                      cols={20}
                      maxLength={100}
                      className="w-full"
                    />
                    <label htmlFor="ad2">{t("fileDescription")} (*)</label>
                  </div>
                </Dialog>
                {/*add monitoring visit dialog */}
                <Dialog
                  visible={this.state.addMonitoringVisitDialogVisible}
                  style={{ width: "700px" }}
                  modal
                  header={t("addMonitoringVisitDialogHeader")}
                  className="p-fluid"
                  onHide={() => {
                    this.setState({ addMonitoringVisitDialogVisible: false });
                  }}
                >
                  <div className="mb-1">
                    <label
                      htmlFor="monitoringVisits"
                      className="platformColor"
                      style={{ fontSize: "14px", fontStyle: "Roboto" }}
                    >
                      {t("contactType")} (*)
                    </label>
                    <div className="mb-2">
                      <Dropdown
                        id="monitoringVisitsAndContacts"
                        options={contactType}
                        value={
                          this.state.newMonitoringVisit
                            .monitoringVisitsAndContacts
                        }
                        placeholder={t("pleaseSelect")}
                        onChange={(e) =>
                          this.onInputChangeMonitoringVisit(
                            e,
                            "monitoringVisitsAndContacts"
                          )
                        }
                        optionLabel="name"
                        optionValue="code"
                        className="w-full"
                      />
                    </div>
                  </div>

                  <div className="p-float w-full mb-1">
                    <label
                      className="platformColor"
                      htmlFor="monitoringVisitsDescription"
                    >
                      {t("description")} (*)
                    </label>

                    <InputText
                      id="monitoringVisitsDescription"
                      onChange={(e) => {
                        this.onInputChangeMonitoringVisit(e, "description");
                      }}
                      maxLength={100}
                      className="w-full p-inputtext-sm block mb-3"
                      placeholder={t("pleaseEnter")}
                      value={this.state.newMonitoringVisit.description}
                    />
                  </div>

                  <div className="p-float w-full mb-1">
                    <label
                      className="platformColor"
                      htmlFor="monitoringVisitsDescription"
                    >
                      {t("notes")}
                    </label>

                    <InputText
                      id="monitoringVisitsDescription"
                      onChange={(e) => {
                        this.onInputChangeMonitoringVisit(e, "notes");
                      }}
                      maxLength={100}
                      className="w-full p-inputtext-sm block mb-3"
                      placeholder={t("pleaseEnter")}
                      value={this.state.newMonitoringVisit.notes}
                    />
                  </div>

                  <div className="p-float-label w-full">
                    <Calendar
                      id="uploadDate"
                      dateFormat="dd/mm/yy"
                      placeholder={t("pleaseEnter")}
                      value={this.state.newMonitoringVisit.uploadDate}
                      onChange={(e) =>
                        this.onInputChangeMonitoringVisit(e, "uploadDate")
                      }
                      maxLength={10}
                      readOnlyInput={true}
                      disabled={this.state.nonEditAccess}
                    />
                    <label className="platformColor" htmlFor="date">
                      {t("date2")} (*)
                    </label>
                  </div>
                  <br />
                  <div
                    className="flex justify-content-end px-2"
                    id="dialogButton"
                  >
                    <Button
                      label={t("save")}
                      style={{ width: "20%" }}
                      className="p-button-mb"
                      onClick={(e) => {
                        this.addMonitoringVisit();
                      }}
                    />
                  </div>
                </Dialog>
                {/*update monitoring visits dialog */}
                <Dialog
                  visible={this.state.updateMonitoringVisitDialogVisible}
                  style={{ width: "450px" }}
                  header={t("updateMonitoringVisit")}
                  modal
                  className="p-fluid"
                  footer={this.updateMonitoringVisitDialogFooter}
                  onHide={this.hideUpdateMonitoringVisitDialog}
                >
                  <div className="mb-2"></div>

                  <div className="p-float-label">
                    <Dropdown
                      id="monitoringVisitsAndContacts"
                      value={
                        this.state.newMonitoringVisit
                          .monitoringVisitsAndContacts
                      }
                      options={contactType}
                      onChange={(e) =>
                        this.onInputChangeMonitoringVisit(
                          e,
                          "monitoringVisitsAndContacts"
                        )
                      }
                      className="p-inputtext-sm"
                      optionLabel="name"
                      optionValue="code"
                    />
                    <label
                      htmlFor="monitoringVisitsAndContacts"
                      className="platformColor"
                    >
                      {t("contactType")} (*)
                    </label>
                  </div>

                  <div className="p-float-label mb-3">
                    <InputText
                      id="description"
                      value={this.state.newMonitoringVisit.description}
                      onChange={(e) =>
                        this.onInputChangeMonitoringVisit(e, "description")
                      }
                      maxLength={100}
                      className="p-inputtext-sm block mb-3"
                    />

                    <label htmlFor="description" className="platformColor">
                      {t("description")} (*)
                    </label>
                  </div>

                  <div className="p-float w-full mb-1">
                    <label
                      className="platformColor"
                      htmlFor="monitoringVisitsDescription"
                    >
                      {t("notes")}
                    </label>

                    <InputText
                      id="monitoringVisitsDescription"
                      onChange={(e) => {
                        this.onInputChangeMonitoringVisit(e, "notes");
                      }}
                      maxLength={100}
                      className="w-full p-inputtext-sm block mb-3"
                      placeholder={t("pleaseEnter")}
                      value={this.state.newMonitoringVisit.notes}
                    />
                  </div>

                  <div className="p-float-label mb-3">
                    <Calendar
                      id="uploadDate"
                      dateFormat="dd/mm/yy"
                      placeholder={t("pleaseEnter")}
                      value={this.calendarFormatTemplate2(
                        this.state.newMonitoringVisit.uploadDate
                      )}
                      onChange={(e) =>
                        this.onInputChangeMonitoringVisit(e, "uploadDate")
                      }
                      maxLength={10}
                      readOnlyInput={true}
                      disabled={this.state.nonEditAccess}
                    />

                    <label htmlFor="uploadDate" className="platformColor">
                      {t("date")} (*)
                    </label>
                  </div>
                </Dialog>
                <Dialog
                  visible={this.state.pdfVisible}
                  style={{ width: "%100", height: "%100" }}
                  modal
                  className="p-fluid"
                  onHide={() => this.setState({ pdfVisible: false })}
                >
                  {this.state.documentUrl.endsWith(".png") ||
                  this.state.documentUrl.endsWith(".jpeg") ||
                  this.state.documentUrl.endsWith(".jpg") ? (
                    <Galleria
                      value={this.state.documentUrl}
                      numVisible={5}
                      circular
                      style={{ maxWidth: "640px", borderRadius: "6px" }}
                      showThumbnails={false}
                      item={this.itemTemplatePdf}
                    />
                  ) : this.state.documentUrl.endsWith(".pdf") ? (
                    <PdfViewer pdfUrl={this.state.documentUrl} />
                  ) : (
                    <div>No valid document format found</div>
                  )}
                </Dialog>
                <ConfirmDialog
                  visible={this.state.uploadDocumentCheckConfirmDialog}
                  onHide={() => {
                    this.setState({ uploadDocumentCheckConfirmDialog: false });
                  }}
                  message={t("uploadDocumentCheckText")}
                  header={t("confirm")}
                  icon="pi pi-exclamation-triangle icon-orange"
                  accept={this.acceptUploadDocumentCheck}
                  reject={this.acceptUploadDocumentCheck}
                  acceptLabel={t("okey")}
                  rejectLabel={t("cancel")}
                />
                <ConfirmDialog
                  visible={this.state.deleteDocumentConfirmDialogVisible}
                  onHide={() => {
                    this.setState({
                      deleteDocumentConfirmDialogVisible: false,
                    });
                  }}
                  message={t("confirmMessage")}
                  header={t("confirm")}
                  icon="pi pi-exclamation-triangle"
                  accept={this.acceptDeleteDocument}
                  reject={this.rejectDeleteDocument}
                />
                <ConfirmDialog
                  visible={this.state.deleteDocumentLinkConfirmDialogVisible}
                  onHide={() => {
                    this.setState({
                      deleteDocumentLinkConfirmDialogVisible: false,
                    });
                  }}
                  message={t("confirmMessage")}
                  header={t("confirm")}
                  icon="pi pi-exclamation-triangle"
                  accept={this.acceptDeleteDocumentLink}
                  reject={this.rejectDeleteDocument}
                />

               <ConfirmDialog
                visible={this.state.saveButtonConfirmDialog}
                onHide={() => {
                  this.setState({ saveButtonConfirmDialog: false });
                }}
                message={t("saveButtonConfirmText")}
                header={t("confirm")}
                accept={this.acceptSaveForNextButton}
                reject={this.rejectForNext}
                acceptLabel={t("btnSaveAndCon")}
                rejectLabel={t("btnDiscard")}
                acceptClassName="p-button-mb-saveNext"
                rejectClassName="p-button-mb-gray"
              />

               <ConfirmDialog
                visible={this.state.saveButtonPrevConfirmDialog}
                onHide={() => {
                  this.setState({ saveButtonPrevConfirmDialog: false });
                }}
                message={t("saveButtonConfirmText")}
                header={t("confirm")}
                accept={this.acceptSaveForPrevButton}
                reject={this.rejectForPrev}
                acceptLabel={t("btnSaveAndCon")}
                rejectLabel={t("btnDiscard")}
                acceptClassName="p-button-mb-saveNext"
                rejectClassName="p-button-mb-gray"
              />

<ConfirmDialog
                visible={this.state.nextPhaseButtonConfirmDialog}
                onHide={() => {
                  this.setState({ nextPhaseButtonConfirmDialog: false });
                }}
                message={t("saveButtonConfirmText")}
                header={t("confirm")}
                accept={this.acceptForNextPhaseButton}
                reject={this.rejectForNextPhaseButton}
                acceptLabel={t("btnSaveAndCon")}
                rejectLabel={t("btnDiscard")}
                acceptClassName="p-button-mb-saveNext"
                rejectClassName="p-button-mb-gray"
              />

              <ConfirmDialog
                visible={this.state.prevPhaseButtonConfirmDialog}
                onHide={() => {
                  this.setState({ prevPhaseButtonConfirmDialog: false });
                }}
                message={t("saveButtonConfirmText")}
                header={t("confirm")}
                accept={this.acceptForPrevPhaseButton}
                reject={this.rejectForPrevPhaseButton}
                acceptLabel={t("btnSaveAndCon")}
                rejectLabel={t("btnDiscard")}
                acceptClassName="p-button-mb-saveNext"
                rejectClassName="p-button-mb-gray"
              />



                <SidebarComponent
                  visible={this.state.visibleCustomSidebar}
                  project={this.state.project}
                  setState={this.setState.bind(this)}
                  lang={t}
                />
                <WaitDialog loading={this.state.loading} lang={t} />
                <WaitDialogDocument
                  loading={this.state.documentLoading}
                  lang={t}
                />
                <ToastContainer
                  autoClose={2200}
                  position={toast.POSITION.TOP_CENTER}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick={true}
                  rtl={false}
                  theme="colored"
                  pauseOnFocusLoss={true}
                  draggable={false}
                  pauseOnHover={true}
                />
              </>
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(PostCompletion));
