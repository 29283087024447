import React from "react";
import { Link } from "react-router-dom";
import { Chip } from "primereact/chip";
import { Sidebar } from "primereact/sidebar";
import { withTranslation } from "react-i18next";
import { isDevelopment } from "./functions";

const SidebarComponent = ({ visible, project, setState, t }) => {
  return (
    <Sidebar
      visible={visible}
      onHide={() => {
        setState({ visibleCustomSidebar: false });
      }}
    >
      <>
        <h3>Project Phases</h3>
        <br />
        <Link
          to={{ pathname: "/pi", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 1: " + t("phase1Name")}
            icon="pi pi-id-card"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link
          to={{ pathname: "/fs", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 2: " + t("phase2Name")}
            icon="pi pi-book"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link
          to={{ pathname: "/mortgageProcess", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 3: " + t("phase3Name")}
            icon="pi pi-tag"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link
          to={{ pathname: "/contractManagement", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 4: " + t("phase4Name")}
            icon="pi pi-briefcase"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link
          to={{ pathname: "/movingAndTemporaryRelocation", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 5: " + t("phase5Name")}
            icon="pi pi-car"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <div style={{ display: isDevelopment() ? "" : "none" }}>
          <Link
            to={{ pathname: "/financialManagement", state: project }}
            style={{ textDecoration: "none" }}
          >
            <Chip
              style={{ fontSize: "13px", fontStyle: "Manrope" }}
              label={t("phase") + " 6: " + t("phase6Name")}
              icon="pi pi-money-bill"
              className="mr-2 mb-2 custom-chip w-full"
            />
          </Link>
        </div>

        <Link
          to={{ pathname: "/practicalCompletion", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 7: " + t("phase7Name")}
            icon="pi pi-folder-open"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <div style={{ display: isDevelopment() ? "" : "none" }}>
          <Link
            to={{ pathname: "/postCompletion", state: project }}
            style={{ textDecoration: "none" }}
          >
            <Chip
              style={{ fontSize: "13px", fontStyle: "Manrope" }}
              label={t("phase") + " 8: " + t("phase8Name")}
              icon="pi pi-check-circle"
              className="mr-2 mb-2 custom-chip w-full"
            />
          </Link>
        </div>

        <Link
          to={{ pathname: "/indoorQE", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            style={{ fontSize: "13px", fontStyle: "Manrope" }}
            label={t("phase") + " 9: " + t("phase9Name")}
            icon="pi pi-globe"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>
      </>
    </Sidebar>
  );
};

export default withTranslation()(SidebarComponent);
