export default function authTracidHeader() {
  
  const ttoken = JSON.parse(localStorage.getItem("tracid_token"));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (ttoken && ttoken.token) {
    return { ...headers, Authorization: "Bearer " + ttoken.token };
  } else {
    return {};
  }
}
