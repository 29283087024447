const statusReason = [
    { name: "Not a homeowner", code: "1" },
    { name: "Age of homeowner", code: "2" },
    { name: "Property location", code: "3" },
    { name: "Title", code: "4" },
    { name: "Listed building/conservation area", code: "5" },
    { name: "Existing mortgage", code: "6" },
    { name: "Homeowner decided not to proceed", code: "7" },
]

export default statusReason;
 