import React, { useState } from "react";

import SysService from "../services/sys.service";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { withTranslation, Trans } from "react-i18next";
import { handleDownloadDocument } from "./functions";
import { toast } from "react-toastify";

import WaitDialog from "./WaitDialog";

const DocumentUpload = ({
  t,
  documents,
  setDocuments,
  selectedDocument,
  setSelectedDocument,
  loading,
  setLoading,
  uploadType,
  isMulti,
  isRequired,
  phase,
  pid,
}) => {
  const emptyNewDocument = {
    documentDate: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    uploadURL: "",
    description: "",
    imphasegRatio: "",
  };

  const [newDocument, setNewDocument] = useState(emptyNewDocument);
  const [documentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [inputFile, setInputFile] = useState("");
  let s3Response = "",
    tbuFile = "";

  const handleDeleteDocument = (rowData) => {
    const index = documents.indexOf(rowData);
    let newDocuments = [...documents];
    if (index > -1) {
      newDocuments.splice(index, 1);
      setDocuments(newDocuments);
    }
    setNewDocument(emptyNewDocument);
  };

  const put2S3 = () => {
    //setDocumentLoading(true);

    SysService.putFile2S3URL(s3Response.uploadURL, tbuFile).then(
      (response) => {
        const newDocObj = {
          documentDate: s3Response.documentDate,
          uploadURL: s3Response.uploadURL,
          fileName: s3Response.fileName,
          documentName: s3Response.documentName,
          documentURL: s3Response.documentURL,
          description: newDocument.description,
        };

        setNewDocument(emptyNewDocument);

        let newDocuments = [];
        if (isMulti) {
          newDocuments = [...documents, newDocObj];
        } else {
          newDocuments = [newDocObj];
        }

        setDocuments(newDocuments);
        setDocumentUploadLoading(false);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  const myDocumentUploader = () => {
    const filesX = inputFile;
    const fileX = filesX[0];

    if (
      filesX === null ||
      filesX === undefined ||
      fileX === null ||
      fileX === undefined
    ) {
      setLoading(false);

      toast.warning(<Trans i18nKey="check_please_select_file" />);

      return;
    }
    if (newDocument.description === null || newDocument.description === "") {
      setLoading(false);

      toast.warning(<Trans i18nKey="check_please_enter_desc" />);

      return;
    }
    const maxAllowedSize = 10 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      setLoading(false);

      toast.warning(<Trans i18nKey="check_file_size_1mb" />);

      return;
    }

    const allowedExtensions = [".pdf", ".png", ".jpeg", ".jpg"];
    const fileNameLower = fileX.name.toLowerCase();
    const isAllowedFileType = allowedExtensions.some((extension) =>
      fileNameLower.endsWith(extension)
    );

    if (!isAllowedFileType) {
      setLoading(false);
      toast.warning(<Trans i18nKey="check_file_type" />);
      return;
    }

    setDocumentUploadLoading(true);

    SysService.getS3URL(fileX.name, phase, pid, 0).then(
      (response) => {
        s3Response = response.data;
        tbuFile = fileX;

        put2S3();
      },
      (error) => {
        setLoading(false);

        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    setInputFile("");
  };

  const actionTemplateDocument = (data, props) => {
    return (
      <div>
        <Button
          icon="pi pi-download"
          href
          tooltip={t("download")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
          id="buttonFontWeight"
        />
        <Button
          icon="pi pi-trash"
          tooltip={t("delete")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDeleteDocument(data, props);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };

  const onSelectFile = (e) => {
    const filesX = e.target.files;
    setInputFile(filesX);
    const fileX = filesX[0];

    const allowedExtensions = [".pdf", ".png", ".jpeg", ".jpg"];
    const fileNameLower = fileX.name.toLowerCase();
    const isAllowedFileType = allowedExtensions.some((extension) =>
      fileNameLower.endsWith(extension)
    );

    const maxAllowedSize = 10 * 1024 * 1024;
    if (fileX.size > maxAllowedSize) {
      setLoading(false);

      toast.warning(<Trans i18nKey="check_file_size" />);

      return;
    }

    if (!isAllowedFileType) {
      setLoading(false);
      toast.warning(<Trans i18nKey="check_file_type" />);
      return;
    }

    setNewDocument({ ...newDocument, documentName: fileX.name });
  };

  return (
    <>
      <div className="flex flex-column gap-3 mb-2">
        <div className="flex flex-column gap-3">
          <div className="mb-2">
            <label
              style={{
                border: "1px solid #ccc",
                display: "inline-block",
                padding: "12px 14px",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundColor: "#8C83B2",
              }}
            >
              <input
                id="fupload"
                type="file"
                accept=".pdf, image/*"
                onChange={(e) => onSelectFile(e)}
                style={{ display: "none", width: "20%" }}
              />

              <span style={{ fontSize: "14px", color: "#fff" }}>
                <i style={{ fontSize: "18px" }} class="pi pi-cloud-upload"></i>{" "}
                {t("chooseFile")}
              </span>
            </label>
            {newDocument.documentName !== "" ? (
              <span style={{ marginLeft: "7px" }}>
                {newDocument.documentName}
              </span>
            ) : (
              <span className="primaryColor" style={{ marginLeft: "7px" }}>
                {t("noFileChosen")}
              </span>
            )}
          </div>
          <div className="p-float-label">
            <InputTextarea
              id="ad2"
              value={newDocument.description}
              onChange={(e) =>
                setNewDocument({ ...newDocument, description: e.target.value })
              }
              rows={3}
              cols={20}
              maxLength={800}
              className="w-full"
            />
            <label htmlFor="ad2">
              {t("fileDescription")} {isRequired ? "(*)" : ""}{" "}
            </label>
          </div>
          <div className="flex justify-content-start">
            <Button
              className="p-button-mb"
              label={t("add")}
              style={{ width: "20%" }}
              onClick={myDocumentUploader}
              id="buttonFontWeight"
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dtDoc"
              size="small"
              showGridlines
              selectionMode="single"
              value={documents}
              selection={selectedDocument}
              onSelectionChange={(e) => setSelectedDocument(e.value)}
              onRowClick={(e) => setSelectedDocument(e.value)}
              dataKey="fileName"
              stripedRows
              responsiveLayout="scroll"
              emptyMessage={t("noDocumentFound")}
              rows={20}
              loading={loading}
            >
              <Column
                field="fileName"
                headerStyle={{
                  backgroundColor: "rgb(248, 214, 189)",
                }}
                header={t("documentName")}
              ></Column>
              <Column
                field="description"
                headerStyle={{
                  backgroundColor: "rgb(248, 214, 189)",
                }}
                header={t("description")}
              ></Column>
              <Column
                header={t("action")}
                style={{ textAlign: "center", width: "8em" }}
                headerStyle={{
                  backgroundColor: "rgb(248, 214, 189)",
                }}
                body={actionTemplateDocument}
              />
            </DataTable>
          </div>
        </div>
      </div>

      <WaitDialog loading={documentUploadLoading} lang={t} />
    </>
  );
};

export default withTranslation()(DocumentUpload);
