import {
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_FAIL,
  CHANGE_ROLE_SUCCESS,
  CHANGE_ROLE_FAIL,
  CHANGE_PROJECT_SUCCESS,
  CHANGE_PROJECT_FAIL,
  ADD_NEW_PROJECT_SUCCESS,
  ADD_NEW_PROJECT_FAIL,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  UPDATE_PROJECT_STATUS_SUCCESS,
  UPDATE_PROJECT_STATUS_FAIL,
  ARCHIVE_PROJECT_SUCCESS,
  ARCHIVE_PROJECT_FAIL,
  ADD_CLIENT_LOG_SUCCESS,
  ADD_CLIENT_LOG_FAIL,
  SET_MESSAGE,
} from "./types";

import SysService from "../services/sys.service";

export const changePassword =
  (username, oldPassword, newPassword) => (dispatch) => {
    return SysService.changePassword(username, oldPassword, newPassword).then(
      (response) => {
        dispatch({
          type: CHANGE_PASS_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: CHANGE_PASS_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const changeRole = (username, rolename) => (dispatch) => {
  return SysService.changeRole(username, rolename).then(
    (response) => {
      dispatch({
        type: CHANGE_ROLE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHANGE_ROLE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const changeProject = (username, projects) => (dispatch) => {
  return SysService.changeProject(username, projects).then(
    (response) => {
      dispatch({
        type: CHANGE_PROJECT_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHANGE_PROJECT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const addNewUser =
  (username, firstname, lastname, role) => (dispatch) => {
    return SysService.addNewUser(username, firstname, lastname, role).then(
      (response) => {
        dispatch({
          type: ADD_NEW_USER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_NEW_USER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const updateUser =
  (username, firstname, lastname, timeLogout) => (dispatch) => {
    return SysService.updateUser(
      username,
      firstname,
      lastname,
      timeLogout
    ).then(
      (response) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_USER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const updatePlatformUser =
  (username, firstname, lastname, role) => (dispatch) => {

    return SysService.updatePlatformUser(
      username,
      firstname,
      lastname,
      role
    ).then(
      (response) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_USER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const addNewProject = (newProject) => (dispatch) => {
  return SysService.addNewProject(newProject).then(
    (response) => {
      dispatch({
        type: ADD_NEW_PROJECT_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ADD_NEW_PROJECT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteProject = (id) => (dispatch) => {
  return SysService.deleteProject(id).then(
    (response) => {
      dispatch({
        type: DELETE_PROJECT_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_PROJECT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateProjectStatus = (newProject) => (dispatch) => {
  return SysService.updateProjectStatus(newProject).then(
    (response) => {
      dispatch({
        type: UPDATE_PROJECT_STATUS_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_PROJECT_STATUS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const archiveProject = (newProject) => (dispatch) => {
  return SysService.archiveProject(newProject).then(
    (response) => {
      dispatch({
        type: ARCHIVE_PROJECT_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ARCHIVE_PROJECT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const addClientLog =
  (contactType, description, notes, uploadDate, projectId) => (dispatch) => {
    return SysService.addClientLog(
      contactType,
      description,
      notes,
      uploadDate,
      projectId
    ).then(
      (response) => {
        dispatch({
          type: ADD_CLIENT_LOG_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_CLIENT_LOG_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
