import "bootstrap/dist/css/bootstrap.min.css";
import "./resources/main.css";

import React, { Component } from "react";
import { BrowserRouter, Router, Switch } from "react-router-dom";

import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import PrivateRoute from "./common/PrivateRoute";
import PublicRoute from "./common/PublicRoute";
import WaitDialog from "./common/WaitDialog";

import Login from "./components/login";
import ForgotPassword from "./components/forgotPassword";
import ChangePassword from "./components/changePassword";
import SetNewPassword from "./components/setNewPassword";
import VerifyEmail from "./components/verifyEmail";
import ResendMail from "./components/resendMail";
import Index from "./components/index";
import PageNotFound from "./components/404";
import UnauthorizedPage from "./components/401";
import ForbiddenPage from "./components/403";
import Home from "./components/home";
import Profile from "./components/profile";
import PlatformUsers from "./components/platformUsers";
import Users from "./components/users";
import Projects from "./components/projects";
import Project from "./components/project";
import PI from "./components/pi";
import IndoorQE from "./components/indoorQE";
import FS from "./components/fs";
import ContractManagement from "./components/contractManagement";
import PracticalCompletion from "./components/practicalCompletion";

import FinancialManagement from "./components/financialManagement";
import PostCompletion from "./components/postCompletion";

import MortgageProcess from "./components/mortgageProcess";
import MovingAndTemporaryRelocation from "./components/movingAndTemporaryRelocation";
import { Menubar } from "primereact/menubar";
import { clearMessage } from "./actions/message";
import { connect } from "react-redux";

import { history } from "./common/history";
import {
  isAdmin,
  isPlatformManager,
  isProjectManager,
  isSupervisor,
  isProjectExpert,
  isViewerExpert,
} from "./common/token-check";
import jwt_decode from "jwt-decode";
import { logout } from "./actions/auth";
import { isLogin } from "./common/token-check";

import { withTranslation } from "react-i18next";

import LogoutTimer from "./common/logoutTimer";
import Tasks from "./components/tasks";

class App extends Component {
  constructor(props) {
    super(props);

    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
      laName: "",
    };

    this.menuItems1 = [];

    history.listen((location) => {
      props.dispatch(clearMessage());
    });
  }

  componentDidMount() {
    const user = this.props.user;

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { t } = this.props;
    const user = this.props.user;

    if (user) {
      const decodedJwt = jwt_decode(user.token);
      user.fullname = decodedJwt.firstName + " " + decodedJwt.lastName;
      user.laName = decodedJwt.laName;
    }

    this.menuItems1 = [
      // {
      //   visible: true,
      //   label: t("language"),
      //   icon: "pi pi-list",
      //   className: "zindex102",
      //   items: [
      //     {
      //       label: "English",
      //       command: () => {
      //         i18n.changeLanguage("en");
      //       },
      //     },
      //     /*
      //     {
      //       label: "Türkçe",
      //       command: () => {
      //         i18n.changeLanguage("tr");
      //       },
      //     }
      //     */
      //   ],
      // },
      {
        label: t("home"),
        visible: true,
        icon: "pi pi-home",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/home",
      },
      {
        label: t("platformUsers"),
        visible: isAdmin(),
        icon: "pi pi-users",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/platformUsers",
      },
      {
        label: t("users"),
        visible: isPlatformManager() || isSupervisor(),
        icon: "pi pi-users",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/users",
      },
      {
        label: t("projects"),
        visible:
          isPlatformManager() ||
          isProjectManager() ||
          isSupervisor() ||
          isProjectExpert() ||
          isViewerExpert(),
        icon: "pi pi-building",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/projects",
      },
      {
        visible: true,
        label: user != null ? user.fullname : "",
        icon: "pi pi-user",
        className: "zindex102",
        items: [
          {
            label: t("profile"),
            icon: "pi pi-fw pi-file",
            command: (e) => {
              this.setState({ loading: true });
              window.location.href = "/profile";
            },
          },
          {
            label: t("changePassword"),
            visible: !isAdmin(),
            icon: "pi pi-fw pi-key",
            command: (e) => {
              this.setState({ loading: true });
              window.location.href = "/changePassword";
            },
          },
          {
            label: t("logout"),
            icon: "pi pi-fw pi-power-off",
            command: (e) => {
              this.setState({ loading: true });
              this.logOut();
              window.location.href = "/";
            },
          },
        ],
      },
    ];

    const start = (
      <>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={() =>
            isLogin()
              ? (window.location.href = "/")
              : (window.location.href = "/")
          }
        >
          <img
            src="/assets/img/lrs-logo.png"
            width="60%"
            alt="HHHL"
            style={{ marginRight: "10px" }}
          />
        </button>
      </>
    );

    const menuItems3 = [
      {
        visible: true,
        label: t("login"),
        icon: "pi pi-user",
        className: "zindex102",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/login",
      },
      // {
      //   visible: true,
      //   label: t("language"),
      //   icon: "pi pi-list",
      //   className: "zindex102",
      //   items: [
      //     {
      //       label: "English",
      //       command: () => {
      //         i18n.changeLanguage("en");
      //       },
      //     },

      //     {
      //       label: "Türkçe",
      //       command: () => {
      //         i18n.changeLanguage("tr");
      //       },
      //     }

      //   ],
      // }
    ];

    return (
      <>
        {isLogin() ? <LogoutTimer /> : <div />}

        <Router history={history}>
          <div>
            {isLogin() ? (
              <>
                <div>
                  <Menubar
                    className="w-full mb-0 p-menubar-mb"
                    model={this.menuItems1.filter((item) => item.visible)}
                    start={start}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <Menubar
                    className="w-full mb-0 p-menubar-mb"
                    model={menuItems3}
                    start={start}
                  />
                </div>
              </>
            )}

            <div className="grid">
              <div className="col-12 md:col-11 lg:col-11 centered">
                <br />
                <BrowserRouter>
                  <Switch>
                    <PrivateRoute component={Profile} path="/profile" exact />
                    <PrivateRoute
                      component={ChangePassword}
                      path="/changePassword"
                      exact
                    />
                    <PrivateRoute component={Home} path="/home" exact />
                    <PrivateRoute
                      component={PlatformUsers}
                      path="/platformUsers"
                      exact
                    />
                    <PrivateRoute component={Users} path="/users" exact />
                    <PrivateRoute component={Projects} path="/projects" exact />
                    <PrivateRoute component={Project} path="/project" exact />
                    <PrivateRoute component={PI} path="/pi" exact />
                    <PrivateRoute component={IndoorQE} path="/indoorQE" exact />
                    <PrivateRoute component={FS} path="/fs" exact />
                    <PrivateRoute
                      component={ContractManagement}
                      path="/contractManagement"
                      exact
                    />
                    <PrivateRoute
                      component={PracticalCompletion}
                      path="/practicalCompletion"
                      exact
                    />

                    <PrivateRoute
                      component={PostCompletion}
                      path="/postCompletion"
                      exact
                    />
                    <PrivateRoute
                      component={FinancialManagement}
                      path="/financialManagement"
                      exact
                    />

                    <PrivateRoute
                      component={MortgageProcess}
                      path="/mortgageProcess"
                      exact
                    />
                    <PrivateRoute
                      component={MovingAndTemporaryRelocation}
                      path="/movingAndTemporaryRelocation"
                      exact
                    />

                    <PrivateRoute
                      component={UnauthorizedPage}
                      path="/401"
                      exact
                    />
                    <PrivateRoute component={ForbiddenPage} path="/403" exact />
                    <PrivateRoute component={PageNotFound} path="/404" exact />
                    <PublicRoute
                      restricted={true}
                      component={Login}
                      path="/login"
                      exact
                    />
                    <PublicRoute
                      restricted={true}
                      component={ForgotPassword}
                      path="/forgotPassword"
                      exact
                    />
                    <PublicRoute
                      restricted={true}
                      component={ResendMail}
                      path="/resendMail"
                      exact
                    />
                    <PublicRoute
                      restricted={true}
                      component={SetNewPassword}
                      path="/setNewPassword"
                      exact
                    />
                    <PublicRoute
                      restricted={false}
                      component={VerifyEmail}
                      path="/verifyEmail"
                      exact
                    />
                    <PublicRoute
                      restricted={false}
                      component={Index}
                      path="/"
                      exact
                    />

                    {/* <PrivateRoute
                      component={TaskManagement}
                      path="/taskManagement"
                      exact
                    /> */}

                    <PrivateRoute component={Tasks} path="/tasks" exact />

                    {/* <PublicRoute component={Passport} path="/passport" exact /> */}
                  </Switch>
                </BrowserRouter>

                <div className="text-center">
                  <br />
                  <br />
                  <span className="text-700 font-medium line-height-3">
                    {t("footerCopyright")}{" "}
                    <img
                      src="/assets/img/ecowise.png"
                      alt="Ecowise"
                      height={30}
                      className="mb-0"
                    />
                    <br />
                  </span>
                  <br />
                  <br />
                </div>
              </div>

              <WaitDialog loading={this.state.loading} lang={t} />
            </div>

            {<AuthVerify logOut={this.logOut} />}
          </div>
        </Router>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default withTranslation()(connect(mapStateToProps)(App));
