import styled from "@emotion/styled";

export const PasswordContainer = styled.div`
  .p-component {
    width: 100%;
  }
`;

export const DialogContainer = styled.div`
  margin-top: 0.6rem;
`;

export const PanelCardDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;
/* -------------------------PROFILE PAGE STYLES-------------------- */
export const ProfileContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .logo-container {
    width: 352px;
    height: 186.08px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #2d416e;
  }

  .divider {
    border: 1px solid #e7e0ec;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .title {
    color: #2d416e;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.13px;
  }
`;
export const OrganisationInfoContainer = styled.div`
  .org-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 820px) {
      flex-direction: column;
    }
  }

  .org-logo-container {
    width: 300px;
    height: 207.09px;
    flex-shrink: 0;
    border-radius: 6px;
    /* border: 1px solid #2d416e; */
    @media (max-width: 820px) {
      width: 100%;
    }
  }

  .org-logo {
    width: 230px;
    height: 109.153px;
    flex-shrink: 0;
    object-fit: contain;
  }

  .org-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 6px;
    background: #f5f5f5;
    padding: 0.7rem;

    .sync-with-gln-button {
      width: 100%;
      height: 36px;
      background: #25c3a7;
      .p-button-label {
        font-weight: 400;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;

export const PersonelContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }

  .personel-info-container {
    display: flex;
    align-items: start;
    gap: 1rem;
    flex: 1;
    /* height: 207.09px; */

    .personel-logo-container {
      width: 300px;
      height: 207.09px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid #2d416e;
      cursor: pointer;
      @media (max-width: 820px) {
        width: 100%;
      }
    }

    .personel-content {
      border-radius: 6px;
      /* background: #f5f5f5; */
      /* padding: 0.7rem; */
      width: 100%;
    }

    @media (max-width: 820px) {
      flex-direction: column;
    }
  }
`;

export const PersonelInfoContainer = styled.div`
  width: 65%;
  .avatar-class {
    border-radius: 50%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  .profile-picture {
    width: 200px;
    height: 100px;
    object-fit: contain;

    @media screen and (min-width: 501px) and (max-width: 561px) {
      width: 200px;
      height: 100px;
    }
  }
`;

export const AccountContainer = styled.div`
  width: 35%;

  @media (max-width: 820px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  .account-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.33rem;
    height: 207.09px;
    /* padding: 0.7rem; */

    .member-info-container {
      flex: 1;
      border-radius: 6px;
      border: 1px solid #2d416e;
      background: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }
    .sync-gs1-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 6px;
      border: 1px solid #25c3a7;
      padding: 0.5rem;
      height: 36.38px;
    }

    .password-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: left;
      color: #2d416e;
    }

    .change-password-button {
      height: 30px;
      background: #f0f0f0;
      color: #2d416e;
      border: 1px solid #2d416e;
      .p-button-label {
        font-weight: 400;
      }
    }

    .green-flag-container {
      @media screen and (min-width: 501px) and (max-width: 610px) {
        display: none;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;

  .save-button {
    width: 65%;
    background-color: #2d416e;
    &:hover {
      background-color: #151e32;
    }
    @media screen and (min-width: 501px) and (max-width: 588px) {
      height: 51px;
    }
    @media (max-width: 820px) {
      width: 50%;
    }

    @media (max-width: 500px) {
      width: 100%;
      height: 51px;
    }
  }
  .cancel-button {
    width: 35%;
    background-color: #979797;
    @media (max-width: 820px) {
      width: 50%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;
/* -------------------------LOG PAGE STYLE-------------------- */
export const LogPageContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: Sofia Sans;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const FilterRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

/* -------------------------HOME PAGE STYLES-------------------- */

export const HomeContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  font-family: Sofia Sans;
  .welcome-title {
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    font-weight: 600;
    color: #2d416e;
  }
  .welcome-text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-weight: 400;
    color: #2d416e;
  }
`;

export const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 3rem;
  padding-top: 7rem;
  padding-bottom: 7rem;

  .logo {
    height: 250px;
    width: auto;
    object-fit: contain;
  }

  .add-product-model-button {
    .p-button {
      background: #2d416e;
      .p-button-label {
        font-weight: normal;
      }
    }
  }
`;

export const HomeContentWithOrganisation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .welcome-title-organisation {
    font-size: 24px;
    line-height: 20px;
    text-align: left;
    font-weight: 600;
    color: #2d416e;
  }
  .welcome-text-organisaiton {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    text-align: left;
    color: #979797;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 1rem;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33.333%, 1fr));
  grid-gap: 1rem;
  min-height: 400px;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const Card = styled.div`
  flex: 1;
  min-width: calc(33.333% - 1rem);
  height: 155px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  text-align: left;
  color: #000;

  .card-title {
    line-height: 190%;
  }

  .card-number {
    font-size: 36px;
    line-height: 190%;
  }

  .card-complete {
    display: flex;
    align-items: center;
    min-height: 21px;
    gap: 2px;
    color: #79747e;
    .card-date {
      color: #25c3a7;
      font-weight: 600;
    }
  }

  .card-icon {
    margin-right: 1.6rem;
    background-color: #d9d9d9;
    padding: 0.5rem;
    border-radius: 8px;
  }
`;
export const MemberContainer = styled.div`
  width: 18.75rem;
  height: 362px;
  border-radius: 6px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  overflow-y: auto;
  .member-title {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    line-height: 26px;
  }
  .member-name {
    color: #2d416e;
  }
  .member-job {
    color: #79747e;
  }

  @media (max-width: 650px) {
    width: 100%;
    overflow-y: hidden;
  }
`;

/* ------------------------------------------------------------------- */

/* -------------------------PRODUCT MODELS PAGE STYLES--------------------------- */

export const ProductModelsContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: Sofia Sans;

  .logo-button-container {
    display: flex;
    align-items: end;
    justify-content: space-between;
    @media (max-width: 550px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }

  .add-product-button {
    width: 200px;
    flex-shrink: 0;
    height: 36px;
    border-radius: 6px;
    background: #25c3a7;
    &:hover {
      background: #17836f;
    }
    @media (max-width: 550px) {
      width: 100%;
    }
  }

  .dpp-button {
    border-radius: 100px;
    background: #8c83b2;
    &:hover {
      background: #7e75a0;
    }
    height: 29px;
    flex-shrink: 0;
    .p-button-label {
      font-weight: 400;
      white-space: nowrap;
    }
  }

  .transition-effect {
    width: 20px;
    height: 20px;
    transition: width 0.5ms, height 0.5ms;

    &:hover {
      width: 25px;
      height: 25px;
      color: #fff;
    }
  }
`;

export const DataTableContainer = styled.div`
  height: 600px;
  flex: 1;
  width: %100;
  display: flex;
  flex-direction: column;

  .add-product-button {
    width: 200px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #25c3a7;
  }

  .data-table-columns {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    background: #2d416e;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 35.59px;
    .col-item:nth-of-type(1) {
      flex: 2;
    }

    .col-item:nth-of-type(2) {
      flex: 2;
      @media (max-width: 650px) {
        display: none;
      }
    }

    .col-item:nth-of-type(3) {
      flex: 2;
      @media (max-width: 875px) {
        flex: 3;
      }
    }

    .col-item:nth-of-type(4) {
      flex: 2;
      @media (max-width: 875px) {
        display: none;
      }
    }

    .col-item:nth-of-type(5) {
      flex: 1;
      @media (max-width: 875px) {
        display: none;
      }
    }

    .col-item:nth-of-type(6) {
      flex: 2;
      @media (max-width: 650px) {
        flex: 3;
      }
    }
    .col-item:nth-of-type(7) {
      flex: 1;
      @media (max-width: 875px) {
        flex: 2;
      }
      @media (max-width: 650px) {
        flex: 3;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 6px;
    overflow-y: auto;
    flex: 1;

    .dpp-button {
      border-radius: 100px;
      background: #8c83b2;
      &:hover {
        background: #7e75a0;
      }
      height: 29px;
      flex-shrink: 0;
      .p-button-label {
        font-weight: 400;
        white-space: nowrap;
      }
    }

    .transition-effect {
      width: 20px;
      height: 20px;
      transition: width 0.5ms, height 0.5ms;

      &:hover {
        width: 25px;
        height: 25px;
        color: #fff;
      }
    }
  }

  .data-table-rows-container {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    color: #2d416e;
    border-radius: 6px;
    background: #f4f4f4;
    max-height: 60px;
    overflow-x: auto;
  }

  .empty-data-table {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 6px;
    background: #f4f4f4;
  }
  .row-item:nth-of-type(1) {
    flex: 2;
  }

  .row-item:nth-of-type(2) {
    flex: 2;
    @media (max-width: 650px) {
      display: none;
    }
  }

  .row-item:nth-of-type(3) {
    flex: 2;
    @media (max-width: 875px) {
      flex: 3;
    }
  }

  .row-item:nth-of-type(4) {
    flex: 2;
    @media (max-width: 875px) {
      display: none;
    }
  }

  .row-item:nth-of-type(5) {
    flex: 1;
    @media (max-width: 875px) {
      display: none;
    }
  }

  .row-item:nth-of-type(6) {
    flex: 2;
    @media (max-width: 650px) {
      flex: 3;
    }
  }

  .row-item:nth-of-type(7) {
    flex: 1;
    @media (max-width: 875px) {
      flex: 2;
    }
    @media (max-width: 650px) {
      flex: 3;
    }
  }
`;

export const ProductModelHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .left-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    color: #2d416e;
    .model-title {
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
    .model-title span {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
    .title {
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
    .add-new-dpp {
      height: 36px;
      background-color: #25c3a7;
      .p-button-label {
        font-weight: 400;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: end;
      @media (max-width: 576px) {
        justify-content: center;
      }
    }
  }
`;

export const ProductModelDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;

  .sync-gs1-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 6px;
    background: #25c3a7;
    color: #fff;
    padding: 0.5rem;
  }

  .section-one {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    color: #2d416e;
  }

  .section-two {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 1.3rem;
    border-radius: 6px;
    background: #f5f5f5;
    color: #2d416e;
    .row-container {
      display: flex;
      align-items: start;
    }
  }

  .section-three {
    .carousel-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10rem;
      padding-right: 10rem;
      min-height: 400px;
      .image-gallery-image {
        max-height: 400px;
      }
    }
    .carousel-image-container {
      padding: 1.3rem;
    }
  }

  .title {
    font-size: 15px;
    font-weight: 600;
  }

  .text {
    font-size: 15px;
    font-weight: 400;
    line-height: 20.5px;
  }

  .text-two {
    font-size: 12px;
    font-weight: 300;
    line-height: 20.5px;
  }

  .border-dash {
    border: 1.5px dashed black;
    padding: 0.5rem;
    padding-bottom: 1rem;
  }
`;

/* ------------------------------------------------------------------- */

/* -------------------------COMPANY PROFILE STYLES-------------------- */

export const CompanyProfileContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CompanyContentContainer = styled.div`
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  padding: 1.5rem;
  @media (min-width: 768px) {
    flex: 1;
  }
`;

export const CompanyCredentialsFirst = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ImageUploadContainer = styled.div`
  flex: 0 0 20%;
  background: #f5f5f5;
  padding: 1.868rem;
  border-radius: 6px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ImageUploadWrapper = styled.div`
  background: #d9d9d9;
  padding: 1.5rem;
  border-radius: 50%;
  @media (max-width: 500px) {
    border-radius: 6px;
  }
`;

export const InfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background: #f5f5f5;
  border-radius: 6px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const CompanyCredentialsSecond = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoSectionWrapper = styled.div`
  display: flex;
  text-align: center;
  gap: 0.5rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const EditorsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;
export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const CommonTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #116182;
  text-align: left;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
`;

export const TagsSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TagItem = styled.span`
  padding: 8px 16px 8px 16px;
  border: 1px solid #116182;
  border-radius: 100px;
  text-align: center;
`;

/* -------------------------CREATE DPP STYLES-------------------- */
export const CreateDPPContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: Sofia Sans;

  .p-tabview-nav-container {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .left-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 2rem;
    margin-left: 1.5rem;
    width: 100%;
    @media (max-width: 500px) {
      align-items: center;
    }
    .title {
      font-family: Inter;
      color: #3d84a8;
      font-size: 16px;
      font-weight: 700;
    }
    .desc {
      color: #2d416e;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: start;
    }
  }

  .middle-part {
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;

    .brand-logo {
      height: 86px;
      width: auto;
      object-fit: contain;
    }
  }

  .right-part {
    display: flex;
    align-items: start;
    justify-content: end;
    gap: 3rem;
    width: 100%;
    @media (max-width: 500px) {
      justify-content: center;
    }
  }
`;

export const BasicInfoSection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 0.5rem;
  @media (max-width: 1232px) {
    flex-direction: column-reverse;
  }
  .infoFirstPart {
    flex: 6;
    border-radius: 6px;
    background: #f5f5f5;
    width: 150%;
    min-height: 305px;
    .row-container {
      display: flex;
      align-items: start;
      text-align: left;
    }
    .title {
      font-size: 12px;
      font-weight: 700;
      margin-left: 2rem;
    }

    .text {
      font-size: 12px;
      font-weight: 700;
      line-height: 27px;
    }

    .text-two {
      font-size: 12px;
      font-weight: 300;
      line-height: 20.5px;
    }
  }

  .infoFirstPartFullWidth {
    flex: 12;
    border-radius: 6px;
    background: #f5f5f5;

    width: 100%;
    min-height: 400px;
    .row-container {
      display: flex;
      align-items: start;
      text-align: left;
    }
    .title {
      font-size: 14px;
      font-weight: 600;
    }

    .text {
      font-size: 15px;
      font-weight: 400;
      line-height: 20.5px;
    }

    .text-two {
      font-size: 12px;
      font-weight: 300;
      line-height: 20.5px;
    }
  }

  .infoSecondPart {
    flex: 6;
    width: 100%;
    display: flex;
    align-items: end;
    gap: 0.5rem;
    .infoImages {
      flex: 1;
      display: flex;
      justify-content: end;
      align-items: end;
      min-height: 400px;
      width: 100%;

      .image-gallery-image {
        max-height: 400px;
      }
    }
  }
`;
export const PanelSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  .p-panel-content {
    background: #f4f4f4;
  }
  .p-panel-header {
    background: #f4f4f4;

    .header-icon {
      width: 35px;
      height: 35px;
    }

    .header-title {
      color: #566587;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

/*-------------PRODUCT PASSPORT MANAGER STYLES----------------- */

export const ProductPassportManagerContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: Sofia Sans;
`;

export const IndividualPassportSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 1rem;
  gap: 1.2rem;

  .p-button {
    height: 35px !important;
    width: 35px;
    background-color: #25c3a7;
  }
`;

export const PassportManagerHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .left-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    color: #2d416e;
    .model-title {
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
    .model-title span {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
    .title {
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
    .add-new-dpp {
      height: 36px;
      background-color: #25c3a7;
      .p-button-label {
        font-weight: 400;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: end;
      @media (max-width: 576px) {
        justify-content: center;
      }
    }
  }
`;

export const PassportManagerTableSection = styled.div`
  .table-filter-section {
    min-height: 150px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #2d416e;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;

    .filter-container {
      display: flex;
      margin-top: 1.2rem;
      color: #fff;
      width: 100%;

      @media (max-width: 576px) {
        gap: 1rem;
      }
      .filter-title {
        flex: 1;
      }

      .filter-items {
        flex: 11;
        width: 100%;
        .filter-item {
          display: inline-flex;
          height: 20px;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 5px;
          flex-shrink: 0;
          border-radius: 16px;
          border: 1px solid #c9d7d3;
        }
      }
    }
  }

  .table-header-section {
    background: #2d416e;
    min-height: 36px;
    border-radius: 6px;
    flex-shrink: 0;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* padding-left: 1rem;
    padding-right: 1rem; */
    color: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;

    .col-item:nth-of-type(1) {
      flex: 2;
      @media (max-width: 700px) {
        display: none;
      }
    }
    .col-item:nth-of-type(2) {
      flex: 2;
    }
    .col-item:nth-of-type(3) {
      flex: 2;
      @media (max-width: 576px) {
        display: none;
      }
    }
    .col-item:nth-of-type(4) {
      flex: 1;
      @media (max-width: 850px) {
        flex: 2;
      }
      @media (max-width: 576px) {
        flex: 3;
      }
    }

    .col-item:nth-of-type(5) {
      flex: 3;
      @media (max-width: 700px) {
        flex: 4;
      }
    }
    .col-item:nth-of-type(6) {
      flex: 2;
      @media (max-width: 850px) {
        flex: 3;
      }
      @media (max-width: 700px) {
        flex: 6;
      }
    }
  }

  .table-content-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 6px;
    overflow-y: auto;
    flex: 1;
    margin-top: 0.5rem;

    .data-table-rows-container {
      flex: 1;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      color: #2d416e;
      border-radius: 6px;
      background: #f4f4f4;
      min-height: 45px;

      .row-item:nth-of-type(1) {
        flex: 2;
        @media (max-width: 700px) {
          display: none;
        }
      }
      .row-item:nth-of-type(2) {
        flex: 2;
      }
      .row-item:nth-of-type(3) {
        flex: 2;
        @media (max-width: 576px) {
          display: none;
        }
      }
      .row-item:nth-of-type(4) {
        flex: 1;
        @media (max-width: 850px) {
          flex: 2;
        }
        @media (max-width: 576px) {
          flex: 3;
        }
      }

      .row-item:nth-of-type(5) {
        flex: 3;
        .action-buttons {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .transition-effect {
            width: 20px;
            height: 20px;
            transition: width 0.5ms, height 0.5ms;

            &:hover {
              width: 25px;
              height: 25px;
              color: #fff;
            }
          }
          @media (max-width: 500px) {
            flex-direction: column;
            gap: 0.7rem;
          }
        }
        @media (max-width: 700px) {
          flex: 4;
        }
      }
      .row-item:nth-of-type(6) {
        flex: 2;
        display: flex;
        justify-content: center;
        @media (max-width: 850px) {
          flex: 3;
        }
        @media (max-width: 700px) {
          flex: 6;
        }
      }
    }

    .empty-data-table {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 6px;
      background: #f4f4f4;
      min-height: 300px;
    }
  }
`;

export const PassportManagerHistorySection = styled.div`
  .table-header-section {
    background: #2d416e;
    min-height: 36px;
    border-radius: 6px;
    flex-shrink: 0;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* padding-left: 1rem;
  padding-right: 1rem; */
    color: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;

    .col-item:nth-of-type(1) {
      flex: 3;
    }
    .col-item:nth-of-type(2) {
      flex: 3;
    }
    .col-item:nth-of-type(3) {
      flex: 3;
    }
    .col-item:nth-of-type(4) {
      flex: 3;
    }
  }

  .table-content-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 6px;
    overflow-y: auto;
    flex: 1;
    margin-top: 0.5rem;

    .data-table-rows-container {
      flex: 1;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      color: #2d416e;
      border-radius: 6px;
      background: #f4f4f4;
      min-height: 45px;

      .row-item:nth-of-type(1) {
        flex: 3;
      }
      .row-item:nth-of-type(2) {
        flex: 3;
      }
      .row-item:nth-of-type(3) {
        flex: 3;
      }
      .row-item:nth-of-type(4) {
        flex: 3;
      }
    }
  }
`;

export const PublishButton = styled.div`
  display: flex;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 120px;
  flex-shrink: 0;
  border-radius: 100px;
  background: ${(props) =>
    props.customBackground ? props.customBackground : "#c98e1c"};
  color: #fff;
  cursor: ${(props) => (props.cursorStatus ? "pointer" : "")};
`;

export const PublishText = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  color: ${(props) => (props.customColor ? props.customColor : "#FFF")};
`;

/*-------------REQUESTS PAGE STYLES----------------- */

export const RequestsContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: Sofia Sans;

  .table-header {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 18px;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #2d416e !important;
    color: #fff !important;
    font-weight: 500 !important;
    .anticon {
      color: #fff;
    }
  }
`;

/*------------------ LANDING PAGE STYLES-------------------- */

export const LandingPageContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
`;

/*------------------ BATCH and INDIVIDUAL Passport Manager PAGE STYLES-------------------- */

export const BatchAndIndividualPassportManagerContainer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Sofia Sans;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: Sofia Sans;
`;

export const BatchAndIndividualPassportManagerHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .left-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    color: #2d416e;
    .model-title {
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
    .model-title span {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
    .title {
      font-size: 15px;
      font-weight: 700;
      text-align: left;
    }
    .add-new-batch {
      height: 36px;
      background-color: #25c3a7;
      .p-button-label {
        font-weight: 400;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: end;
      @media (max-width: 576px) {
        justify-content: center;
      }
    }
  }
`;

export const BAIDialog = styled.div`
  display: flex;
  flex-direction: column;
  .select-text {
    color: #2d416e;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    .add-button {
      width: 100%;
      background-color: #25c3a7;
      color: #fff;
      height: 36px;
    }
    .add-button-batch {
      width: 100%;
      background-color: #8c83b2;
      color: #fff;
      height: 36px;
    }
  }

  .info-text {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    background-color: #d3e3ea;
    border-radius: 6px;
    padding: 10px;
    margin-top: 1rem;
    color: #2d416e;

    .icon {
      font-size: 1.3em;
    }
  }

  .up-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .upload-container {
    display: flex;
    justify-content: start;

    .upload-button {
      width: 30%;
      height: 34px;
      margin-right: 1rem;
      background-color: #8c83b2;
      color: #fff;
    }
  }

  .download-button {
    height: 34px;
    width: 30%;
    border-color: #8c83b2;
    color: #8c83b2;
  }
`;
