const cities = [
  { name: "Aberdeen", code: "Aberdeen" },
  { name: "Armagh", code: "Armagh" },
  { name: "Bangor", code: "Bangor" },
  { name: "Bath", code: "Bath" },
  { name: "Belfast", code: "Belfast" },
  { name: "Birmingham", code: "Birmingham" },
  { name: "Bradford", code: "Bradford" },
  { name: "Brighton & Hove", code: "Brighton & Hove" },
  { name: "Bristol", code: "Bristol" },
  { name: "Cambridge", code: "Cambridge" },
  { name: "Canterbury", code: "Canterbury" },
  { name: "Cardiff", code: "Cardiff" },
  { name: "Carlisle", code: "Carlisle" },
  { name: "Chelmsford", code: "Chelmsford" },
  { name: "Chester", code: "Chester" },
  { name: "Chichester", code: "Chichester" },
  { name: "Coventry", code: "Coventry" },
  { name: "Derby", code: "Derby" },
  { name: "Derry", code: "Derry" },
  { name: "Dundee", code: "Dundee" },
  { name: "Durham", code: "Durham" },
  { name: "Edinburgh", code: "Edinburgh" },
  { name: "Ely", code: "Ely" },
  { name: "Exeter", code: "Exeter" },
  { name: "Glasgow", code: "Glasgow" },
  { name: "Gloucester", code: "Gloucester" },
  { name: "Hereford", code: "Hereford" },
  { name: "Inverness", code: "Inverness" },
  { name: "Hull", code: "Hull" },
  { name: "Lancaster", code: "Lancaster" },
  { name: "Leeds", code: "Leeds" },
  { name: "Leicester", code: "Leicester" },
  { name: "Lichfield", code: "Lichfield" },
  { name: "Lincoln", code: "Lincoln" },
  { name: "Lisburn", code: "Lisburn" },
  { name: "Liverpool", code: "Liverpool" },
  { name: "London", code: "London" },
  { name: "Manchester", code: "Manchester" },
  { name: "Newcastle", code: "Newcastle" },
  { name: "Newport", code: "Newport" },
  { name: "Newry", code: "Newry" },
  { name: "Norwich", code: "Norwich" },
  { name: "Nottingham", code: "Nottingham" },
  { name: "Oxford", code: "Oxford" },
  { name: "Perth", code: "Perth" },
  { name: "Peterborough", code: "Peterborough" },
  { name: "Plymouth", code: "Plymouth" },
  { name: "Portsmouth", code: "Portsmouth" },
  { name: "Preston", code: "Preston" },
  { name: "Ripon", code: "Ripon" },
  { name: "St Albans", code: "St Albans" },
  { name: "St Asaph", code: "St Asaph" },
  { name: "St Davids", code: "St Davids" },
  { name: "Salford", code: "Salford" },
  { name: "Salisbury", code: "Salisbury" },
  { name: "Sheffield", code: "Sheffield" },
  { name: "Southend-on-Sea", code: "Southend-on-Sea" },
  { name: "Southampton", code: "Southampton" },
  { name: "Stirling", code: "Stirling" },
  { name: "Stoke-on-Trent", code: "Stoke-on-Trent" },
  { name: "Sunderland", code: "Sunderland" },
  { name: "Swansea", code: "Swansea" },
  { name: "Truro", code: "Truro" },
  { name: "Wakefield", code: "Wakefield" },
  { name: "Wells", code: "Wells" },
  { name: "Westminster", code: "Westminster" },
  { name: "Winchester", code: "Winchester" },
  { name: "Wolverhampton", code: "Wolverhampton" },
  { name: "Worcester", code: "Worcester" },
  { name: "York", code: "York" },
  { name: "Not Relevant", code: "NotRelevant" },
  { name: "Other", code: "Other" }

];
  
export default cities;