import React, { useState, lazy, useEffect } from "react";
import {
  BasicInfoSection,
  CreateDPPContainer,
  HeaderSection,
  PanelSection,
} from "./assets/pages.styled.js";
import { Galleria } from "primereact/galleria";
import SysService from "../services/sys.service.js";
import { BlockUI } from "primereact/blockui";
import WaitDialog from "../common/WaitDialog.js";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import jsPDF from "jspdf";
import { Steps } from "primereact/steps";
import { TabView, TabPanel } from "primereact/tabview";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import TracidService from "../services/tracid.service.js";
import sysService from "../services/sys.service.js";
import { set } from "date-fns";

const InformationPanels = lazy(() => import("../common/InformationPanels.jsx"));
const QRCodeGenerator = lazy(() => import("../common/QRCodeGenerator.jsx"));

const Passport = ({ t }) => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [images, setImages] = useState([]);
  const [notFound, setNotFound] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const location = useLocation();
  const [data, setData] = useState();
  const [informationPanels, setInformationPanels] = useState();
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState();
  const [dataVisible, setDataVisible] = useState(false);
  const [passportCode, setPassportCode] = useState();
  const [passportControlCode, setPassportControlCode] = useState("");

  const generateQRCodePDF = async (qrid) => {
    const qrCodeWidth = 20;
    const qrCodeHeight = 20;
    const margin = 10;
    const columnCount = 6;

    let pdf = new jsPDF();

    let colPosition = 0;
    let rowPosition = 0;
    let isNewPage = false;
    let u = 0;

    for (let i = 0; i < 1; i++) {
      colPosition = i % columnCount;
      if (isNewPage) {
        rowPosition = 0;
        isNewPage = false;
        u = 1;
      } else {
        rowPosition = Math.floor(u / columnCount);
        u++;
      }
      const x = colPosition * (qrCodeWidth + margin) + margin;
      const y = rowPosition * (qrCodeHeight + margin) + margin;
      const qrCodeDataURL = document
        .getElementById(qrid)
        .toDataURL("image/png");
      pdf.setFontSize(8);
      pdf.addImage(qrCodeDataURL, "PNG", x, y, qrCodeWidth, qrCodeHeight);
      pdf.text(x + qrCodeWidth / 2, y + qrCodeHeight + 5, qrid, {
        align: "center",
        horizontalScale: 1,
      });
    }
    pdf.save("QRCodePDF.pdf");
  };

  useEffect(() => {
    if (passwordCheck === true) {
      TracidService.passportControl(params?.id, passportControlCode).then(
        (response) => {
          setData(response.data.passport.logbookObject);
          setInformationPanels(
            response.data.passport.logbookObject.logbookInformationPanels
          );

          setPassportCode(response.data.passport.passportCode);

          let _images = [];

          for (
            let i = 0;
            i <
            response.data.passport.logbookObject?.logbookBasicInfo?.images
              ?.length;
            i++
          ) {
            _images.push({
              original:
                response.data.passport.logbookObject.logbookBasicInfo.images[i],
            });
          }
          setImages(_images);
          setLoading(false);
        },
        (error) => {
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    } else {
      if (
        params?.id !== "" &&
        params?.id !== null &&
        params?.id !== undefined
      ) {
        TracidService.getPassportLogbook(params?.id).then((response) => {
          setPassportControlCode(response.data.passportControlCode);
          setData(response.data.passport.logbookObject);
          setInformationPanels(
            response.data.passport.logbookObject.logbookInformationPanels
          );

          setPassportCode(response.data.passport.passportCode);

          let _images = [];

          for (
            let i = 0;
            i <
            response.data.passport.logbookObject?.logbookBasicInfo?.images
              ?.length;
            i++
          ) {
            _images.push({
              original:
                response.data.passport.logbookObject.logbookBasicInfo.images[i],
            });
          }
          setImages(_images);
          setLoading(false);
        });
      }
    }
  }, [params, passwordCheck]);

  const itemTemplate = (item) => {
    return (
      <img
        src={item.original}
        alt={item.alt}
        style={{ width: "600px", height: "350px", display: "block" }}
      />
    );
  };

  const energyEfficiencyInformationTemp = (data) => {
    if (data === "1") {
      return "Class A";
    } else if (data === "2") {
      return "Class B";
    } else if (data === "3") {
      return "Class C";
    } else if (data === "4") {
      return "Class D";
    } else if (data === "5") {
      return "Class E";
    } else if (data === "6") {
      return "Class F";
    } else if (data === "7") {
      return "Class G";
    }
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.original}
        alt={item.alt}
        style={{
          cursor: "pointer",
          width: "60px",
          height: "60px",
          display: "block",
        }}
      />
    );
  };

  const handleLogin = () => {
    setLoading(true);
    sysService.checkOTPCode(password).then(
      (response) => {
        if (response.data === false) {
          toast.warning(<Trans i18nKey="invalidAccessCode" />);
          setLoading(false);
          setPasswordCheck(false);
        } else if (response.data === true) {
          setPasswordCheck(true);
          setLoading(false);
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  const handleClick = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {

        toast.success(<Trans i18nKey="DBLLinkCopied" />);
      })
      .catch((error) => {
      });
  };
  return (
    <>
      {!notFound ? (
        <BlockUI blocked={blockedPanel}>
          <CreateDPPContainer>
            <HeaderSection>
              <div className="left-part">
                <div className="mb-2">
                  <span
                    className="title"
                    style={{ fontFamily: "Roboto", fontSize: "18px" }}
                  >
                    {t("digitalBuildingLogbook")}
                  </span>
                </div>
              </div>

              <div className="right-part">
                <img
                  src="/assets/img/lrs-logo.png"
                  width="140px"
                  alt="HHHL"
                  style={{ marginRight: "10px" }}
                />
              </div>
            </HeaderSection>
            <div className="flex align-items-start justify-content-end">
              <>
                <Button
                  style={{ marginTop: "5rem" }}
                  className="p-button-mb-dpp"
                  visible={false}
                >
                  <img src="/assets/img/passport/addHomeScreen.png" alt="" />
                  <div style={{ fontFamily: "Sofia Sans", fontSize: "15px" }}>
                    {t("addToHomeScreen")}
                  </div>
                </Button>
                &nbsp;
                <Button
                  style={{ marginTop: "5rem", marginRight: "2rem" }}
                  className="p-button-mb-dpp"
                  visible={false}
                >
                  <img src="/assets/img/passport/helpButton.png" alt="" />
                  <div style={{ fontFamily: "Sofia Sans", fontSize: "15px" }}>
                    {t("helpAndSupport")}
                  </div>
                </Button>
              </>
            </div>

            <BasicInfoSection>
              <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
                style={{ width: "120%" ,marginTop: "2.5rem"}}

              >
                <TabPanel header={t("propertyInformation")}>
                  <div
                    className={
                      images?.length > 0
                        ? "infoFirstPart"
                        : "infoFirstPartFullWidth"
                    }
                  >
                    {" "}
                    <div className="row-container">
                      <div
                        className="col-6 title"
                        style={{ marginTop: "1.5rem" }}
                      >
                        {t("homeowner")}:
                      </div>
                      <div className="col-8" style={{ marginTop: "1.5rem" }}>
                        {data?.logbookBasicInfo?.homeowner}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">{t("city")}:</div>
                      <div className="col-8">
                        {data?.logbookBasicInfo?.city}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">{t("postcode")}:</div>
                      <div className="col-8">
                        {data?.logbookBasicInfo?.postcode}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">{t("streetName")}:</div>
                      <div className="col-8">
                        {data?.logbookBasicInfo?.streetName}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">{t("houseNumber")}:</div>
                      <div className="col-8">
                        {data?.logbookBasicInfo?.houseNumber}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">{t("propertyType")}:</div>
                      <div className="col-8">
                        <div className="flex flex-wrap gap-2">
                          {data?.logbookBasicInfo?.propertyType}
                        </div>
                      </div>
                    </div>
                    <div className="row-container">
                      <div
                        className="col-6 title"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        {t("constructionYear")}:
                      </div>
                      <div className="col-8" style={{ marginBottom: "1.5rem" }}>
                        {data?.logbookBasicInfo?.constructionYear}
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel header={t("energyEfficiencyInformation")}>
                  <div
                    className={
                      images?.length > 0
                        ? "infoFirstPart"
                        : "infoFirstPartFullWidth"
                    }
                  >
                    <div className="row-container">
                      <div
                        className="col-6 title"
                        style={{ marginTop: "1.5rem" }}
                      >
                        {t("energyPerformanceClass")}:
                      </div>
                      <div className="col-8" style={{ marginTop: "1.5rem" }}>
                        {energyEfficiencyInformationTemp(
                          data?.logbookBasicInfo?.energyPerformanceClass
                        )}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">
                        {t("annualGasConsumption")}:
                      </div>
                      <div className="col-8">
                        {data?.logbookBasicInfo?.annualGasConsumption}
                      </div>
                    </div>
                    <div className="row-container">
                      <div className="col-6 title">
                        {t("annualElectricityConsumption")}:
                      </div>
                      <div className="col-8">
                        {data?.logbookBasicInfo?.annualElectricityConsumption}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabView>

              <div className="infoSecondPart">
                <div className="infoImages">
                  <Galleria
                    value={images}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    showItemNavigators
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                  />
                </div>
              </div>
            </BasicInfoSection>
            <PanelSection>
              <InformationPanels
                informationPanels={informationPanels}
                loading={loading}
                setLoading={setLoading}
                isView={true}
                noLoggDpp={true}
                passportCode={passportCode}
                passwordCheck={passwordCheck}
              />
            </PanelSection>
            {/* accessCode  */}
            {passwordCheck !== true && (
              <div
                className="col-12 md:col-3 lg:col-3"
                onClick={(e) =>
                  setDataVisible(dataVisible ? !dataVisible : true)
                }
                //onDoubleClick={(e) => setDataVisible(!dataVisible)}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#3D84A8",
                    cursor: "pointer",
                    fontWeight: "bolder",
                  }}
                >
                  {t("dppText")}
                </div>
              </div>
            )}
            {dataVisible === true && passwordCheck !== true && (
              <div className="col-12 md:col-3 lg:col-3">
                {/* <div className="mb-1">
                    <label
                      htmlFor="password"
                      className="platformColor"
                      style={{ fontSize: "14px" ,fontFamily:"Roboto"}}
                    >
                      {t("accessCode")}
                    </label> 
                  </div>*/}
                <div>
                  <InputText
                    id="password"
                    type="password"
                    className="w-full p-inputtext block mb-4"
                    value={password}
                    placeholder={t("accessCode")}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    label={t("access")}
                    className="w-full p-button-mb mb-4"
                    onClick={() => handleLogin()}
                  />
                </div>
              </div>
            )}

            <div className="flex justify-content-between">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      color: "#414140",
                      marginLeft: "1rem",
                    }}
                  >
                    Powered by
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <img
                    src="/assets/img/passport/tracidLogo.png"
                    width="140px"
                    alt="Tracid"
                    style={{ objectFit: "contain" }}
                    height={55}
                  />
                </div>
              </div>
              <div className="flex align-items-start justify-content-end">
                <Button
                  style={{ marginTop: "5rem", marginRight: "2rem"}}
                  className="p-button-mb-dpp"
                  onClick={handleClick}
                  visible={true}
                >
                  <div style={{ fontFamily: "Manrope", fontSize: "14px" }}>
                    {t("copyDBLLink")}
                  </div>
                </Button>
                {passportCode && (
                  <div>
                    <>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => generateQRCodePDF(passportCode)}
                      >
                        <QRCodeGenerator
                          link={
                            process.env.REACT_APP_SERVER_ADDRESS +
                            "/passport/" +
                            passportCode
                          }
                          id={passportCode}
                        />
                      </a>
                      <p style={{ marginRight: "2rem", fontFamily: "Manrope", fontSize: "14px" }}>{passportCode}</p>
                    </>
                  </div>
                )}
              </div>
            </div>
            <br />
            <br />
            <i>
              <div className="w-full gap-2 text-xs">
                {t("SHA256Hash")}: {data?.sha256Hash}
              </div>
            </i>
          </CreateDPPContainer>
        </BlockUI>
      ) : (
        <>
          <br />
          <div className="flex w-50 align-items-center justify-content-center">
            <div className="surface-card p-4 shadow-2 border-round w-full">
              <div className="text-center text-900 text-xl font-medium">
                404
              </div>
              <div className="text-center mb-4">
                <span className="text-600 font-medium line-height-3">
                  {t("404_passport")}
                  <br />
                  {t("pleaseCheckAndTryAgain")}
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.TOP_CENTER}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="colored"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
      />
    </>
  );
};

export default withTranslation()(Passport);
