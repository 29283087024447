import { isPlatformManager } from "./token-check";

let projectStatus5;

if (isPlatformManager()) {
  projectStatus5 = [
    { name: "Completed", code: "9" },
    { name: "Paused", code: "10" },
    { name: "Closed", code: "11" },
  ];
} else {
  projectStatus5 = [
    { name: "Paused", code: "10" },
    { name: "Closed", code: "11" },
  ];
}

export default projectStatus5;
